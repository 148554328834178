import React, { useCallback, useMemo } from 'react';
import { Document, View, Page,  Text } from '@react-pdf/renderer';
import { pdfStyles, PdfPageLimiter, UnloadedPdfRows } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { formatDateLonger, formatTime } from '../../../lib/datesAndTime';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { formatAction } from '../../../modals/Utilities/ActionLogModal/ActionLogModal';
import { renderFullName, renderFullNameForUserId } from '../../../shared-state/Core/users';
import { ActionLogEntry } from '../../../shared-state/General/actionLog';
import { sharedState } from '../../../shared-state/shared-state';
import { renderVesselsList } from '../../../shared-state/Core/vessels';
import SeaHorizontalBarGraph, { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaNoDataPDF from '../../../components/SeaNoDataPDF';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';

interface ActionsLoggedReportPDFProps {
    onCompleted: () => void,
    title: string,
    subTitle?: string,
    graphData?: GraphData[],
    filteredItems?: ActionLogEntry[],
    colourPalette?: string[],
    yLabelWidth?: number,
    vesselActions?: {
        byDay: {
            [key: string]: ActionLogEntry[],
        },
        days: string[]
    }
    vesselName?: string,
}

const ActionsLoggedReportPDF: React.FC<ActionsLoggedReportPDFProps> = ({
    onCompleted,
    title,
    subTitle,
    graphData,
    filteredItems,
    colourPalette,
    yLabelWidth,
    vesselActions,
    vesselName
}) => {
    const vessels = sharedState.vessels.use();
    const divisions = sharedState.divisions.use();
    const licenseeSettings = sharedState.licenseeSettings.use();
    const { selectedVesselIds } = useReportSettings();
    const counterRef = React.useRef(0);
    const { mapPdfArrayWithLimit, isLimitReached } = PdfPageLimiter(counterRef);

    const subHeading = useMemo(() => {
        if (vesselName && subTitle) {
            return subTitle.replace(/\) *$/, ` ${vesselName})`)
        } else if (vesselName) {
            return `(${vesselName})`;
        }
        return subTitle;
    }, [vesselName, subTitle]);

    const MakePDF = useCallback(() => {
        counterRef.current = 0;
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={subHeading}
                        userFullName={renderFullName()}
                    />
                    {vessels && selectedVesselIds?.length > 1 ? (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Vessels</Text>
                                <Text>{renderVesselsList(selectedVesselIds, vessels, divisions)}</Text>
                            </View>
                        </View>
                    ) : null}

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <SeaHorizontalBarGraph
                                n={0}
                                title={title}
                                subTitle={subTitle}
                                mode="pdf"
                                data={graphData}
                                sortData={true}
                                units="Actions Logged"
                                colourPalette={colourPalette}
                                hashNamesForColours={false}
                                yLabelWidth={yLabelWidth}
                            />
                        </View>
                    </View>

                    <SeaNoDataPDF 
                        dataName="crew certificates"
                        hasNoData={!filteredItems?.length}
                        isUsingFilter={true}
                    />
                    {filteredItems?.length && vesselActions?.byDay ? (
                        <View style={pdfStyles.dataContainer}>
                            <View style={pdfStyles.dataHeaderRow}>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.dateColumn]}>Date</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Done By</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2x3]}>Action</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2x3]}>Detail</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Vessels /{'\n'}Facilities</Text>
                            </View>
                            {vesselActions?.days.map((day: string, index: number) => {
                                if (isLimitReached()) {
                                    return null;
                                }
                                return (
                                    <View key={day}>
                                        <View style={[pdfStyles.categoryHeading, { marginTop: index === 0 ? 6 : undefined }]}>
                                            <Text style={pdfStyles.historyGroupHeaderText}>
                                                {formatDateLonger(vesselActions.byDay[day][0].when)}
                                            </Text>
                                        </View>
                                        {mapPdfArrayWithLimit(vesselActions?.byDay[day], ((action: ActionLogEntry) => {
                                            return (
                                                <View key={action.id} wrap={false} style={pdfStyles.greyDataRow}>
                                                    <Text style={pdfStyles.dateColumn}>{formatTime(action.when)}</Text>
                                                    <Text style={pdfStyles.column1}>{renderFullNameForUserId(action.userId)}</Text>
                                                    <Text style={pdfStyles.column2x3}>{formatAction(action, licenseeSettings)}</Text>
                                                    <Text style={pdfStyles.column2x3}>{formatValue(action.detail)}</Text>
                                                    <Text style={pdfStyles.column1}>{renderVesselsList(action.vesselIds, vessels, divisions)}</Text>
                                                </View>
                                            );
                                        }))}
                                    </View>
                            )})}
                            <UnloadedPdfRows length={filteredItems?.length} />
                        </View>
                    ) : null}
                </Page>
            </Document>
        )
    }, [title, subTitle, subHeading, vessels, divisions, selectedVesselIds, graphData, colourPalette, yLabelWidth, filteredItems?.length, vesselActions?.byDay, vesselActions?.days, isLimitReached, mapPdfArrayWithLimit, licenseeSettings]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="actionsLoggedReport"
        />
    );

};

export default ActionsLoggedReportPDF;
