import React, { useState, useCallback } from 'react';
import { sharedState } from '../../../shared-state/shared-state';
import { cloneDivisions, Divisions, divisionsToString } from '../../../shared-state/Core/divisions';
import { makeBatchTrace, reportError } from '../../../managers/ErrorsManager/ErrorsManager';
import { deleteValue, firestore, splittableBatch } from '../../../lib/firebase';
import { doc } from 'firebase/firestore';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaTab from '../../../components/SeaTab/SeaTab';
import SeaTabsGroup from '../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTabContent from '../../../components/SeaTabContent/SeaTabContent';
import DivisionsTab from './DivisionsTab/DivisionsTab';
import AuthenticationSettingsTab from './AuthenticationSettingsTab/AuthenticationSettingsTab';
import TimeZoneSettingsTab from './TimeZoneSettingsTab/TimeZoneSettingsTab';
import TaskSettingsTab from './TaskSettingsTab/TaskSettingsTab';

interface EditFleetSettingsModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const EditFleetSettingsModal: React.FC<EditFleetSettingsModalProps> = ({showModal, setShowModal}) => {
    const userId = sharedState.userId.use()!;
    const user = sharedState.user.use()!;
    const licenseeId = sharedState.licenseeId.use()!;
    const licenseeSettings = sharedState.licenseeSettings.use()!;
    const [tab, setTab] = useState('divisions');
    const [initialDivisions, setInitialDivisions] = useState<Divisions>();
    const [maxSessionSeconds, setMaxSesseionSeconds] = useState<string>("0");
    const [divisions, setDivisions] = useState<Divisions>();
    const [timeZone, setTimeZone] = useState<string>();
    const [hasSafetyCheckTaskTime, setHasSafetyCheckTaskTime] = useState(false);
    const [hasMaintenanceTaskTime, setHasMaintenanceTaskTime] = useState(false);

    // Detect if division structure has changed
    const areDivisionsDirty = useCallback(() => {
        return (
            divisionsToString(initialDivisions) !== divisionsToString(divisions)
        );
    }, [initialDivisions, divisions]);

    // Detect if vessel placement within divisions has changed
    const areVesselsDirty = useCallback(() => {
        let isDirty = false;
        if (initialDivisions?.byId) {
            const divisionIdsByVesselId = {} as { [vesselId: string]: string };
            Object.values(initialDivisions.byId).forEach((division) => {
                division.vessels.forEach((vessel) => {
                    divisionIdsByVesselId[vessel.id] = division.id;
                });
            });
            if (divisions?.byId) {
                Object.values(divisions.byId).forEach((division) => {
                    division.vessels.forEach((vessel) => {
                        if (divisionIdsByVesselId[vessel.id] === undefined || divisionIdsByVesselId[vessel.id] !== division.id) {
                            // Change detected
                            isDirty = true;
                        }
                    });
                });
            }
        }
        return isDirty;
    }, [divisions?.byId, initialDivisions?.byId]);

    const isAuthenticationDirty = useCallback(() => {
        return String(licenseeSettings.maxSessionSeconds) !== maxSessionSeconds;
    }, [licenseeSettings.maxSessionSeconds, maxSessionSeconds]);

    const isTimeZoneDirty = useCallback(() => {
        return (timeZone ?? '') !== (licenseeSettings.timeZone ?? '');
    }, [licenseeSettings.timeZone, timeZone]);

    const areTasksDirty = useCallback(() => {
        return (
            hasSafetyCheckTaskTime !== (licenseeSettings.hasSafetyCheckTaskTime ?? false) ||
            hasMaintenanceTaskTime !== (licenseeSettings.hasMaintenanceTaskTime ?? false)
        );
    }, [hasMaintenanceTaskTime, hasSafetyCheckTaskTime, licenseeSettings]);

    const isModalDirty = useCallback(() => {
        return (
            areDivisionsDirty() ||
            areVesselsDirty() ||
            isAuthenticationDirty() ||
            isTimeZoneDirty() ||
            areTasksDirty()
        );
    }, [areDivisionsDirty, areTasksDirty, areVesselsDirty, isAuthenticationDirty, isTimeZoneDirty]);

    const submitForm = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'licenseeSettings', 'update', licenseeId);
            let somethingChanged = false;

            // Update divisions (not including vessels)?
            if (areDivisionsDirty()) {
                somethingChanged = true;
                const divisionsData = divisions!.all.map((division) => {
                    return {
                        id: division.id,
                        name: division.name,
                        parentId: (division.parent!.id === 'root') ? undefined : division.parent!.id
                    };
                });
                batchTrace.data.divisions = divisionsData;

                batch.set(
                    doc(firestore, 'licenseeSettings', licenseeId!),
                    {
                        divisions: divisionsData,
                        updatedBy: userId,
                        whenUpdated: batchTrace.whenAction
                    },
                    { merge: true }
                );
            }

            // Update vessels (if any have a new division)
            const divisionIdsByVesselId = {} as { [vesselId: string]: string };
            if (initialDivisions) {
                Object.values(initialDivisions.byId).forEach((division) => {
                    division.vessels.forEach((vessel) => {
                        divisionIdsByVesselId[vessel.id] = division.id;
                    });
                });
            }
            if (divisions?.byId) {
                Object.values(divisions.byId).forEach((division) => {
                    division.vessels.forEach((vessel) => {
                        if (divisionIdsByVesselId[vessel.id] === undefined || divisionIdsByVesselId[vessel.id] !== division.id) {
                            // This vessel has changed division
                            batch.set(
                                doc(firestore, 'vessels', vessel.id),
                                {
                                    divisionId: (division.id === 'root') ? deleteValue : division.id,
                                    // whenUpdated: batchTrace.whenAction,
                                    // updatedBy: userId
                                },
                                { merge: true }
                            );
                            somethingChanged = true;
                        }
                    });
                });
            }

            if (isAuthenticationDirty()) {
                batch.set(
                    doc(firestore, 'licenseeSettings', licenseeId),
                    {
                        maxSessionSeconds: parseInt(maxSessionSeconds),
                        whenUpdated: batchTrace.whenAction,
                        updatedBy: userId
                    },
                    { merge: true }
                );
                somethingChanged = true;
            }

            if (isTimeZoneDirty()) {
                batch.set(
                    doc(firestore, 'licenseeSettings', licenseeId),
                    {
                        timeZone: timeZone,
                        whenUpdated: batchTrace.whenAction,
                        updatedBy: userId
                    },
                    { merge: true }
                );
                somethingChanged = true;
            }

            if (areTasksDirty()) {
                batch.set(
                    doc(firestore, 'licenseeSettings', licenseeId),
                    {
                        hasSafetyCheckTaskTime: hasSafetyCheckTaskTime,
                        hasMaintenanceTaskTime: hasMaintenanceTaskTime,
                        whenUpdated: batchTrace.whenAction,
                        updatedBy: userId
                    },
                    { merge: true }
                );
                somethingChanged = true;
            }

            if (somethingChanged) {
                batchTrace.save(`Updating fleet settings`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });
            }

            setShowModal(false);
        } catch (error: any) {
            reportError(
                `Failed to save fleet settings `,
                error?.message,
                error,
                {}
            );
        }
    }, [licenseeId, areDivisionsDirty, initialDivisions, divisions, isAuthenticationDirty, isTimeZoneDirty, areTasksDirty, setShowModal, userId, maxSessionSeconds, timeZone, hasSafetyCheckTaskTime, hasMaintenanceTaskTime]);

    const onOpened = useCallback(() => {
        setInitialDivisions(sharedState.divisions.current);
        setDivisions(
            sharedState.divisions.current ? cloneDivisions(sharedState.divisions.current) : undefined
        );
        setTimeZone(licenseeSettings?.timeZone);
        setMaxSesseionSeconds(String(licenseeSettings?.maxSessionSeconds ?? 0));
        setHasSafetyCheckTaskTime(licenseeSettings?.hasSafetyCheckTaskTime ?? false);
        setHasMaintenanceTaskTime(licenseeSettings?.hasMaintenanceTaskTime ?? false);
    }, [licenseeSettings]);

    return (
        <SeaModal
            title="Fleet Settings"
            size="wide"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            tabsPanel={
                <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                    <SeaTab tab="divisions" mode="forms">Divisions</SeaTab>
                    <SeaTab tab="authentication" mode="forms">Authentication</SeaTab>
                    <SeaTab tab="timeZone" mode="forms">Time Zone</SeaTab>
                    <SeaTab tab="tasks" mode="forms">Tasks</SeaTab>
                </SeaTabsGroup>
            }
            actionPanel={
                <div className="columns">
                    <div>
                        <SeaButton
                            zone="white"
                            onClick={submitForm}
                            disabled={!user?.isLicensee}
                        >
                            Save Fleet Settings
                        </SeaButton>
                    </div>
                    {!user?.isLicensee &&
                        <div style={{ alignSelf: 'center', paddingLeft: '12px' }}>
                            Only the account licensee can edit these settings.
                        </div>
                    }
                </div>
            }
        >
            <SeaTabContent tab="divisions" selectedTab={tab}>
                <DivisionsTab
                    showModal={showModal}
                    divisions={divisions}
                    setDivisions={setDivisions}
                />
            </SeaTabContent>
            <SeaTabContent tab="authentication" selectedTab={tab}>
                <AuthenticationSettingsTab
                    showModal={showModal}
                    maxSessionSeconds={maxSessionSeconds}
                    setMaxSesseionSeconds={setMaxSesseionSeconds}
                />
            </SeaTabContent>
            <SeaTabContent tab="timeZone" selectedTab={tab}>
                <TimeZoneSettingsTab
                    showModal={showModal}
                    timeZone={timeZone}
                    setTimeZone={setTimeZone}
                />
            </SeaTabContent>
            <SeaTabContent tab="tasks" selectedTab={tab}>
                <TaskSettingsTab
                    showModal={showModal}
                    hasSafetyCheckTaskTime={hasSafetyCheckTaskTime}
                    setHasSafetyCheckTaskTime={setHasSafetyCheckTaskTime}
                    hasMaintenanceTaskTime={hasMaintenanceTaskTime}
                    setHasMaintenanceTaskTime={setHasMaintenanceTaskTime}
                />
            </SeaTabContent>
        </SeaModal>
    );
};

export default EditFleetSettingsModal;
