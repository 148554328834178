import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { cloneDivisions, Division, Divisions } from '../../../../shared-state/Core/divisions';
import { alertMessage } from '../../../../managers/AlertManager/AlertManager';
import { sharedState } from '../../../../shared-state/shared-state';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import EditDivisionModal from '../../EditDivisionModal/EditDivisionModal';
import SeaDraggables from '../../../../components/SeaDraggables/SeaDraggables';
import EditVesselDivisionsModal from '../../EditVesselDivisionsModal/EditVesselDivisionsModal';
import './DivisionsTab.css';

interface DivisionsTabProps {
    showModal: boolean,
    divisions?: Divisions,
    setDivisions: React.Dispatch<React.SetStateAction<Divisions | undefined>>
}

const DivisionsTab: React.FC<DivisionsTabProps> = ({ showModal, divisions, setDivisions }) => {
    const user = sharedState.user.use(showModal);
    const [showEditDivisionModal, setShowEditDivisionModal] = useState(false);
    const [showEditVesselDivisionsModal, setShowEditVesselDivisionsModal] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState<Division>();

    const addDivision = useCallback(() => {
        setSelectedDivision(undefined);
        setShowEditDivisionModal(true);
    }, []);

    // Keep selectedDivision fresh
    useEffect(() => {
        if (selectedDivision && divisions && divisions.byId[selectedDivision.id]) {
            setSelectedDivision(
                divisions.byId[selectedDivision.id]   
            );
        }
    }, [divisions, selectedDivision]);

    const renderDivisionVesselCount = useCallback((division: Division) => {
        let numVessels = 0;
        let numFacilities = 0;
        division.vessels.forEach((vessel) => {
            if (vessel.isShoreFacility) {
                numFacilities++;
            } else {
                numVessels++;
            }
        });
        const total = numVessels + numFacilities;
        if (total > 0) {
            return (
                <i style={{ paddingTop: '2px', fontSize: '80%' }}>
                    ({total} {
                        numFacilities > 0 ? (
                            numVessels > 0 ? (
                                'vessels/facilities'
                            ) : (
                                total > 1 ? 'facilities' : 'facility'
                            )
                        ) : (
                            total > 1 ? 'vessels' : 'vessel'
                        )
                    })
                </i>
            );
        }
        return undefined;
    }, []);

    const renderDivisions = useCallback((division: Division, output: ReactNode[] = [], level = 0) => {
        const isRoot = (division.id === 'root');
        output.push(
            <div
                key={division.id}
                className={`sea-division ${isRoot ? 'non-draggable' : ''}`}
                style={{ marginLeft: `${level * 32}px`, paddingBottom: (isRoot && division.children!.length === 1 ? '4px' : '0px') }}
            >
                <div
                    className={`sea-row-history sea-card columns ${user?.isLicensee ? 'clickable' : ''}`}
                    onClick={(e) => {
                        if (user?.isLicensee) {
                            if (division.id === 'root') {
                                setSelectedDivision(undefined);
                                setShowEditVesselDivisionsModal(true);
                            } else {
                                setSelectedDivision(division);
                                setShowEditDivisionModal(true);
                            }
                        }
                    }}
                    style={{ marginLeft: isRoot ? '0px' : '4px' }}
                >
                    {division.name} {renderDivisionVesselCount(division)}
                </div>
            </div>
        );
        division.children?.forEach((child) => {
            renderDivisions(child, output, level + 1);
        });
        return output;
    }, [user?.isLicensee, renderDivisionVesselCount]);

    const moveDivision = useCallback((fromIndex: number, toIndex: number) => {
        // Need to convert from index to division (divisions.all is not in the same order)
        const divisionsByIndex = {} as {
            [index: number]: Division
        };
        let index = 0;
        const walkTree = (division: Division) => {
            divisionsByIndex[index++] = division;
            division.children?.forEach((child) => {
                walkTree(child);
            });
        };
        walkTree(divisions!.root);
        let fromDivision = divisionsByIndex[fromIndex];
        let toDivision = divisionsByIndex[toIndex];

        if (fromDivision && toDivision && fromDivision.id !== toDivision.id) {
            // Check toDivision is not a child of fromDivision
            let isChild = false;
            const check = (division: Division) => {
                if (division.id === toDivision.id) {
                    isChild = true;
                }
                division.children?.forEach((child) => {
                    check(child);
                });
            };
            check(fromDivision);
            if (isChild) {
                alertMessage('Failed to move division. You cannot move a division to a position within itself.');
                return;
            }

            // Move the division
            setDivisions((_divisions) => {
                const divisions = cloneDivisions(_divisions!);
                fromDivision = divisions.byId[fromDivision.id];
                toDivision = divisions.byId[toDivision.id];
                // Remove from existing location
                fromDivision.parent!.children!.splice(fromDivision.parent!.children!.indexOf(fromDivision), 1);
                divisions.all.splice(divisions.all.indexOf(fromDivision), 1);
                // Insert at new location
                const indexOffset = (toIndex > fromIndex) ? 1 : 0; // When moving down, it feels like the item should be inserted below the destination
                toDivision.parent!.children!.splice(toDivision.parent!.children!.indexOf(toDivision) + indexOffset, 0, fromDivision);
                fromDivision.parent = toDivision.parent;
                divisions.all.splice(divisions.all.indexOf(toDivision) + indexOffset, 0, fromDivision);
                return divisions;
            });
        }
    }, [divisions, setDivisions]);

    return (<>
        {(!divisions || divisions.all.length === 0) &&
            <div style={{ padding: '0 0 40px 0' }}>
                <p>
                    There are currently no divisions.
                </p>
            </div>
        }
        {divisions?.root && 
            <div className="sea-divisions">
                {user?.isLicensee ? (
                    <SeaDraggables
                        type="list"
                        items={[]}
                        onDrop={moveDivision}
                    >
                        {renderDivisions(divisions.root)}
                    </SeaDraggables>
                ) : (
                    <>{renderDivisions(divisions.root)}</>
                )}
                
            </div>
        }
        {user?.isLicensee &&
            <div className="columns" style={{ justifyContent: 'space-between' }}>
                <div className="sea-add-new-button" style={{ marginTop: '0px' }}>
                    <SeaButton
                        zone="white"
                        shape="circle"
                        onClick={(e) => addDivision()}
                    >
                        <SeaIcon slot="icon-only" icon="add" />
                    </SeaButton>
                    <div className="text" onClick={(e) => addDivision()}>
                        Add Division
                    </div>
                </div>
                {divisions?.root &&
                    <div style={{ textAlign: 'right' }}>
                        <SeaButton mini onClick={(e) => setShowEditVesselDivisionsModal(true)}>
                            Manage Vessels
                        </SeaButton>
                    </div>
                }
            </div>
        }
        {showModal &&
            <>
                {user?.isLicensee &&
                    <>
                        <EditDivisionModal
                            showModal={showEditDivisionModal}
                            setShowModal={setShowEditDivisionModal}
                            divisions={divisions}
                            setDivisions={setDivisions}
                            divisionToUpdate={selectedDivision}
                            level={2}
                        />
                        <EditVesselDivisionsModal
                            showModal={showEditVesselDivisionsModal}
                            setShowModal={setShowEditVesselDivisionsModal}
                            divisions={divisions}
                            setDivisions={setDivisions}
                            level={2}
                        />
                    </>
                }
            </>
        }
    </>);
};

export default DivisionsTab;
