import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import QueryString from 'query-string';
import { sharedState } from '../shared-state/shared-state';

// Functions to convert back and forth between values and query string values
type ParamEncoder<T> = {
    encode: (value: T) => string, // Encode a value of type T into a string to be stored as a query string value
    decode: (qsValue: string) => T // Decode a query string value back into a value of type T
}

/**
 * You should use this encoder for simple srting values.
 */
export const stringParamEncoder: ParamEncoder<string> = {
    encode: (value: string) => ''+value,
    decode: (qsValue: string) => qsValue as string
};

/**
 * You should use this encoder for id arrays.
 * Assumes an array of 20 character length strings.
 */
export const idArrayParamEncoder: ParamEncoder<string[]> = {
    encode: (ids: string[]) => {
        return ids.join('');
    },
    decode: (qsValue: string) => {
        const ids = [] as string[];
        for (let i = 0; (i + 20) <= qsValue.length; i += 20) {
            ids.push(qsValue.substring(i, i+20));
        }
        return ids;
    }
};

/**
 * You should use this encoder for id arrays such as vesselIds.
 * Assumes an array of 20 character length strings.
 * Will remove any vesselIds you don't have access to.
 * (This can happen due to persistance to the device being out of date)
 */
export const vesselIdsParamEncoder: ParamEncoder<string[]> = {
    encode: (ids: string[]) => {
        return ids.join('');
    },
    decode: (qsValue: string) => {
        const ids = [] as string[];
        for (let i = 0; (i + 20) <= qsValue.length; i += 20) {
            const vesselId = qsValue.substring(i, i+20);
            if (sharedState.vesselIds.current?.includes(vesselId)) {
                ids.push(vesselId);
            }
        }
        if (ids.length === 0) {
            return [...(sharedState.vesselIds.current ?? [])];
        }
        return ids;
    }
};


/**
 * Synchronises a state with a query string parameter.
 * 
 * When a URL is loaded this should initialise the state value with the query string param.
 * When the state value is changed, the URL should change to reflect the new value.
 * 
 * Values are recorded in the query string using JSON.
 */
export default function useQueryStringState<T>(
    param: string,
    defaultValue: T | undefined,
    encoder: ParamEncoder<T> = {
        encode: (value: T) => JSON.stringify(value),
        decode: (qsValue: string) => JSON.parse(qsValue)
    },
    persistId?: string // Set this to persist the latest value to the device
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>] {
    const location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = useState<T | undefined>();

    // If we have an undefined value AND if query string contains <param>, use query string's value
    useEffect(() => {
        setValue((current: T | undefined) => {
            if (current !== undefined) {
                return current;
            }
            // Attempt to extract value from query string
            if (location?.search) { // Process query string
                const qs = QueryString.parse(location.search);
                if (qs[param]) {
                    return encoder.decode(qs[param] as string);
                }
            }
            if (persistId) { // Prefer persisted value over default value
                const persistedValue = window.localStorage.getItem(`_${sharedState.userId.current}_${persistId}`);
                if (persistedValue) {
                    return encoder.decode(persistedValue);
                }
            }
            return defaultValue;
        });
    }, [defaultValue, encoder, location.search, param, persistId]);

    useEffect(() => {
        if (location.pathname) {
            const params = QueryString.parse(location.search);
            if (params[param] !== (value === undefined ? undefined : encoder.encode(value))) {
                if (value === undefined) {
                    delete params[param];
                } else {
                    const paramValue = encoder.encode(value);
                    params[param] = paramValue;
                    if (persistId && paramValue) {
                        window.localStorage.setItem(`_${sharedState.userId.current}_${persistId}`, paramValue);
                    }
                }
                navigate(`${location.pathname}?${QueryString.stringify(params)}`, { replace: true });
            }
        }
    }, [value, location.pathname, location.search, navigate, param, encoder, persistId]);

    return [value, setValue];
}
