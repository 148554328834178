import React, { useCallback } from 'react';
import { Document, View, Page,  Text } from '@react-pdf/renderer';
import { PdfPageLimiter, UnloadedPdfRows, pdfStyles } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { formatDatetime } from '../../../lib/datesAndTime';
import { renderCategoryNames } from '../../../lib/categories';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { Incident, Injury } from '../../../shared-state/HealthSafety/incidents';
import { renderVesselsList } from '../../../shared-state/Core/vessels';
import reporting, { colours } from '../../../lib/reporting';
import SeaNoDataPDF from '../../../components/SeaNoDataPDF';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';
import SeaHorizontalBarGraph, { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';

interface InjuryTypesReportPdfProps {
    onCompleted: () => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredIncidents?: Incident[],
    yLabelWidth?: number,
    multiColoured?: boolean
}

const InjuryTypesReportPdf: React.FC<InjuryTypesReportPdfProps> = ({
    onCompleted,
    title,
    subTitle,
    graphData,
    filteredIncidents,
    yLabelWidth,
    multiColoured
}) => {
    const vessels = sharedState.vessels.use();
    const divisions = sharedState.divisions.use();
    const injuryTypes = sharedState.injuryTypes.use();
    const { selectedVesselId, selectedVesselIds } = useReportSettings();
    const counterRef = React.useRef(0);
    const { mapPdfArrayWithLimit } = PdfPageLimiter(counterRef);

    const MakePDF = useCallback(() => {
        counterRef.current = 0;
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={subTitle}
                        userFullName={renderFullName()}
                    />
                    {vessels && selectedVesselIds?.length > 1 ? (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Vessels</Text>
                                <Text>{renderVesselsList(selectedVesselIds, vessels, divisions)}</Text>
                            </View>
                        </View>
                    ) : null}

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <SeaHorizontalBarGraph
                                mode="pdf"
                                data={graphData}
                                sortData={true}
                                units="People Injured"
                                colourPalette={multiColoured ? reporting.colours.default : [colours.orangeSoda]}
                                yLabelWidth={yLabelWidth}
                                hashNamesForColours={multiColoured ? true : false}
                            />
                        </View>
                    </View>

                    <SeaNoDataPDF 
                        dataName="injury types"
                        hasNoData={!filteredIncidents?.length}
                        isUsingFilter={true}
                    />
                    {filteredIncidents?.length ? (
                        <View style={pdfStyles.dataContainer}>
                            <View style={pdfStyles.dataHeaderRow}>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.timeColumn]}>Date/Time</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2x3]}>Name</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2x3]}>Injury Types</Text>
                                {!selectedVesselId ? <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Vessel</Text> : null}
                            </View>
                            {mapPdfArrayWithLimit(filteredIncidents, (incident) => {
                                const injuryIds: string[] = Array.from(new Set(incident.injuries?.flatMap((injury: Injury) => injury.typeIds || [])));
                                return (
                                    <View wrap={false} key={incident.id} style={pdfStyles.greyDataRow}>
                                        <Text style={pdfStyles.timeColumn}>{formatDatetime(incident.whenEvent)}</Text>
                                        <Text style={pdfStyles.column2x3}>{formatValue(incident.name)}</Text>
                                        <Text style={[pdfStyles.truncate, pdfStyles.column2x3]}>{renderCategoryNames(injuryIds, injuryTypes)}</Text>
                                        {!selectedVesselId ? <Text style={[pdfStyles.truncate, pdfStyles.column1]}>{vessels?.byId[incident.vesselId]?.name}</Text> : null}
                                    </View>
                                );
                            })}
                            <UnloadedPdfRows length={filteredIncidents?.length} />
                        </View>
                    ) : null}
                </Page>
            </Document>
        )
    }, [title, subTitle, vessels, selectedVesselIds, divisions, graphData, multiColoured, yLabelWidth, filteredIncidents, selectedVesselId, mapPdfArrayWithLimit, injuryTypes]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="injuryTypesReport"
        />
    );

};

export default InjuryTypesReportPdf;
