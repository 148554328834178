import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, SyncableDocument, UpdateableDocument, sharedState } from "../shared-state";
import { renderFullNameForUserId } from '../Core/users';
import { canView } from "../Core/userPermissions";
import { registerFiles } from "../FileSyncSystem/filesToCache";


export interface CorrectiveAction extends CreateableDocument, UpdateableDocument, SyncableDocument {
    correctiveActionNum: string;
    assignedTo?: string;
    completedBy?: string;
    completedNotes?: string;
    completedFiles?: string[];
    deletedBy?: string;
    description: string;
    emailReminder?: string;
    files: string[];
    licenseeId: string;
    state: string;
    tags?: string[];
    title: string;
    vesselIds: string[];
    whenCompleted?: number;
    whenDeleted?: number;
    dateDue?: string;
    dateToRemind?: string;
    incidentReviewId?: string;
    // Generated at run time - not in original data
    searchText?: string;
}

export type CorrectiveActionsData = {
    byId: { [correctiveActionId: string]: CorrectiveAction };
    byVesselId: { // Contains both active and completed CorrectiveActions
        [vesselId: string]: CorrectiveAction[]
    };
    array: {
        completed: CorrectiveAction[],
        active: CorrectiveAction[],
        all: CorrectiveAction[]
    };
};

export const correctiveActionsConfig: SharedStateConfig<CorrectiveActionsData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId'],
    countLiveDocs: () => sharedState.correctiveActions.current?.array.all.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (
            licenseeId && canView('correctiveActions')
        ) {
            console.log('correctiveActionsConfig', licenseeId);

            const makeSearchText = (correctiveAction: CorrectiveAction) => {
                let s = correctiveAction.title;
                if (correctiveAction.assignedTo) {
                    s += renderFullNameForUserId(correctiveAction.assignedTo);
                }
                if (correctiveAction.description) {
                    s += correctiveAction.description;
                }
                if (correctiveAction.correctiveActionNum) {
                    s += correctiveAction.correctiveActionNum;
                }
                if (correctiveAction.tags) {
                    s += correctiveAction.tags.join('');
                }
                return s.toLowerCase();
            };

            return onSnapshot(
                query(
                    collection(firestore, 'correctiveActions'),
                    where('licenseeId', '==', licenseeId),
                    where('state', 'in', ['active', 'completed']),
                    orderBy('title', 'asc')
                ),
                (snap) => {
                    done();

                    const all = [] as CorrectiveAction[];
                    const active = [] as CorrectiveAction[];
                    const completed = [] as CorrectiveAction[];
                    const byId = {} as { [correctiveActionId: string]: CorrectiveAction };
                    const byVesselId = {} as { [vesselId: string]: CorrectiveAction[] };

                    snap.docs.forEach((doc) => {
                        const correctiveAction = {
                            id: doc.id,
                            ...doc.data()
                        } as CorrectiveAction;
                        correctiveAction.searchText = makeSearchText(correctiveAction);

                        byId[correctiveAction.id] = correctiveAction;
                        all.push(correctiveAction);
                        registerFiles(correctiveAction.files, 'correctiveActions', correctiveAction);
                        if (correctiveAction.state === 'active') {
                            active.push(correctiveAction);
                        } else {
                            completed.push(correctiveAction);
                        }
                        if (correctiveAction.vesselIds) {
                            correctiveAction.vesselIds.forEach((vesselId) => {
                                if (byVesselId[vesselId] === undefined) {
                                    byVesselId[vesselId] = [];
                                }
                                byVesselId[vesselId].push(correctiveAction);
                            });
                        }
                    });

                    set({
                        array: {
                            completed,
                            active,
                            all
                        },
                        byId,
                        byVesselId
                    });

                },
                (error) => {
                    done();
                    // This should be very rare
                    console.error(
                        `Failed to access corrective actions for licensee ${licenseeId}`,
                        error
                    );
                }
            )
        } else {
            done();
        }
    },

};
