import React, { useState, useEffect } from 'react';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { formatDateShort } from '../../../../lib/datesAndTime';
import { DrillReport } from '../../../../shared-state/VesselSafety/drillReports';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import ViewDrillReport from '../ViewDrillReport/ViewDrillReport';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import './ViewDrillHistory.css'

interface ViewDrillHistoryProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const ViewDrillHistory: React.FC<ViewDrillHistoryProps> = ({showModal, setShowModal}) => {
    const vesselDrills = sharedState.vesselDrills.use(showModal);
    const drillReports = sharedState.drillReports.use(showModal);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [selectedReport, setSelectedReport] = useState<DrillReport>();
    const [showDrillReportModal, setShowDrillReportModal] = useState(false);

    useEffect(() => {
        resetPageLimit();
    }, [showModal, drillReports, resetPageLimit]);

    // Keep selectedReport fresh
    useEffect(() => {
        if (selectedReport?.id && drillReports?.byId[selectedReport.id]) {
            setSelectedReport(drillReports.byId[selectedReport.id])
        };
    }, [drillReports, selectedReport?.id]);

    const onViewDrillReport = (report: DrillReport) => {
        setSelectedReport(report);
        setShowDrillReportModal(true);
    };
    
    return (
        <SeaModal
            title="Drill History"
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
        >
            <SeaNoData
                dataName="item"
                isHistory={true}
                isLoading={!drillReports}
                hasNoData={drillReports?.allReports && drillReports.allReports.length === 0}
            />
            <div className={`drill-history-history ${(drillReports?.allReports?.length || 0) > 0 ? 'reveal' : 'conceal'}`}>
                <div className="sea-row-history headings-history">
                    <div>Date</div>
                    <div>Completed By</div>
                    <div>Drills Completed</div>
                    <div></div>
                </div>
                {mapArrayWithLimit(drillReports?.allReports, (item) => {
                    return (
                        <div key={item.id} className='sea-card sea-row-history clickable' onClick={(e) => onViewDrillReport(item)}>
                            <div>{formatDateShort(item.dateCompleted)}</div>
                            <div className="truncate-3">
                                {item?.crewInvolvedIds?.map((crewId: string, index: number) => {
                                    if (renderFullNameForUserId(crewId)) {
                                        if (index === 0) {
                                            return renderFullNameForUserId(crewId)
                                        } else {
                                            return `, ${renderFullNameForUserId(crewId)}`
                                        }
                                    } else {
                                        return '-'
                                    }
                                })}
                            </div>
                            <div className="truncate-3">
                                {item.drills?.map((drill, index) => {
                                    if (index === 0) {
                                        return (
                                            vesselDrills?.byId[drill.id]
                                                ? vesselDrills?.byId[drill.id].name
                                                : drill.name
                                        )
                                    } else {
                                        return (
                                            vesselDrills?.byId[drill.id]
                                                ? `, ${vesselDrills?.byId[drill.id].name}`
                                                : `, ${drill.name}`
                                        )
                                    }
                                })}
                            </div>
                            <div><SeaFileImage files={item.files} size="tiny"/></div>
                        </div>
                    )
                })}
                {limitTriggerElement}
            </div>

            {showModal && selectedReport &&
                <ViewDrillReport
                    showModal={showDrillReportModal}
                    setShowModal={setShowDrillReportModal}
                    selectedItem={selectedReport}
                    level={2}
                />
            }
        </SeaModal>
    );
};

export default ViewDrillHistory;
