import React, { useCallback, useMemo } from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { formatTextAreaText } from '../../../lib/util';
import { pdfStyles, styleItemColumn } from '../../../lib/pdf';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { SafetyMeetingReport } from '../../../shared-state/HealthSafety/safetyMeetingReports';
import { sharedState } from '../../../shared-state/shared-state';
import { getImgSrcFromString } from '../../../lib/files';
import { renderFullName } from '../../../shared-state/Core/users';
import { formatMonthDayTime, formatMonthLonger } from '../../../lib/datesAndTime';
import { renderVesselsList } from '../../../shared-state/Core/vessels';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';


interface HealthSafetyMeetingsPdfProps {
    onCompleted: () => void,
    filterVesselId: string | undefined,
    filteredMeetingReports: {
        categories: string[],
        byCategory: { [key: string]: SafetyMeetingReport[] }
    }
}

const HealthSafetyMeetingsPdf: React.FC<HealthSafetyMeetingsPdfProps> = ({
    onCompleted,
    filterVesselId,
    filteredMeetingReports
}) => {
    const vessels = sharedState.vessels.use();
    const divisions = sharedState.divisions.use();
    const safetyMeetingJobs = sharedState.safetyMeetingJobs.use();

    const title = useMemo(() => {
        return 'Health and Safety Meetings' + (filterVesselId === undefined ? '' : filterVesselId === 'all' ? ' - All' : ' - ' + vessels?.byId[filterVesselId]?.name);
    }, [filterVesselId, vessels]);

    const MakePDF = useCallback(() => {
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="landscape"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        userFullName={renderFullName()}
                    />

                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Date</Text>
                        </View>
                        {
                            (filterVesselId === undefined || filterVesselId === 'all') &&
                            <View style={styleItemColumn(0.5)}>
                                <Text>Vessels / Vacilities</Text>
                            </View>
                        }
                        <View style={styleItemColumn(1)}>
                            <Text>Notes</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Personnel Present</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Jobs Created</Text>
                        </View>
                        <View style={pdfStyles.thumbColumn}></View>
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                    {
                        filteredMeetingReports.categories.map((category, index) => {
                            var items = filteredMeetingReports?.byCategory[category];
                            return (
                                <React.Fragment key={'category-' + index}>
                                    <View wrap={false} style={pdfStyles.itemsCategory}>
                                        <Text>{formatMonthLonger(category)}</Text>
                                    </View>
                                    {(items.map((item, index1) => {
                                        let numJobs = 0;
                                        const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                        const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                        const isLast = index1 === items.length - 1;
                                        return (
                                            <React.Fragment key={'meeting-' + index1}>
                                                {index1 > 0 &&
                                                    <View style={pdfStyles.itemsRowSpacer}></View>
                                                }
                                                <View wrap={false} style={pdfStyles.itemsRow}>
                                                    <View style={pdfStyles.dateColumn}>
                                                        <Text wrap={true}>
                                                            {formatMonthDayTime(item.whenMeeting)}
                                                        </Text>
                                                    </View>
                                                    {
                                                        (filterVesselId === undefined || filterVesselId === 'all') &&
                                                        <View style={styleItemColumn(0.5)}>
                                                            <Text wrap={true}>
                                                                {renderVesselsList(item.vesselIds, vessels, divisions)}
                                                            </Text>
                                                        </View>
                                                    }
                                                    <View style={styleItemColumn(1)}>
                                                        <Text wrap={true}>
                                                            {formatTextAreaText(item.notes)}
                                                        </Text>
                                                    </View>
                                                    <View style={styleItemColumn(1)}>
                                                        <Text wrap={true}>
                                                            {item?.personnelPresentIds?.map((crewId: string, index: number) => {
                                                                if (renderFullNameForUserId(crewId)) {
                                                                    if (index === 0) {
                                                                        return renderFullNameForUserId(crewId)
                                                                    } else {
                                                                        return `, ${renderFullNameForUserId(crewId)}`
                                                                    }
                                                                } else {
                                                                    return '-'
                                                                }
                                                            })}
                                                        </Text>
                                                    </View>
                                                    <View style={styleItemColumn(1)}>
                                                        {safetyMeetingJobs && item?.jobIds && item.jobIds?.map((jobId: string) => {
                                                            if (safetyMeetingJobs.byId[jobId]) {
                                                                numJobs++;
                                                                return <Text>
                                                                    {safetyMeetingJobs.byId[jobId].task}
                                                                </Text>;
                                                            }
                                                            return undefined;
                                                        })}
                                                        {(numJobs === 0) && <Text>-</Text>}
                                                    </View>
                                                    <View style={pdfStyles.thumbColumn}>
                                                        {imgSrc &&
                                                            <Image
                                                                src={imgSrc}
                                                                style={pdfStyles.thumbImg}
                                                            />
                                                        }
                                                    </View>
                                                </View>
                                                {isLast && (
                                                    <View style={pdfStyles.itemsCategorySpacer} />
                                                )}
                                            </React.Fragment>
                                        );
                                    }))}
                                </React.Fragment>

                            );
                        })
                    }

                </Page>
            </Document>
        );
    }, [filterVesselId, filteredMeetingReports?.byCategory, filteredMeetingReports.categories, safetyMeetingJobs, title, vessels, divisions]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="HealthSafetyMeetings"
        />
    );
}

export default HealthSafetyMeetingsPdf;