import React, { useState, useEffect, useMemo, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { extractSearchTerms, formatValue } from '../../../lib/util';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { DangerousGood } from '../../../shared-state/HealthSafety/dangerousGoods';
import { sharedState } from '../../../shared-state/shared-state';
import { warnDays } from '../../../lib/datesAndTime';
import { renderVesselsList } from '../../../shared-state/Core/vessels';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import EditDangerousGood from '../../../modals/HealthSafety/DangerousGoods/EditDangerousGoods/EditDangerousGood';
import ViewDangerousGood from '../../../modals/HealthSafety/DangerousGoods/ViewDangerousGoods/ViewDangerousGood';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import DangerousGoodsCsv from '../../../exports/HealthSafety/DangerousGoods/DangerousGoodsCsv';
import SeaSelectVessels from '../../../components/SeaSelectVessels/SeaSelectVessels';
import './DangerousGoodsRegister.css';
import SeaSearchbar from '../../../components/SeaSearchbar/SeaSearchbar';

const getClassName = (item: DangerousGood, incStatus?: boolean) => {
    if (item?.msdsFiles.length > 0 && item?.dateExpires && incStatus) {
        return ' has-both';
    } else if (item?.msdsFiles.length > 0) {
        return ' has-msds-files';
    } else if (item?.dateExpires && incStatus) {
        return ' has-status';
    }
    return '';
};

interface DangerousGoodsRegisterProps {
    visible: boolean;
    filterVesselIds: string[] | undefined;
    setFilterVesselIds: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

const DangerousGoodsRegister: React.FC<DangerousGoodsRegisterProps> = ({visible, filterVesselIds, setFilterVesselIds}) => {
    const dangerousGoods = sharedState.dangerousGoods.use(visible ? 1 : 101);
    const vessels = sharedState.vessels.use(visible ? 1 : 101);
    const divisions = sharedState.divisions.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [searchText, setSearchText] = useState('');
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<DangerousGood>();
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (visible) {
            logPageView('HealthSafety/DangerousGoodsRegister');
        }
    }, [visible]);

    useEffect(() => {
        resetPageLimit();
    }, [visible, searchText, filterVesselIds, resetPageLimit]);

    const isSingleVessel = useMemo(() => {
        return (filterVesselIds && filterVesselIds.length === 1);
    }, [filterVesselIds]);

    const filteredDangerousGoods = useMemo(() => {
        let _dangerousGoods: DangerousGood[] = [];
        const terms = extractSearchTerms(searchText, true);
        if (filterVesselIds) {
            const doneDangerousGood = {} as {[id: string]: true};
            filterVesselIds.forEach((vesselId) => {
                dangerousGoods?.byVesselId[vesselId]?.forEach((dangerousGood) => {
                    if (!doneDangerousGood[dangerousGood.id]) {
                        doneDangerousGood[dangerousGood.id] = true;
                        let isMatch = true;
                        if (terms.length > 0) {
                            for (let i = 0; i < terms.length; i++) {
                                if (dangerousGood.searchText?.indexOf(terms[i]) === -1) {
                                    isMatch = false;
                                    break;
                                }
                            }
                        }
                        if (isMatch) {
                            _dangerousGoods.push(dangerousGood);
                        }
                    }
                });
            });
            if (filterVesselIds.length > 1) {
                _dangerousGoods.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            }
        } else {
            _dangerousGoods = dangerousGoods?.all || [];
        }
        return _dangerousGoods;
    }, [dangerousGoods, filterVesselIds, searchText]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && dangerousGoods?.byId[selectedItem.id]) { 
            setSelectedItem(dangerousGoods.byId[selectedItem.id])
        };
    }, [dangerousGoods, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: DangerousGood) => {
        setShowViewItemModal(true);
        setSelectedItem(item)
    };

    return (
        <RequirePermissions
            role="dangerousGoodsRegister"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="dangerous-goods-register page-head">
                <div><h2>Dangerous Goods Register</h2></div>
                <div className="actions">
                    <RequirePermissions
                        role="dangerousGoodsRegister"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Add New
                        </SeaButton>
                    </RequirePermissions>
                    <SeaExporter
                        setExportType={setExportType}
                        csv={exportType === "csv" && <DangerousGoodsCsv onCompleted={() => setExportType(undefined)} />}
                    />
                </div>
            </div>

            {/* Filters */}
            <div className="columns wrap filters">
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
                {vessels && vessels.all.length > 1 &&
                    <div>
                        <SeaSelectVessels
                            vesselIds={filterVesselIds}
                            setVesselIds={setFilterVesselIds}
                            zone="grey"
                            width="300px"
                            emptyText="None"
                        />
                    </div>
                }
            </div>

            <SeaNoData
                dataName="dangerous goods"
                isLoading={!dangerousGoods}
                hasNoData={filteredDangerousGoods && filteredDangerousGoods.length === 0}
                isUsingFilter={true}
            />

            <div className={`dangerous-goods-register has-thumbs ${isSingleVessel ? 'hide-vessel' : 'show-vessel'} ${(filteredDangerousGoods?.length || 0) > 0 ? 'reveal' : 'conceal' }`
            }>
                <div className="sea-row headings">
                    <div></div>
                    <div>Chemical</div>
                    <div>Vessels / Facilitys</div>
                    <div>Quantity</div>
                    <div>Location</div>
                    <div>Hazardous</div>
                    <div>DG Class</div>
                    <div>SDS</div>
                    <div>Expiry</div>
                </div>
                <Profiler id="healthSafety.dangerousGoods" onRender={onProfilerRender}>
                    {mapArrayWithLimit(filteredDangerousGoods, (item) => {
                        return (
                            <div key={item.id} className="sea-card sea-row" onClick={(e) => onViewItem(item)}>
                                <div>
                                    <SeaFileImage files={item.imageFiles} size="tiny"/>
                                </div>
                                <div className={'bold truncate-2' + getClassName(item, true)}>{item.name}</div>
                                <div className="truncate-2">{!isSingleVessel && renderVesselsList(item.vesselIds, vessels, divisions)}</div>
                                <div className={'truncate-2' + getClassName(item)}>{formatValue(item.quantity)}</div>
                                <div className="truncate-2">{formatValue(item.location)}</div>
                                <div>{item.isHazardous ? 'Yes' : 'No'}</div>
                                <div className="truncate-2">{formatValue(item.class)}</div>
                                <div>
                                    {(item?.msdsFiles.length > 0) ?
                                        <SeaFileImage files={item.msdsFiles} size="tiny"/>
                                        :
                                        '-'
                                    }
                                </div>
                                <div className={item?.msdsFiles.length > 0 ? 'msds-files' : ''}>
                                    {item.dateExpires &&
                                        <SeaStatusDueDate
                                            dateDue={item.dateExpires}
                                            warnDays={warnDays.dangerousGoods[0]}
                                        />
                                    }
                                </div>
                            </div>
                        );
                    })}
                    {visible && limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    <EditDangerousGood
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        defaultVesselId={isSingleVessel ? filterVesselIds![0] : undefined}
                    />
                    {selectedItem && <ViewDangerousGood
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                </>
            }
        </RequirePermissions>
    );
};

export default DangerousGoodsRegister;
