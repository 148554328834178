import {collection, onSnapshot, query, where} from "firebase/firestore";
import {firestore} from "../../lib/firebase";
import {
    ArchivableDocument,
    CreateableDocument,
    sharedState,
    SharedStateConfig,
    UpdateableDocument
} from "../shared-state";
import {canView} from "../../shared-state/Core/userPermissions";
import {getDayOffset, MAX_DATE, warnDays} from '../../lib/datesAndTime';
import {renderFullName, renderFullNameForUserId} from "../Core/users";
import {registerFiles} from "../FileSyncSystem/filesToCache";
import {renderCategoryName} from '../../lib/categories';
import { UserType } from "../Core/user";

//
// Loads crew certificates (active)
//

export interface CrewCertificate extends CreateableDocument, UpdateableDocument, ArchivableDocument {
    emailReminder?: string;
    files: string[];
    heldBy: string;
    issuedBy?: string;
    licenseeId: string;
    state: string;
    title: string;
    titleId: string;
    type: 'renewable' | 'nonExpiring';
    wasRenewed?: boolean;
    dateExpires?: string; // yyyy-MM-dd
    dateIssued: string; // yyyy-MM-dd
    dateToRemind?: string; // yyyy-MM-dd
    // Generated at run time - not in original data
    searchText?: string;
}

export type CertificateHolderInfo = {
    id: string;
    name: string,
    roleIds: string[],
    certificates: CrewCertificate[]
};

export type CrewCertificatesData = {
    prioritised: CrewCertificate[],
    top5: CrewCertificate[],
    byId: {
        [id: string]: CrewCertificate
    },
    holdersSorted: CertificateHolderInfo[],
    holders: {
        [holderId: string]: CertificateHolderInfo
    },
    numHighestPriority: number,
    count: number
};

export const crewCertificatesConfig: SharedStateConfig<CrewCertificatesData> = {
    isAlwaysActive: false,
    dependencies: ['user', 'licenseeId', 'users', 'crewCertificateTitles'],
    countLiveDocs: () => sharedState.crewCertificates.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const user = sharedState.user.current;
        const licenseeId = sharedState.licenseeId.current;
        const users = sharedState.users.current;
        const crewCertificateTitles = sharedState.crewCertificateTitles.current;

        // Return early if we're missing dependent data
        if (!user || !licenseeId || !users || !crewCertificateTitles) return;

        const createHolderInfo = (user: UserType) => {
            return {
                id: user.id!,
                name: renderFullName(user),
                roleIds: user.roleIds ?? [],
                certificates: []
            } as CertificateHolderInfo;
        };

        const makeSearchText = (item: CrewCertificate) => {
            let s = item.title;
            if (item.heldBy) s += renderFullNameForUserId(item.heldBy);
            if (item.issuedBy) s += item.issuedBy;
            return s.toLowerCase();
        };

        return onSnapshot(
            query(
                collection(firestore, 'crewCertificates'),
                canView('crewCertificates') ? (
                    where('licenseeId', '==', licenseeId)
                ) : (
                    where('heldBy', '==', user.id)
                ),
                where('state', '==', 'active')
            ),
            (snap) => {
                done();
                const all = [] as CrewCertificate[];
                const top5 = [] as CrewCertificate[];
                const byId = {} as {
                    [id: string]: CrewCertificate
                };
                const holdersSorted = [] as CertificateHolderInfo[];
                const holders = {} as {
                    [userId: string]: CertificateHolderInfo
                };
                const userHasCertificateTitleId = {} as {
                    [userId: string]: {
                        [titleId: string]: true
                    }
                };

                // Build all, byId, holders, and holdersSorted
                snap.docs.forEach((doc) => {
                    const certificate = {
                        id: doc.id,
                        ...doc.data(),
                    } as CrewCertificate;
                    const user = users.byId[certificate.heldBy];

                    // Skip certificate if the holder isn't active
                    if (!user || user.state !== 'active') return;

                    // Create holder
                    if (holders[user.id!] === undefined) {
                        holders[user.id!] = createHolderInfo(user);
                        userHasCertificateTitleId[user.id!] = {};
                        holdersSorted.push(holders[user.id!]);
                    }
                    holders[user.id!].certificates.push(certificate);
                    userHasCertificateTitleId[user.id!][certificate.titleId] = true; // Note that this user has this certificate

                    // Hybdrate certificate with title and searchText
                    certificate.title = renderCategoryName(certificate.titleId, crewCertificateTitles);
                    certificate.searchText = makeSearchText(certificate);

                    registerFiles(certificate.files, 'crewCertificates', certificate);
                    all.push(certificate);
                    byId[certificate.id] = certificate;
                });

                // Build prioritised and top5.
                // prioritised should only contain renewable certificates whose
                // dateExpires is not too far in the future
                let prioritised = all.filter((certificate) => 
                    certificate.type === 'renewable' && certificate.dateExpires
                );
                let numHighestPriority = 0
                const minDateExpires = getDayOffset(warnDays.crewCertificates[0]);
                const maxDateExpires = getDayOffset(warnDays.crewCertificates[warnDays.crewCertificates.length - 1]);
                prioritised.sort((a, b) => {
                    return (a.dateExpires ?? MAX_DATE).localeCompare(
                        b.dateExpires ?? MAX_DATE
                    );
                });
                for (let i = 0; i < prioritised.length; i++) {
                    if (prioritised[i].dateExpires! < minDateExpires) {
                        numHighestPriority++;
                        if (top5.length < 5) {
                            top5.push(prioritised[i]);
                        }
                    } else if (prioritised[i].dateExpires! >= maxDateExpires) {
                        // No more prioritised are valid so we can cut the rest
                        prioritised = prioritised.slice(0, i);
                        break;
                    }
                }

                // Build compulsoryTitleIdsByRoleId so we can then
                // find missing certificates
                const compulsoryTitlesByRoleId = {} as {
                    [roleId: string]: {
                        id: string,
                        name: string
                    }[]
                };
                crewCertificateTitles.ids.forEach((titleId) => {
                    const title = crewCertificateTitles.byId[titleId];
                    if (title.state === 'active') {
                        title?.compulsoryForRoles?.forEach((roleId: string) => {
                            if (compulsoryTitlesByRoleId[roleId] === undefined) {
                                compulsoryTitlesByRoleId[roleId] = [];
                            }
                            compulsoryTitlesByRoleId[roleId].push({
                                id: titleId,
                                name: title.name
                            });
                        });
                    }
                });

                // Check users to see if they're missing any certificates
                // that they should have because of their roles.
                // For any missing certiticates, give the user a crewCertificate stub
                // with state=missing (useful for rendering).
                const usersToCheck = canView('crewCertificates') ? users.all : [user];
                usersToCheck.forEach((user) => {
                    user.roleIds?.forEach((roleId) => {
                        compulsoryTitlesByRoleId[roleId]?.forEach((title) => {
                            let holder = holders[user.id!];
                            if (holder === undefined) {
                                holder = createHolderInfo(user);
                                holders[user.id!] = holder;
                                holdersSorted.push(holder);
                                userHasCertificateTitleId[user.id!] = {};
                            }
                            if (!userHasCertificateTitleId[user.id!][title.id]) {
                                holder.certificates.push({
                                    id: `missing-${user.id}-${title.id}`,
                                    heldBy: user.id!, // Will not be undefined as we have checked for it above
                                    state: 'missing',
                                    title: title.name,
                                    titleId: title.id,
                                } as any); // Forced to any because this is only being used to indicate a missing certificate
                                userHasCertificateTitleId[user.id!][title.id] = true;
                            }
                        });
                    });
                });

                // Sort holdersSorted by name
                holdersSorted.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });

                // Sort each holder's certitifcates by title
                holdersSorted.forEach((holder) => {
                    holder.certificates.sort((a, b) => a.title.localeCompare(b.title));
                });

                set({
                    prioritised,
                    top5,
                    byId,
                    holdersSorted,
                    holders,
                    numHighestPriority,
                    count: all.length
                });
            }, (error) => {
                // This should be very rare
                done();
                console.error('Failed to access crew certificates ', error);
            }
        );
    }
};
































/*
function mapCertificates(docs: QueryDocumentSnapshot[], crewCertificateTitles: CategoriesData) {
    return docs.map((doc) => {
        const item = {
            id: doc.id,
            ...doc.data(),
            title: renderCategoryName(doc.data().titleId, crewCertificateTitles),
        } as CrewCertificate;
        let searchText = item.title.toLowerCase();
        if (item.heldBy) {
            searchText += renderFullNameForUserId(item.heldBy).toLowerCase();
        }
        if (item.issuedBy) {
            searchText += item.issuedBy;
        }
        item.searchText = searchText;
        return {
            ...item,
            searchText
        }
    }).sort((a, b) => {
        return a.searchText.localeCompare(b.searchText);
    });
}

const registerFilesForCertificates = (certificates: CrewCertificate[]) => {
    certificates.forEach((certificate) => {
        registerFiles(certificate.files, 'crewCertificates', certificate)
    })
};

function getActiveCertificates(certificates: CrewCertificate[], users: UsersData) {
    return certificates.filter((cert: CrewCertificate) => users.byId[cert.heldBy] && users.byId[cert.heldBy].state === 'active');
}

const makeCompulsoryTitlesByRole = (crewCertificateTitles: CategoriesData) => {
    const result: { [roleId: string]: { id: string, name: string }[] } = {};

    for (const title of Object.values(crewCertificateTitles.byId)) {
        if (title.compulsoryForRoles?.length && title.state === 'active') {
            for (const roleId of title.compulsoryForRoles) {
                if (result[roleId] === undefined) {
                    result[roleId] = [];
                }
                result[roleId].push({id: title.id, name: title.name});
            }
        }
    }

    return result;
};

const makeTop5PrioritisedCertificates = (prioritisedCertificates: CrewCertificate[]) => {
    const minDateExpires = getDayOffset(warnDays.crewCertificates[0]);
    return prioritisedCertificates.slice(0, 5).filter((p) => p.dateExpires && p.dateExpires < minDateExpires);
};

const makePrioritisedCertificates = (activeCertificates: CrewCertificate[]) => {
    let prioritised = [...activeCertificates] as CrewCertificate[];
    prioritised.sort((a, b) => {
        return (a.type === 'renewable' ? a.dateExpires ?? MAX_DATE : MAX_DATE).localeCompare(
            b.type === 'renewable' ? b.dateExpires ?? MAX_DATE : MAX_DATE
        );
    });
    // prioritised should only contain dateExpires up to a set amount days in the future
    // (and should not contain any nonExpiring either)
    const maxDateExpires = getDayOffset(warnDays.crewCertificates[warnDays.crewCertificates.length - 1]);
    const minDateExpires = getDayOffset(warnDays.crewCertificates[0]);
    let numHighestPriority = 0
    for (let i = 0; i < prioritised.length; i++) {
        if (
            prioritised[i].type !== 'nonExpiring' &&
            prioritised[i].dateExpires &&
            prioritised[i].dateExpires! < minDateExpires
        ) {
            numHighestPriority++
        }
        if (
            prioritised[i].type === 'nonExpiring' || (
                prioritised[i].dateExpires &&
                prioritised[i].dateExpires! >= maxDateExpires
            )
        ) {
            prioritised = prioritised.slice(0, i);
            break;
        }
    }
    return {
        prioritisedCertificates: prioritised,
        numHighestPriority
    };
};

const processUserCertificates = (users: UsersData, activeCertificates: CrewCertificate[], crewCertificateTitles: CategoriesData, licenseeId: string) => {
    const certificatesResult = [...activeCertificates];
    const holdersResult: { [id: string]: CertificateHolderInfo } = {}

    const compulsoryTitles: {
        [roleId: string]: { id: string; name: string }[]
    } = makeCompulsoryTitlesByRole(crewCertificateTitles)

    const activeUsers = users.all.filter((user) => user.state === 'active' && !!user.id)

    activeUsers.forEach(user => {
        holdersResult[user.id!] = {
            id: user.id!,
            name: renderFullNameForUserId(user.id),
            roleIds: user.roleIds ?? [],
            certificates: activeCertificates.filter((c: CrewCertificate) => c.heldBy === user.id),
        }

        const roleIds = user.roleIds ?? [];
        const userCompulsoryTitles = roleIds.map((roleId) => compulsoryTitles[roleId] ?? []).flat();

        userCompulsoryTitles.forEach((title) => {
            const existingCertificate = activeCertificates.find(cert => cert.heldBy === user.id && cert.titleId === title.id)

            if (!existingCertificate || !existingCertificate.files.length) {
                const missingCertificate: CrewCertificate = {
                    id: `missing-${user.id}-${title.id}`,
                    files: [],
                    heldBy: user.id!, // Will not be undefined as we have checked for it above
                    licenseeId: licenseeId,
                    state: 'missing',
                    title: title.name,
                    titleId: title.id,
                    type: 'compulsory',
                    dateIssued: getToday(),
                    whenAdded: Date.now(),
                    addedBy: '',
                }

                if (!existingCertificate) { // Cert doesn't exist so add it
                    certificatesResult.push(missingCertificate)
                    holdersResult[user.id!].certificates.push(missingCertificate)
                } else { // Cert does exist but has no files
                    // TODO - Do we need to update the state to be 'missing' if the cert has no files?
                }
            }
        })
    })

    return {processedCertificates: certificatesResult, processedCertificateHolders: holdersResult};
};

function buildCertificatesById(processedCertificates: CrewCertificate[]) {
    return processedCertificates.reduce((result, cert) => {
        result[cert.id] = cert;
        return result;
    }, {} as { [id: string]: CrewCertificate });
}

const buildCertificateHoldersSorted = (certificateHolders: { [p: string]: CertificateHolderInfo }) => {
    // Sort certs array for holders
    for (const userId in certificateHolders) {
        certificateHolders[userId].certificates.sort((a, b) => a.title.localeCompare(b.title));
    }

    // Remove holders who do not have any certificates
    const holdersWithAtLeastOneCertificate = Object.fromEntries(
        Object.entries(certificateHolders)
            .filter(([userId, certificateHolderInfo]) => certificateHolderInfo.certificates.length > 0)
    );

    // Build sorted array of CertHolderInfo based on cert name
    return Object.values(holdersWithAtLeastOneCertificate).sort((a, b) => a.name.localeCompare(b.name))
};

function makeHoldersWithCertificates(processedCertificateHolders: { [p: string]: CertificateHolderInfo }) {
    return Object.fromEntries(
        Object.entries(processedCertificateHolders)
            .filter(([userId, certificateHolderInfo]) => certificateHolderInfo.certificates.length > 0)
    );
}

const processDocs = (docs: QueryDocumentSnapshot[], userId: string, licenseeId: string, users: UsersData, crewCertificateTitles: CategoriesData): CrewCertificatesData => {
    const _certificates: CrewCertificate[] = mapCertificates(docs, crewCertificateTitles)

    registerFilesForCertificates(_certificates)

    const activeCertificates = getActiveCertificates(_certificates, users)

    const {prioritisedCertificates, numHighestPriority} = makePrioritisedCertificates(activeCertificates)
    const top5: CrewCertificate[] = makeTop5PrioritisedCertificates(prioritisedCertificates)

    const {
        processedCertificates,
        processedCertificateHolders
    } = processUserCertificates(users, activeCertificates, crewCertificateTitles, licenseeId)

    const byId = buildCertificatesById(processedCertificates)
    const holders = makeHoldersWithCertificates(processedCertificateHolders);
    const holdersSorted = buildCertificateHoldersSorted(holders)

    return {
        prioritised: prioritisedCertificates,
        numHighestPriority,
        top5,
        byId,
        holders,
        holdersSorted,
        count: _certificates.length
    }
};

export const crewCertificatesConfig: SharedStateConfig<CrewCertificatesData> = {
    isAlwaysActive: false,
    dependencies: ['userId', 'licenseeId', 'users', 'crewCertificateTitles'],
    countLiveDocs: () => sharedState.crewCertificates.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();

        // Dependant data
        const userId = sharedState.userId.current;
        const licenseeId = sharedState.licenseeId.current;
        const users = sharedState.users.current;
        const crewCertificateTitles = sharedState.crewCertificateTitles.current;

        // Return early if we're missing dependent data
        if (!userId || !licenseeId || !users || !crewCertificateTitles) return;

        return onSnapshot(
            query(
                collection(firestore, 'crewCertificates'),
                canView('crewCertificates') ? (
                    where('licenseeId', '==', licenseeId)
                ) : (
                    where('heldBy', '==', userId)
                ),
                where('state', '==', 'active')
            ),
            (snap) => {
                done();
                set(
                    processDocs(snap.docs, userId, licenseeId, users, crewCertificateTitles)
                );
            }, (error) => {
                // This should be very rare
                done();
                console.error('Failed to access crew certificates ', error);
            }
        );
    }
};
*/