import React, { useEffect, useMemo, useState } from 'react';
import { IonCol, IonGrid, IonRow, IonSelectOption } from '@ionic/react';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaLabel from '../../../../components/SeaLabel/SeaLabel';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';
import EditContextualHelp from '../EditContextualHelp/EditContextualHelp';
import SeaSelectVessels from '../../../../components/SeaSelectVessels/SeaSelectVessels';
import './BuildCustomFormElementPanel.css';

interface BuildCustomFormElementPanelProps {
    element?: any,
    updateElement: (element: any) => void,
    removeElement: (element: any) => void,
    moveElement: (element: any, movement: number) => void,
    canMoveElement: (element: any, movement: number) => boolean,
    historyElementN: number,
    setHistoryElementN: (n: number) => void
}

const BuildCustomFormElementPanel: React.FC<BuildCustomFormElementPanelProps> = ({element, updateElement, removeElement, moveElement, canMoveElement, historyElementN, setHistoryElementN}) => {
    const [elementText, setElementText] = useState<string>();
    const [showEditContextualHelp, setShowEditContextualHelp] = useState(false);

    const onChange = (e: CustomEvent<any>, id: string) => {
        const _element = {...element};
        if (_element[id] === e.detail.value) {
            return;
        }
        _element[id] = e.detail.value;
        updateElement(_element);
    };
    const changeValue = (id: string, value: any) => {
        const _element = {...element};
        _element[id] = value;
        updateElement(_element);
    }
    const onFocus = (e: CustomEvent<any>) => {
        // This somehow fixes the problem of the first input being eaten
        // Yes, it is weird, but it works
        updateElement({...element});
    };

    const onDelete = () => {
        confirmAction('Are you sure you want to delete this element?', 'Yes, delete').then(() => {
            removeElement(element);
        }).catch(() => {});
    };

    const canShowInHistory = useMemo(() => {
        if (element?.id) {
            switch (element.id) {
                case 'input':
                case 'textarea':
                case 'checkbox':
                case 'dropdown':
                case 'date':
                case 'datetime':
                    return true;
            }
        }
        return false;
    }, [element?.id]);

    const canHaveHelp = useMemo(() => {
        if (element?.id) {
            switch (element.id) {
                case 'line':
                case 'spacer':
                    return false;
            }
        }
        if (element?.isSpecial) return false;
        return true;
    }, [element?.id]);

    useEffect(() => {
        if (element?.text !== undefined) {
            // This is here because something was going wrong using onChange with SeaTextArea
            setElementText(element.text);
        }
    }, [element?.n]);

    if (element === undefined) return <></>;

    return (
        <IonGrid className="form-grid custom-elements">
            <IonRow>
                {element.heading !== undefined &&
                    <IonCol size="12">
                        <SeaInput
                            key={`heading${element.n}`}
                            label="heading"
                            name="heading"
                            value={element.heading}
                            type="text"
                            onchange={(e) => onChange(e, 'heading')}
                            onfocus={(e) => onFocus(e)}
                        />
                    </IonCol>
                }
                {element.label !== undefined &&
                    <IonCol size="12">
                        <SeaInput
                            key={`label${element.n}`}
                            label="label"
                            name="label"
                            value={element.label}
                            type="text"
                            onchange={(e) => onChange(e, 'label')}
                            onfocus={(e) => onFocus(e)}
                        />
                    </IonCol>
                }
                {element.id === 'input' &&
                    <IonCol size="12">
                        <SeaSelect
                            key={`type${element.n}`}
                            label="Type"
                            name="type"
                            value={element.type ? element.type : ''}
                            onchange={(e) => onChange(e, 'type')}
                        >
                            <>
                                <IonSelectOption value="">Text</IonSelectOption>
                                <IonSelectOption value="number">Number</IonSelectOption>
                            </>
                        </SeaSelect>
                    </IonCol>
                }
                {element.lines !== undefined &&
                    <IonCol size="12">
                        <SeaInput
                            key={`lines${element.n}`}
                            label="Height (lines)"
                            name="lines"
                            value={element.lines}
                            type="number"
                            inputmode="numeric"
                            onchange={(e) => onChange(e, 'lines')}
                            onfocus={(e) => onFocus(e)}
                        />
                    </IonCol>
                }
                {element.height !== undefined &&
                    <IonCol size="12">
                        <SeaInput
                            key={`height${element.n}`}
                            label="Height (pixels)"
                            name="height"
                            value={element.height}
                            type="number"
                            inputmode="numeric"
                            onchange={(e) => onChange(e, 'height')}
                            onfocus={(e) => onFocus(e)}
                        />
                    </IonCol>
                }
                {element.description !== undefined &&
                    <IonCol size="12">
                        <SeaInput
                            key={`description${element.n}`}
                            label="description"
                            name="description"
                            value={element.description}
                            type="text"
                            onchange={(e) => onChange(e, 'description')}
                            onfocus={(e) => onFocus(e)}
                        />
                    </IonCol>
                }
                {element.text !== undefined &&
                    <IonCol size="12">
                        <SeaTextarea
                            key={`text${element.n}`}
                            label="text"
                            name="text"
                            value={elementText}
                            onchange={(e) => {
                                setElementText(e.detail.value);
                                onChange(e, 'text');
                            }}
                            //onfocus={(e) => onFocus(e)}
                        />
                    </IonCol>
                }
                {element.options !== undefined && 
                    <IonCol size="12">
                        <SeaInputList
                            name="options"
                            label={element.id === 'checks' ? 'Checks' : 'Dropdown Options'}
                            //maxWidth="500px"
                            values={element.options}
                            setValues={(options) => {
                                changeValue('options', options);
                            }}
                            addNewText={element.id === 'checks' ? 'Add check' : 'Add option'}
                            confirmDelete={true}
                            itemName={element.id === 'checks' ? 'check' : 'option'}
                            draggable
                        />
                    </IonCol>
                }
                {element.required !== undefined &&
                    <IonCol size="12">
                        <SeaCheckbox
                            key={`checkbox${element.n}`}
                            //label="Is Required?"
                            name="required"
                            checked={element.required ? true : false}
                            setFieldValue={(name: string, value: boolean) => {
                                changeValue('required', value);
                                //onBlur();
                            }}
                        >
                            {element.id === 'checkbox' ? 'This is required' : 'This is required'}
                        </SeaCheckbox>
                    </IonCol>
                }
                {canShowInHistory &&
                    <IonCol size="12">
                        <SeaCheckbox
                            key={`showInHistory${element.n}`}
                            //label="Show in history?"
                            name="showInHistory"
                            checked={element.n === historyElementN}
                            help={{
                                text: `Ticking this will show this field\'s value in the history.
                                    \nNote: You can only do this for one field. Any other fields will be automatically unticked.
                                    `
                            }}
                            setFieldValue={(name: string, value: boolean) => {
                                if (value) {
                                    if (
                                        historyElementN &&
                                        historyElementN !== element.n
                                    ) {
                                        // confirmAction('Are sure you want to show this field in history, replacing the current field being shown?', 'Yes, I want this field to be shown')
                                        // .then(() => {
                                        //     setHistoryElementN(element.n);
                                        // }).catch(() => {
                                        //     // Ignore
                                        // });
                                        setHistoryElementN(element.n);
                                    } else {
                                        setHistoryElementN(element.n);
                                    }
                                } else {
                                    setHistoryElementN(0);
                                }
                            }}
                        >
                            Show in history
                        </SeaCheckbox>
                    </IonCol>
                }
                {element.size !== undefined &&
                    <IonCol size="12">
                        <SeaSelect
                            key={`size${element.n}`}
                            label="Size"
                            name="size"
                            value={element.size}
                            onchange={(e) => onChange(e, 'size')}
                        >
                            <>
                                <IonSelectOption value="large">Large</IonSelectOption>
                                <IonSelectOption value="medium">Medium</IonSelectOption>
                                <IonSelectOption value="small">Small</IonSelectOption>
                                <IonSelectOption value="tiny">Tiny</IonSelectOption>
                            </>
                        </SeaSelect>
                    </IonCol>
                }
                <IonCol size="12">
                    <SeaSelect
                        key={`width${element.n}`}
                        label="Element Width"
                        name="width"
                        value={''+element.width}
                        onchange={(e) => onChange(e, 'width')}
                    >
                        <IonSelectOption value="100">Full</IonSelectOption>
                        <IonSelectOption value="50">1/2</IonSelectOption>
                        {element.id !== 'signature' && element.id !== 'files' && element.id !== 'checks' &&
                            <>
                                <IonSelectOption value="33.33">1/3</IonSelectOption>
                                <IonSelectOption value="66.66">2/3</IonSelectOption>
                                <IonSelectOption value="25">1/4</IonSelectOption>
                                <IonSelectOption value="75">3/4</IonSelectOption>
                            </>
                        }
                    </SeaSelect>
                </IonCol>
                {element.forVesselIds !== undefined &&
                    <IonCol size="12">
                        <SeaSelectVessels
                            label="Vessels / facilities allowed"
                            help={{text: 'Select which vessels / facilities this form can be linked to.'}}
                            modalTitle="Vessels / facilities allowed"
                            vesselIds={element.forVesselIds}
                            setVesselIds={(_values) => {
                                const values = (typeof _values === 'function') ? _values(element.forVesselIds) : _values;
                                element.forVesselIds = values;
                                element.isSubmitting = true;
                                changeValue('forVesselIds', values);
                            }}
                            required={true}
                            emptyText="Not Set"
                            isSubmitting={element.isSubmitting}
                        />
                    </IonCol>
                }
                {element.allowMultiple !== undefined &&
                    <IonCol size="12">
                        <SeaCheckbox
                            key={`allowMultiple${element.n}`}
                            name="allowMultiple"
                            checked={element.allowMultiple ? true : false}
                            setFieldValue={(name: string, value: boolean) => {
                                changeValue('allowMultiple', value);
                            }}
                            help={{
                                text: `Tick if you want to be able to complete this form for more than one ${element.id === 'vessels' ? 'vessel / facility' : 'crew member'} at a time.`
                            }}
                        >
                            Allow multiple {element.id === 'vessels' ? 'vessels / facilities' : 'personnel'}
                        </SeaCheckbox>
                    </IonCol>
                }
                {canHaveHelp &&
                    <IonCol size="12">
                        <SeaLabel>Contextual Help</SeaLabel>
                        <SeaButton
                            size="standard"
                            onClick={(e) => {
                                setShowEditContextualHelp(true)
                            }}
                        >
                            {element.help ? 'Edit Help' : 'Add Help'}
                        </SeaButton>
                        {element.help &&
                            <SeaButton
                                size="standard"
                                onClick={(e) => {
                                    changeValue('help', undefined);
                                }}
                            >
                                Remove Help
                            </SeaButton>
                        }
                    </IonCol>
                }
                {!element.isSpecial && 
                    <IonCol size="12">
                        <SeaLabel>Reposition Element</SeaLabel>
                        <SeaButton
                            shape="circle"
                            onClick={(e) => moveElement(element, -1)}
                            disabled={!canMoveElement(element, -1)}
                        >
                            <SeaIcon
                                slot="icon-only"
                                icon="moveUp"
                                forceFontSize="25px"
                                marginBottom={2}
                            />
                        </SeaButton>
                        <SeaButton
                            shape="circle"
                            onClick={(e) => moveElement(element, 1)}
                            disabled={!canMoveElement(element, 1)}
                        >
                            <SeaIcon
                                slot="icon-only"
                                icon="moveDown"
                                forceFontSize="25px"
                                marginBottom={0}
                            />
                        </SeaButton>
                    </IonCol>
                }
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    {element.isSpecial ? (
                        <div style={{
                            padding: '30px 0px',
                            fontSize: '13px'
                        }}>
                            Note: This element is here because this form is associated with {element.id === 'vessels' ? 'vessels' : 'personnel' }. It can't be removed and must remain at the top of the page.
                        </div>
                    ) : (
                        <div className="view-modal-buttons">
                            <SeaButton zone="white" transparent onClick={(e) => onDelete()}>
                                <SeaIcon slot="start" icon="trash" />
                                Remove Element
                            </SeaButton>
                        </div>
                    )}
                </IonCol>
            </IonRow>
            <EditContextualHelp
                showModal={showEditContextualHelp} 
                setShowModal={setShowEditContextualHelp}
                element={element}
                changeValue={changeValue}
            />
        </IonGrid>
    );
};

export default BuildCustomFormElementPanel;
