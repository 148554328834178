import React, { useEffect, useState } from 'react';
import { formatValue, taskStatuses } from '../../../../lib/util';
import { formatDateShort, warnDays, dateDueToClassName } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { IonSelectOption } from '@ionic/react';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { ScheduledMaintenanceTask } from '../../../../shared-state/VesselMaintenance/maintenanceSchedule';
import { ReportingEquipmentByIdData } from '../../../../shared-state/Reporting/MaintenanceReporting/useReportingEquipmentById';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import SeaHorizontalStackedBarGraph from '../../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import reporting from '../../../../lib/reporting';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaStatusIcon from '../../../../components/SeaStatusIcon/SeaStatusIcon';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import MaintenanceScheduleReportPDF from '../../../../exports/Reporting/Maintenance/MaintenanceScheduleReportPdf';
import SeaStatusDueHours from '../../../../components/SeaStatusDueHours/SeaStatusDueHours';
import './ViewMaintenanceScheduleReport.css';

const maintenanceScheduleOptions = Object.keys(taskStatuses).map((statusId: string) => {
    return {
        id: statusId,
        name: taskStatuses[statusId]
    };
});

interface ViewMaintenanceScheduleReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredItems?: ScheduledMaintenanceTask[],
    categories?: (string | undefined)[],
    selectedStatuses: string[],
    setSelectedStatuses: React.Dispatch<React.SetStateAction<string[]>>,
    itemCriticality: string,
    setItemCriticality: React.Dispatch<React.SetStateAction<string>>,
    itemsById?: ReportingEquipmentByIdData,
}

const ViewMaintenanceScheduleReport: React.FC<ViewMaintenanceScheduleReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredItems,
    categories,
    selectedStatuses,
    setSelectedStatuses,
    itemCriticality,
    setItemCriticality,
    itemsById
}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const { selectedVesselId } = useReportSettings();
    const [exportType, setExportType] = useState<ExportType>();
    const [labelWidthsForPdf, setLabelWidthsForPdf] = useState<number[]>([]);

    useEffect(() => {
        if (showModal) {
            logModalView('MaintenanceScheduleStatusReport', true);
        }
    }, [showModal]);

    useEffect(() => {
        resetPageLimit();
    }, [showModal, filteredItems, resetPageLimit]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={filteredItems && exportType === 'pdf' &&
                <MaintenanceScheduleReportPDF
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredItems={filteredItems}
                    itemsById={itemsById}
                    categories={categories}
                    labelWidthsForPdf={labelWidthsForPdf}
                />
            }
            setExportType={setExportType}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={false}
                extraComponents={[
                    <SeaMultiSelect
                        label="Maintenance Task Statuses"
                        values={selectedStatuses}
                        setValues={setSelectedStatuses}
                        options={maintenanceScheduleOptions}
                        useAllOption={true}
                    />,
                    <SeaSelect
                        label="Critical?"
                        value={itemCriticality}
                        onchange={(e) => {
                            setItemCriticality(e.detail.value);
                        }}
                    >
                        <IonSelectOption value="both">Critical & Non-Critical</IonSelectOption>
                        <IonSelectOption value="critical">Critical Only</IonSelectOption>
                        <IonSelectOption value="nonCritical">Non-Critical Only</IonSelectOption>
                    </SeaSelect>
                ]}
            />

            <SeaHorizontalStackedBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                categories={categories}
                setLabelWidthsForPdf={setLabelWidthsForPdf}
                colourPalette={reporting.colours.taskStatuses}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="maintenance tasks"
                    isLoading={!filteredItems}
                    hasNoData={filteredItems?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`maintenance-schedule-report has-thumbs has-status ${(filteredItems && filteredItems.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div></div>
                        <div>Equipment</div>
                        <div>Next Due</div>
                        <div>Vessel</div>
                        <div>Status</div>
                        <div>Critical</div>
                    </div>
                    {mapArrayWithLimit(filteredItems, (item) => {
                        return (
                            <div
                                key={item.id}
                                className={`sea-card sea-row-history ${dateDueToClassName(item.dateDue, warnDays.maintenanceSchedule[0])}`}
                            >
                                <div>
                                    <SeaFileImage files={itemsById?.[item.equipmentId]?.files} size="tiny"/>
                                </div>
                                <div className="bold truncate-2">
                                    {formatValue(item.equipmentId ? itemsById?.[item.equipmentId]?.equipment : '')}
                                </div>
                                <div>{formatDateShort(item.dateDue)}</div>
                                <div className="truncate-2">
                                    {renderVesselName(item.vesselId)}
                                </div>
                                <div>
                                    {item.useHours ? (
                                        <SeaStatusDueHours engineHoursLeft={item.engineHoursLeft} />
                                     ) : (
                                         <SeaStatusDueDate dateDue={item.dateDue} warnDays={warnDays.maintenanceSchedule[0]}/>
                                     )}
                                </div>
                                <div>
                                    {itemsById?.[item.equipmentId]?.isCritical && (
                                        <SeaStatusIcon
                                            icon="flag"
                                            className="critical bottom"
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>
        </SeaModal>
    );
};

export default ViewMaintenanceScheduleReport;
