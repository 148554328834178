import React, { useEffect, useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { formatDate, formatDateLonger, formatTime } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { canViewAction, formatAction, getActionIcon } from '../../../Utilities/ActionLogModal/ActionLogModal';
import { canAccessAnyVessels } from '../../../../shared-state/Core/userPermissions';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { openContextualHelp } from '../../../../managers/ContextualHelpManager/ContextualHelpManager';
import { ActionLogEntry } from '../../../../shared-state/General/actionLog';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import { renderVesselsList } from '../../../../shared-state/Core/vessels';
import SeaHorizontalBarGraph, { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import ActionsLoggedReportPdf from '../../../../exports/Reporting/Crew/ActionsLoggedReportPdf';
import './ViewActionsLoggedReport.css';

interface ViewActionsLoggedReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle?: string,
    graphData?: GraphData[],
    filteredItems?: ActionLogEntry[],
    yLabelWidth?: number,
    colourPalette?: string[],
    vesselName?: string,
}

const ViewActionsLoggedReport: React.FC<ViewActionsLoggedReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredItems,
    yLabelWidth,
    colourPalette,
    vesselName
}) => {
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const divisions = sharedState.divisions.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const user = sharedState.user.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [exportType, setExportType] = useState<ExportType>();
    const [vesselActions, setVesselActions] = useState<{
        days: string[];
        byDay: {
            [key: string]: ActionLogEntry[];
        }
    }>();

    useEffect(() => {
        if (showModal) {
            logModalView('Crew Certificates Report', true);
        }
    }, [showModal]);

    useEffect(() => {
        resetPageLimit();
    }, [showModal, filteredItems, resetPageLimit])

    useEffect(() => {
        setVesselActions(undefined);
        if (showModal && filteredItems) {
            const days = [] as string[];
            const byDay: {
                [key: string]: ActionLogEntry[],
            } = {} ;

            filteredItems.forEach((action) => {
                if (
                    (
                        vesselId === undefined ||
                        action.vesselIds === 'any' ||
                        action.vesselIds.includes(vesselId)
                    ) && (
                        vesselId !== undefined ||
                        action.vesselIds === 'any' ||
                        canAccessAnyVessels(action.vesselIds)
                    ) &&
                    canViewAction(action)
                ) {
                    const day = formatDate(action.when);
                    if (days.length === 0 || days[days.length - 1] !== day) {
                        days.push(day);
                        byDay[day] = [];
                    }
                    byDay[day].push(action);
                }
            });

            setVesselActions({
                days,
                byDay
            });
        }
    }, [showModal, vesselId, userId, user, filteredItems]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={exportType === 'pdf' &&
                <ActionsLoggedReportPdf
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredItems={filteredItems}
                    yLabelWidth={yLabelWidth}
                    colourPalette={colourPalette}
                    vesselActions={vesselActions}
                    vesselName={vesselName}
                />
            }
            setExportType={setExportType}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={true}
            />

            <SeaHorizontalBarGraph
                n={0}
                title={title}
                subTitle={subTitle}
                mode="modal"
                data={graphData}
                sortData={true}
                units="Actions Logged"
                colourPalette={colourPalette}
                hashNamesForColours={false}
                yLabelWidth={yLabelWidth}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="actions logged by crew"
                    isLoading={!filteredItems}
                    hasNoData={filteredItems?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`action-log-report ${(filteredItems && filteredItems.length > 0) ? 'reveal' : 'conceal'}`}>
                    <div className="sea-row-history headings-history">
                        <div>Date</div>
                        <div>Done By</div>
                        <div></div>
                        <div>Action</div>
                        <div>Detail</div>
                        <div className="truncate">Vessels / Facilities</div>
                    </div>
                    {vesselActions?.days?.map((day: string) => {
                        if (isLimitReached()) {
                            return undefined;
                        }
                        return (
                            <React.Fragment key={day}>
                                <div className="category-heading">
                                    {formatDateLonger(vesselActions.byDay[day][0].when)}
                                </div>
                                <div className="custom-history-table">
                                    {mapArrayWithLimit(vesselActions?.byDay[day], (action) => {
                                        return (
                                            <div className="sea-card sea-row-history" key={action.id}>
                                                <div data-fullname={`, ${renderFullNameForUserId(action.userId)}`}>{formatTime(action.when)}</div>
                                                <div onClick={(e) => openContextualHelp(e, {text: renderFullNameForUserId(action.userId)})}>
                                                    {renderFullNameForUserId(action.userId)}
                                                </div>
                                                <div>{getActionIcon(action)}</div>
                                                <div className="truncate-2" onClick={(e) => openContextualHelp(e, {text: formatAction(action, licenseeSettings)})}>
                                                    {formatAction(action, licenseeSettings)}
                                                </div>
                                                <div className="truncate-2" onClick={(e) => openContextualHelp(e, {text: action.detail})}>
                                                    {formatValue(action.detail)}
                                                </div>
                                                <div onClick={(e) => openContextualHelp(e, {text: renderVesselsList(action.vesselIds, vessels, divisions)})}>
                                                    {renderVesselsList(action.vesselIds, vessels, divisions)}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>
        </SeaModal>
    );
};

export default ViewActionsLoggedReport;
