import React, { useState, useEffect, useMemo } from 'react';
import { logPageView } from '../../../lib/firebase';
import { extractSearchTerms, formatValue } from '../../../lib/util';
import { formatDateShort } from '../../../lib/datesAndTime';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { sharedState } from '../../../shared-state/shared-state';
import { CorrectiveAction } from '../../../shared-state/HealthSafety/correctiveActions';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { useLocation } from 'react-router-dom';
import { renderVesselsList } from '../../../shared-state/Core/vessels';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaSearchbar from '../../../components/SeaSearchbar/SeaSearchbar';
import EditCorrectiveAction from '../../../modals/HealthSafety/CorrectiveActions/EditCorrectiveAction/EditCorrectiveAction';
import ViewCorrectiveAction from '../../../modals/HealthSafety/CorrectiveActions/ViewCorrectiveAction/ViewCorrectiveAction';
import EditCorrectiveActionsSettings from '../../../modals/HealthSafety/CorrectiveActions/EditCorrectiveActionSettings/EditCorrectiveActionsSettings';
import CorrectiveActionsCsv from '../../../exports/HealthSafety/CorrectiveActions/CorrectiveActionsCsv';
import CorrectiveActionsPdf from '../../../exports/HealthSafety/CorrectiveActions/CorrectiveActionsPdf';
import CorrectiveActionsHistoryModal from '../../../modals/HealthSafety/CorrectiveActions/CorrectiveActionsHistoryModal/CorrectiveActionsHistoryModal';
import QueryString from 'query-string';
import SeaSelectVessels from '../../../components/SeaSelectVessels/SeaSelectVessels';
import './CorrectiveActions.css';

interface CorrectiveActionsProps {
    visible: boolean;
    filterVesselIds: string[] | undefined;
    setFilterVesselIds: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

const CorrectiveActions: React.FC<CorrectiveActionsProps> = ({ visible, filterVesselIds, setFilterVesselIds }) => {
    const correctiveActions = sharedState.correctiveActions.use(visible ? 1 : 101);
    const vessels = sharedState.vessels.use(visible ? 1 : 101);
    const divisions = sharedState.divisions.use(visible ? 1 : 101);
    const location = useLocation();
    sharedState.contacts.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.vesselLocations.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.safetyCheckItems.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.spareParts.use(visible ? 51 : 0); // Prepare for modals to access

    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();

    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showCorrectiveActionsSettings, setShowCorrectiveActionsSettings] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();
    const [selectedItem, setSelectedItem] = useState<CorrectiveAction>();
    const [searchText, setSearchText] = useState('');
    const [showHistoryModal, setShowHistoryModal] = useState(false);

    useEffect(() => {
        if (visible) {
            logPageView('HealthSafety/CorrectiveActions');
        }
    }, [visible]);

    useEffect(() => {
        resetPageLimit();
    }, [searchText, filterVesselIds, resetPageLimit]);

    const isSingleVessel = useMemo(() => {
        return (filterVesselIds && filterVesselIds.length === 1);
    }, [filterVesselIds]);

    useEffect(() => {
        const qs = QueryString.parse(location.search);
        if (qs.action && correctiveActions?.array.all) {
            const action = correctiveActions.array.all.find((ca) => ca.id === qs.action);
            if (action && action.state !== 'completed') {
                setSelectedItem(action);
                setShowViewItemModal(true); 
            }
        }
    }, [visible, correctiveActions?.array.all, location.search]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (correctiveActions?.byId && selectedItem?.id) {
            const action = correctiveActions.byId[selectedItem.id];
            if (action) {
                setSelectedItem(action);
            }
        }
    }, [correctiveActions, selectedItem?.id]);

    const filteredCorrectiveActions = useMemo(() => {
        let _correctiveActions: CorrectiveAction[] = [];
        let _filteredCorrectiveActions: CorrectiveAction[] = [];
        const terms = extractSearchTerms(searchText, true);
        if (filterVesselIds) {
            const hasCorrectiveAction = {} as {[id: string]: true};
            filterVesselIds.forEach((vesselId) => {
                correctiveActions?.byVesselId[vesselId]?.forEach((correctiveAction) => {
                    if (!hasCorrectiveAction[correctiveAction.id]) {
                        hasCorrectiveAction[correctiveAction.id] = true;
                        _correctiveActions.push(correctiveAction);
                    }
                });
            });
            if (filterVesselIds.length > 1) {
                _correctiveActions.sort((a, b) => {
                    return a.title.localeCompare(b.title);
                });
            }
        }
        if (_correctiveActions) {
            for (let i = 0; i < _correctiveActions.length; i++) {
                const correctiveAction = _correctiveActions[i];
                if (terms.length > 0) {
                    let isMatch = true;
                    for (let j = 0; j < terms.length; j++) {
                        if (correctiveAction.searchText?.indexOf(terms[j]) === -1) {
                            isMatch = false;
                            break;
                        }
                    }
                    if (!isMatch) {
                        continue;
                    }
                }
                _filteredCorrectiveActions.push(correctiveAction);
            }
        }
        return _filteredCorrectiveActions;
    }, [searchText, filterVesselIds, correctiveActions]);

    const activeFilteredCorrectiveActions = useMemo(() => {
        return filteredCorrectiveActions.filter((action) => action.state === 'active')
    }, [filteredCorrectiveActions]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };

    const onViewHistoryModal = () => {
        setShowHistoryModal(true);
    };

    const onViewItem = (item: CorrectiveAction) => {
        setShowViewItemModal(true);
        setSelectedItem(item);
    };
    const onEditSettings = () => {
        setShowCorrectiveActionsSettings(true);
    };

    return (
        <RequirePermissions role="correctiveActions" level={permissionLevels.VIEW} showDenial={true} licenseePermission="hasCorrectiveActions">
            <div className="corrective-actions page-head">
                <div>
                    <h2>Corrective Action List</h2>
                </div>
                <div className="actions">
                    <RequirePermissions role="correctiveActions" level={permissionLevels.CREATE}>
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            Add New
                        </SeaButton>
                        <div className="spacer"></div>
                    </RequirePermissions>
                    <RequirePermissions role="correctiveActions" level={permissionLevels.COMPLETE}>
                        <SeaButton onClick={onViewHistoryModal} zone="grey">
                            <SeaIcon slot="start" icon="archive"/>
                            History
                        </SeaButton>
                        <div className="spacer"></div>
                    </RequirePermissions>
                    <SeaExporter
                        setExportType={setExportType}
                        csv={exportType === 'csv' && <CorrectiveActionsCsv onCompleted={() => setExportType(undefined)} />}
                        pdf={exportType === 'pdf' &&
                            <CorrectiveActionsPdf
                                onCompleted={() => setExportType(undefined)}
                                filterVesselIds={filterVesselIds}
                                filteredCorrectiveActions={activeFilteredCorrectiveActions}
                            />
                        }
                    />
                    <RequirePermissions role="correctiveActions" level={permissionLevels.EDIT}>
                        <SeaButton zone="grey" shape="circle" onClick={(e) => onEditSettings()}>
                            <SeaIcon slot="icon-only" icon="settings" />
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            {/* Filters */}
            <div className="columns wrap filters reveal">
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
                {vessels && vessels.all.length > 1 &&
                    <div>
                        <SeaSelectVessels
                            vesselIds={filterVesselIds}
                            setVesselIds={setFilterVesselIds}
                            zone="grey"
                            width="300px"
                            emptyText="None"
                        />
                    </div>
                }
            </div>

            <SeaNoData dataName="correctiveActions" isLoading={!activeFilteredCorrectiveActions} hasNoData={activeFilteredCorrectiveActions?.length === 0} isUsingFilter={true} />

            <div className={`corrective-actions has-thumbs ${activeFilteredCorrectiveActions && activeFilteredCorrectiveActions.length > 0 ? 'reveal' : 'conceal'}`}>
                <div className="sea-row headings">
                    <div></div>
                    <div>Corrective Action</div>
                    <div>Action #</div>
                    <div>Assigned</div>
                    <div>Tags</div>
                    <div>Date Added</div>
                    <div>Due Date</div>
                    {!isSingleVessel && <div>Vessels</div>}
                </div>
                {mapArrayWithLimit(activeFilteredCorrectiveActions, (item: CorrectiveAction) => {
                        return (
                            <div
                                key={item.id}
                                className="sea-card sea-row"
                                onClick={(e) => onViewItem(item)}
                            >
                                <div>
                                    <SeaFileImage files={item.files} size="tiny"/>
                                </div>
                                <div className="bold truncate-2">
                                    {formatValue(item.title)}
                                </div>
                                <div>
                                    {formatValue(item.correctiveActionNum)}
                                </div>
                                <div>
                                    {renderFullNameForUserId(item.assignedTo)}
                                </div>
                                <div>
                                    {item.tags?.join(', ')}
                                </div>
                                <div>{formatDateShort(item.whenAdded)}</div>
                                <div>
                                    {item.dateDue ? formatDateShort(item.dateDue) : '-'}
                                </div>
                                {!isSingleVessel && <div>{renderVesselsList(item.vesselIds, vessels, divisions)}</div>}
                            </div>
                        );
                    })}
                    {limitTriggerElement}
            </div>
            {visible && (
                <>
                    <EditCorrectiveAction
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                    />
                    <ViewCorrectiveAction
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />
                    <EditCorrectiveActionsSettings
                        showModal={showCorrectiveActionsSettings}
                        setShowModal={setShowCorrectiveActionsSettings}
                    />
                    <CorrectiveActionsHistoryModal
                        showModal={showHistoryModal}
                        setShowModal={setShowHistoryModal}
                        filteredCorrectiveActions={filteredCorrectiveActions}
                    />
                </>
            )}
        </RequirePermissions>
    );
};

export default CorrectiveActions;
