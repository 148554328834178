import React, { useEffect, useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { formatDatetime } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { renderCategoryName } from '../../../../lib/categories';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { sharedState } from '../../../../shared-state/shared-state';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { Incident } from '../../../../shared-state/HealthSafety/incidents';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaPieGraph from '../../../../components/reporting/SeaPieGraph/SeaPieGraph';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import IncidentCategoriesReportPdf from '../../../../exports/Reporting/HealthSafety/IncidentCategoriesReportPdf';
import './ViewIncidentCategoriesReport.css';

interface ViewIncidentCategoriesReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredIncidents?: Incident[],
}

const ViewIncidentCategoriesReport: React.FC<ViewIncidentCategoriesReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredIncidents,
}) => {
    const incidentCategories = sharedState.incidentCategories.use(showModal);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const { selectedVesselId } = useReportSettings();
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (showModal) {
            logModalView('IncidentTypesReport', true);
        }
    }, [showModal]);

    useEffect(() => {
        resetPageLimit();
    }, [showModal, filteredIncidents, resetPageLimit]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={exportType === 'pdf' &&
                <IncidentCategoriesReportPdf
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredIncidents={filteredIncidents}
                />
            }
            setExportType={setExportType}
        >
            <SeaReportSettings mode="modal" />

            <SeaPieGraph
                mode="modal"
                data={graphData}
                visible={showModal}
                hashNamesForColours={true}
            />

            <div className="reporting-data">
                <SeaNoData
                    dataName="incidents"
                    isLoading={!filteredIncidents}
                    hasNoData={filteredIncidents?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`incidents by-category ${(filteredIncidents && filteredIncidents.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div>Date</div>
                        <div>Category</div>
                        <div>Name</div>
                        <div>Vessel</div>
                    </div>
                    {mapArrayWithLimit(filteredIncidents, (incident) => {
                        return (
                            <div
                                key={incident.id}
                                className="sea-card sea-row-history"
                            >
                                <div>
                                    {formatDatetime(incident.whenEvent)}
                                </div>
                                <div className="truncate">
                                    {renderCategoryName(incident.categoryId, incidentCategories)}
                                </div>
                                <div>
                                    {formatValue(incident.name)}
                                </div>
                                <div>
                                    {renderVesselName(incident.vesselId)}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>
        </SeaModal>
    );
};

export default ViewIncidentCategoriesReport;
