import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, functions, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { regions, haveValuesChanged, hasArrayChanged, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate } from '../../../../lib/datesAndTime';
import { PermissionLevel, PermissionRole, permissionRoles, PureUserPermissions, purifyUserPermissions } from '../../../../shared-state/Core/userPermissions';
import { sharedState } from '../../../../shared-state/shared-state';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName } from '../../../../shared-state/Core/users';
import { UserType } from '../../../../shared-state/Core/user';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { alertMessage } from '../../../../managers/AlertManager/AlertManager';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { UserPermissions } from '../../../../shared-state/Core/userPermissions';
import { UserDetails } from '../../../../shared-state/Crew/userDetails';
import { handleUploadError } from '../../../../managers/FileUploadManager/FileUploadManager';
import { defaultUserPermissionDefaults, UserPermissionDefaults } from '../../../../shared-state/Crew/userPermissionDefaults';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaMultiSelect, { OptionType } from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaUserPermissions from '../../../../components/SeaUserPermissions/SeaUserPermissions';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import _ from 'lodash';
import SeaSelectVessels from '../../../../components/SeaSelectVessels/SeaSelectVessels';

export const weeklyReportOptions = [
    {
        id: 'safetyChecks',
        name: 'Safety Checks'
    },{
        id: 'safetyEquipment',
        name: 'Safety Equipment Expiries'
    },{
        id: 'drills',
        name: 'Drills'
    },{
        id: 'scheduledMaintenance',
        name: 'Scheduled Maintenance'
    },{
        id: 'jobs',
        name: 'Jobs'
    },{
        id: 'vesselCertificates',
        name: 'Vessel Certificates / Vehicle Expiries'
    },{
        id: 'vesselDocuments',
        name: 'Vessel Documents'
    },{
        id: 'safetyMeetings',
        name: 'Health & Safety Meetings'
    },{
        id: 'crewCertificates',
        name: 'Crew Certificates'
    },{
        id: 'crewTraining',
        name: 'Crew Training'
    }
];

export const validUserPermissionDefaults: Set<PermissionRole> = new Set([
    'vesselSettings', 'engineHours', 'logbook', 'safetyEquipmentChecks', 'safetyEquipmentList',
    'hazardRegister', 'drills', 'incidentAccidentMedicalRegister', 'correctiveActions',
    'maintenanceSchedule', 'maintenanceHistory', 'survey', 'jobList', 
    'sparePartsList', 'vesselCertificates', 'vesselDocuments', 'standardOperatingProcedures', 
    'healthSafetyMeetings', 'dangerousGoodsRegister', 'equipmentManualDocuments', 
    'customForms', 'crewParticulars', 'crewCertificates', 'crewTraining', 
    'contacts', 'companyPlan', 'companyDocuments'
] as PermissionRole[]);



type UserTypeWithDetails = Omit<UserType, 'dateInducted' | 'licenseeId' | 'id'> & Omit<UserDetails, 'dateOfBirth' | 'state' | 'licenseeId' | 'id'> & {
    id?: string,
    licenseeId?: string,
    state?: string,
    nextOfKinName: string,
    nextOfKinRelationship: string,
    nextOfKinPhone: string,
    medicalDoctorName: string,
    medicalDoctorPhone: string,
    companyName: string,
    companyAddress: string,
    dateOfBirth?: string,
    dateInducted?: string,
    roleIds?: string[],
}
interface EditUserModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    userToEdit?: UserType,
    userDetailsToEdit?: UserDetails,
    level?: number,
}

const EditUserModal: React.FC<EditUserModalProps> = ({showModal, setShowModal, userToEdit, userDetailsToEdit, level}) => {
    const licenseeId = sharedState.licenseeId.use(showModal)!;
    const userId = sharedState.userId.use(showModal);
    const superAdmin = sharedState.superAdmin.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const onlineStatus = sharedState.onlineStatus.use(showModal);
    const myPermissions = sharedState.userPermissions.use(showModal);
    const userRoles = sharedState.userRoles.use(showModal);
    const userPermissionDefaults = sharedState.userPermissionDefaults.use(showModal);
    const [loading, setLoading] = useState(false);
    const [vesselIds, setVesselIds] = useState<string[]>();
    const [crewVesselIds, setCrewVesselIds] = useState<string[]>();
    const [userPermissions, setUserPermissions] = useState<PureUserPermissions>();
    const [originalUserPermissions, setOriginalUserPermissions] = useState<PureUserPermissions>();
    const [loadingPermissions, setLoadingPermissions] = useState(false); // Shows offline message
    const [emailMe, setEmailMe] = useState<string[]>();
    const [weeklyReport, setWeeklyReport] = useState<string[]>();
    const [emailMeVesselIds, setEmailMeVesselIds] = useState<string[]>(); // In userDetails
    const [weeklyReportVesselIds, setWeeklyReportVesselIds] = useState<string[]>(); // In userDetails
    const [emailVesselOptions, setEmailVesselOptions] = useState<OptionType[]>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [roleIds, setRoleIds] = useState<string[]>([]);
    const [selectedPermissionDefaults, setSelectedPermissionDefaults] = useState<string>();

    const emailMeOptions = useMemo(() => {
        const crewCertificateOptions: OptionType[] = [{ id: 'crewCertificatesMeOnly', name: `${userId === userToEdit?.id ? 'My' : 'Their'} Certificates Only`, default: myPermissions?.crewCertificates === 0 ? true : false }];
        if (myPermissions?.crewCertificates !== 0) {
            crewCertificateOptions.push({ id: 'crewCertificates', name: 'All Crew Certificates', default: true });
        }

        let options: OptionType[] = [
            {
                id: 'safetyChecks',
                name: 'Safety Checks',
            }, {
                id: 'safetyEquipment',
                name: 'Safety Equipment Expiries',
            }, {
                id: 'hazards',
                name: 'Risk Assessments',
            },{
                id: 'incidents',
                name: 'Incident / Event Reports',
            },{
                id: 'correctiveActions',
                name: 'Corrective Actions',
                options: [{
                    id: 'correctiveActionsDropdown',
                    name: 'Dropdown',
                    isDropdown: true,
                    options: [
                        {
                            id: 'correctiveActionsCreated',
                            name: 'When corrective actions are created',
                            default: false,
                        },
                        {
                            id: 'correctiveActionsUpdated',
                            name: 'When corrective actions are created or updated',
                            default: true,
                        }
                    ]
                }]
            },{
                id: 'safetyMeetings',
                name: 'Health & Safety Meetings',
            }, {
                id: 'vesselCertificates',
                name: 'Vessel Certificates / Vehicle Expiries',
            }, {
                id: 'vesselDocuments',
                name: 'Vessel Documents',
            }, {
                id: 'crewCertificatesOptions',
                name: 'Crew Certificates',
                options: [{
                    id: 'crewCertificatesDropdown',
                    name: 'Dropdown',
                    isDropdown: true,
                    options: crewCertificateOptions
                }]
            }, {
                id: 'jobsOptions',
                name: 'Jobs',
                options: [
                    {
                        id: 'jobsDropdown',
                        name: 'Job updates',
                        isDropdown: true,
                        options: [
                            { id: 'jobsCreated', name: 'When jobs are created', default: false },
                            { id: 'jobsUpdated', name: 'When jobs are created or updated', default: true },
                        ]
                    },
                    {
                        id: 'jobsCompletedDropdown',
                        name: 'Completed Job updates',
                        isDropdown: true,
                        options: [
                            { id: 'jobsCompletedNone', name: 'No completed job notifications', default: true },
                            { id: 'jobsCompletedCreated', name: 'When jobs are completed', default: false },
                            { id: 'jobsCompletedUpdated', name: 'When jobs are completed or history updated', default: false },
                        ]
                    },
                    { id: 'jobsUrgent', name: 'Urgent priority', default: true },
                    { id: 'jobsHigh', name: 'High priority', default: true },
                    { id: 'jobsMedium', name: 'Medium priority', default: true },
                    { id: 'jobsLow', name: 'Low priority', default: true },
                    { id: 'jobsShipyard', name: 'Shipyard', default: true },
                ]
            },
            {
                id: 'companyPlan',
                name: 'Company Plan',
            }, {
                id: 'companyDocuments',
                name: 'Company Documents',
            }
        ];
    
        if (!licenseeSettings?.hasIncidents) {
            options = options.filter(option => option.id !== 'incidents');
        }
        if (!licenseeSettings?.hasCorrectiveActions) {
            options = options.filter(option => option.id !== 'correctiveActions');
        }
    
        return options;
    }, [licenseeSettings?.hasCorrectiveActions, licenseeSettings?.hasIncidents, myPermissions?.crewCertificates, userId, userToEdit?.id]);

    const userRoleOptions = useMemo(() => {
        const options: OptionType[] = [];
        if (userRoles) {
            for (const [id, role] of Object.entries(userRoles.byId)) {
                if (role.state === 'active') {
                    options.push({ id: id, name: role.name });
                }
            }
        } 
        return options;
    }, [userRoles]);

    const initialValues: UserTypeWithDetails = useMemo(() => {
        if (userToEdit && userDetailsToEdit) {
            return {
                firstName: userToEdit.firstName ? ''+userToEdit.firstName : '',
                lastName: userToEdit.lastName ? ''+userToEdit.lastName : '',
                position: userToEdit.position ? ''+userToEdit.position : '',
                isStaff: userToEdit.isStaff ? true : false,
                isLoginDisabled: userToEdit.isLoginDisabled ? true : false,
                roleIds: userToEdit.roleIds ?? [],
                dateInducted: userToEdit.dateInducted ? userToEdit.dateInducted : '',
                email: userDetailsToEdit.email ? (''+userDetailsToEdit.email).trim().toLowerCase() : '',
                contactNumber: userDetailsToEdit.contactNumber ? ''+userDetailsToEdit.contactNumber : '',
                dateOfBirth: userDetailsToEdit.dateOfBirth ? userDetailsToEdit.dateOfBirth : '',
                passportNumber: userDetailsToEdit.passportNumber ? ''+userDetailsToEdit.passportNumber : '',
                address: userDetailsToEdit.address ? ''+userDetailsToEdit.address : '',
                nextOfKinName: (userDetailsToEdit.nextOfKin?.name) ? ''+userDetailsToEdit.nextOfKin.name : '', 
                nextOfKinRelationship: (userDetailsToEdit.nextOfKin?.relationship) ? ''+userDetailsToEdit.nextOfKin.relationship : '',
                nextOfKinPhone: (userDetailsToEdit.nextOfKin?.phone) ? ''+userDetailsToEdit.nextOfKin.phone : '',
                bankAccountNumber: userDetailsToEdit.bankAccountNumber ? ''+userDetailsToEdit.bankAccountNumber : '',
                irdNumber: userDetailsToEdit.irdNumber ? ''+userDetailsToEdit.irdNumber : '',
                medicalDoctorName: (userDetailsToEdit.medicalDoctor?.name) ? ''+userDetailsToEdit.medicalDoctor.name : '',
                medicalDoctorPhone: (userDetailsToEdit.medicalDoctor?.phone) ? ''+userDetailsToEdit.medicalDoctor.phone : '',
                currentMedicalIssues: userDetailsToEdit.currentMedicalIssues ? ''+userDetailsToEdit.currentMedicalIssues : '',
                currentMedication: userDetailsToEdit.currentMedication ? ''+userDetailsToEdit.currentMedication : '',
                previousInjuryOrSurgery: userDetailsToEdit.previousInjuryOrSurgery ? ''+userDetailsToEdit.previousInjuryOrSurgery : '',
                allergies: userDetailsToEdit.allergies ? ''+userDetailsToEdit.allergies : '',
                bloodType: userDetailsToEdit.bloodType ? ''+userDetailsToEdit.bloodType : '',
                companyName: userDetailsToEdit.companyName ? ''+userDetailsToEdit.companyName : '',
                companyAddress: userDetailsToEdit.companyAddress ? ''+userDetailsToEdit.companyAddress : '',
            };
        } else {
            return {
                firstName: '',
                lastName: '',
                position: '',
                isStaff: true,
                isLoginDisabled: false,
                email: '',
                contactNumber: '',
                dateOfBirth: '',
                dateInducted: '',
                passportNumber: '',
                address: '',
                nextOfKinName: '',
                nextOfKinRelationship: '',
                nextOfKinPhone: '',
                bankAccountNumber: '',
                irdNumber: '',
                medicalDoctorName: '',
                medicalDoctorPhone: '',
                currentMedicalIssues: '',
                currentMedication: '',
                previousInjuryOrSurgery: '',
                allergies: '',
                bloodType: '',
                companyName: '',
                companyAddress: '',
                roleIds: [],
            };
        };
    }, [userToEdit, userDetailsToEdit]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setVesselIds(userToEdit?.vesselIds ?? []);
        setCrewVesselIds(userToEdit?.crewVesselIds ?? []);
        setRoleIds(userToEdit?.roleIds ?? []);
        setEmailMe(userDetailsToEdit?.emailMe);
        setWeeklyReport(userDetailsToEdit?.weeklyReport);
        setEmailMeVesselIds(
            (userDetailsToEdit?.emailMeVesselIds) ? userDetailsToEdit.emailMeVesselIds : (userToEdit?.vesselIds)
        );
        setWeeklyReportVesselIds(
            (userDetailsToEdit?.weeklyReportVesselIds) ? userDetailsToEdit.weeklyReportVesselIds : (userToEdit?.vesselIds)
        );
        setEmailVesselOptions(
            userToEdit?.vesselIds?.map((vesselId: string) => ({
                id: vesselId,
                name: vessels?.byId[vesselId]?.name || ''
            })).sort((a, b) => a.name.localeCompare(b.name))
        );
        
        // Initialize default permissions
        const defaultPermissions: UserPermissionDefaults = _.cloneDeep(defaultUserPermissionDefaults.crew);
        
        // Adjust default permissions based on user permissions
        if (superAdmin === undefined && myPermissions) {
            Object.values(permissionRoles).forEach((permission) => {
                const defaultPermission = defaultUserPermissionDefaults.crew[permission as PermissionRole];
                const userPermission = myPermissions[permission as PermissionRole];
                if (typeof defaultPermission === 'number' && typeof userPermission === 'number') {
                    defaultPermissions[permission as PermissionRole] = Math.min(defaultPermission, userPermission) as PermissionLevel;
                }
            });
        }

        // Set initial permissions state
        if (userToEdit) {
            setLoadingPermissions(true);
            
            if (onlineStatus?.isOnline) {
                getDoc(doc(firestore, 'userPermissions', ''+userToEdit.id)).then((snap) => {
                    if (!snap.exists()) {
                        setUserPermissions(purifyUserPermissions(defaultPermissions));
                        setOriginalUserPermissions(purifyUserPermissions(defaultPermissions));
                        return;
                    }
                    setUserPermissions(purifyUserPermissions(snap.data()));
                    setOriginalUserPermissions(purifyUserPermissions(snap.data()));
                }).catch((error) => {
                    reportError('Failed to access user permissions data', error.message, error, {
                        userToEdit
                    });
                }).finally(() => {
                    setLoadingPermissions(false);
                });
            }
        } else {
            // For new users, set default permissions immediately
            setUserPermissions(purifyUserPermissions(defaultPermissions));
            setOriginalUserPermissions(purifyUserPermissions(defaultPermissions));
            setLoadingPermissions(false);
        }
    };

    const statesErrors = useMemo(() => {
        // If vessels array is empty - update error state
        if (vesselIds === undefined || (vesselIds && vesselIds.length === 0)) {
            return true;
        }

        // Check we aren't giving any permissions greater than our own
        if (superAdmin === undefined && userPermissions && myPermissions) {
            for (let i = 0; i < permissionRoles.length; i++) {
                const permissionRole = permissionRoles[i];
                if (myPermissions[permissionRole] < userPermissions[permissionRole]) {
                    return `You cannot give that permission level for ${permissionRole} to this user. You must have a permission yourself first to be able to grant it to another.`;
                }
            }
        }
        // Check that we have chosen vessels to receive email notifications/weekly report for
        if (emailMe && emailMe.length > 0 && (emailMeVesselIds === undefined || emailMeVesselIds.length === 0)) {
            return 'You have chosen to receive email notifications but no vessels to receive them for! Please select at least one vessel to reveive email notifications for.';
        }
        if (weeklyReport && weeklyReport.length > 0 && (weeklyReportVesselIds === undefined || weeklyReportVesselIds.length === 0)) {
            return 'You have chosen to receive weekly reports but no vessels to receive them for! Please select at least one vessel to reveive weekly reports for.';
        }
        return false;
    }, [emailMe, emailMeVesselIds, myPermissions, superAdmin, userPermissions, vesselIds, weeklyReport, weeklyReportVesselIds]);

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isSubmitting, isValid} = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            firstName: Yup.string().max(500).required(),
            lastName: Yup.string().max(500).required(),
            email: Yup.string().min(3).max(200).email().required(),
            position: Yup.string().max(200),
            isStaff: Yup.boolean(),
            isLoginDisabled: Yup.boolean(),
            contactNumber: Yup.string().max(200),
            dateOfBirth: Yup.date().max(formatSeaDate()),
            dateInducted: Yup.date().max(formatSeaDate()).min(...notTooOld),
            passportNumber: Yup.string().max(200),
            address: Yup.string().max(500),
            nextOfKinName: Yup.string().max(500),
            nextOfKinRelationship: Yup.string().max(500),
            nextOfKinPhone: Yup.string().max(200),
            bankAccountNumber: Yup.string().max(32),
            irdNumber: Yup.string().max(32),
            medicalDoctorName: Yup.string().max(500),
            medicalDoctorPhone: Yup.string().max(32),
            currentMedicalIssues: Yup.string().max(5000),
            currentMedication: Yup.string().max(5000),
            previousInjuryOrSurgery: Yup.string().max(5000),
            allergies: Yup.string().max(5000),
            bloodType: Yup.string().max(500),
            companyName: Yup.string().max(500),
            companyAddress: Yup.string().max(500),
        }), onSubmit: (data: UserTypeWithDetails) => {
            setHasSubmitted(true);

            // If vessels array is empty - update error state
            if (preventMultiTap('editUser') || vesselIds === undefined || (vesselIds && vesselIds.length === 0)) {
                return;
            };

            if (statesErrors && statesErrors !== true) {
                alertMessage(statesErrors);
                return;
            }

            // // Check we aren't giving any permissions greater than our own
            // if (superAdmin === undefined && userPermissions && myPermissions) {
            //     for (let i = 0; i < permissionsList.length; i++) {
            //         const permission = permissionsList[i];
            //         if (myPermissions[permission] < userPermissions[permission]) {
            //             //alertMessage(`You do not have permission to give this user that level of ${permissions[permission].name}`);
            //             alertMessage(`You cannot give that permission level for ${permissions[permission].name} to this user. You must have a permission yourself first to be able to grant it to another.`);
            //             return;
            //         }
            //     }
            // }

            // // Check that we have chosen vessels to receive email notifications/weekly report for
            // if (
            //     emailMe &&
            //     emailMe.length > 0 &&
            //     (emailMeVesselIds === undefined || emailMeVesselIds.length === 0)
            // ) {
            //     alertMessage(`You have chosen to receive email notifications but no vessels to receive them for! Please select at least one vessel to reveive email notifications for.`);
            //     return;
            // }

            // if (
            //     weeklyReport &&
            //     weeklyReport.length > 0 &&
            //     (weeklyReportVesselIds === undefined || weeklyReportVesselIds.length === 0)
            // ) {
            //     alertMessage(`You have chosen to receive weekly reports but no vessels to receive them for! Please select at least one vessel to reveive weekly reports for.`);
            //     return;
            // }

            setLoading(true);
            Promise.resolve().then(() => { // Checking for duplicate email address

                if (userToEdit && superAdmin === undefined) { // Currently can't edit existing user's email (unless superAdmin)
                    return Promise.resolve();
                }
                if (userToEdit && userDetailsToEdit?.email === data.email) { // Email hasn't changed
                    return Promise.resolve();
                }
                if (!onlineStatus?.isOnline) {
                    return Promise.reject({code: 'offline'});
                }

                return httpsCallable(functions, 'isEmailAvailable') ({
                    email: (data.email ?? '').trim().toLowerCase()
                }).then((result: any) => {
                    console.log('httpsCallable result', result);
                    if (result.data.result === 'duplicateEmail') {
                        return Promise.reject({code: 'duplicateEmail'})
                    }

                    if (userToEdit) {
                        // Changing email
                        return confirmAction(
                            `Are you sure you want to change email to ${data.email.trim().toLowerCase()}? ${userToEdit.id === userId ? 'You' : userToEdit.firstName} will need to respond to a new welcome email to access ${userToEdit.id === userId ? 'your' : 'their'} account again.`,
                            'Yes, change email',
                            'Cancel'
                        ).catch(() => {
                            throw new Error('changeEmailCancelled');
                        }).then(() => {
                            return httpsCallable(functions, 'changeEmail') ({
                                userId: userToEdit.id,
                                newEmail: data.email.trim().toLowerCase(),
                                updatedBy: userId
                            }).then(() => {
                                showToast(`Sending a welcome email to ${data.email}...`);
                            });
                        });
                    }

                    return Promise.resolve();
                });

            }).then(() => {
                // Process form
                const batch = splittableBatch(firestore, 20 - 1); // -2 for userPermissions needing 2 extra security calls
                const batchTrace = makeBatchTrace(batch, 'users');

                if (userToEdit) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = userToEdit.id;
                    batch.set(
                        doc(firestore, 'users', ''+userToEdit.id),
                        {
                            vesselIds: vesselIds,
                            crewVesselIds: crewVesselIds,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            isStaff: data.isStaff ? true : false,
                            isLoginDisabled: data.isLoginDisabled ? true : false,
                            position: data.position ? data.position : deleteValue,
                            roleIds: roleIds?.length ? roleIds : deleteValue,
                            dateInducted: data.dateInducted ? data.dateInducted : deleteValue,
                            whenUpdated: batchTrace.whenAction,
                            updatedBy: userId,
                        },
                        { merge: true }
                    );

                    batch.set(
                        doc(firestore, 'userDetails', ''+userToEdit.id),
                        {
                            contactNumber: data.contactNumber ? data.contactNumber : deleteValue,
                            dateOfBirth: data.dateOfBirth ? data.dateOfBirth : deleteValue,
                            passportNumber: data.passportNumber ? data.passportNumber : deleteValue,
                            address: data.address ? data.address : deleteValue,
                            nextOfKin: {
                                name: data.nextOfKinName ? data.nextOfKinName : deleteValue,
                                relationship: data.nextOfKinRelationship ? data.nextOfKinRelationship : deleteValue,
                                phone: data.nextOfKinPhone ? data.nextOfKinPhone : deleteValue
                            },
                            bankAccountNumber: data.bankAccountNumber ? data.bankAccountNumber : deleteValue,
                            irdNumber: data.irdNumber ? data.irdNumber : deleteValue,
                            medicalDoctor: {
                                name: data.medicalDoctorName ? data.medicalDoctorName : deleteValue,
                                phone: data.medicalDoctorPhone ? data.medicalDoctorPhone : deleteValue
                            },
                            currentMedicalIssues: data.currentMedicalIssues ? data.currentMedicalIssues : deleteValue,
                            currentMedication: data.currentMedication ? data.currentMedication : deleteValue,
                            previousInjuryOrSurgery: data.previousInjuryOrSurgery ? data.previousInjuryOrSurgery : deleteValue,
                            allergies: data.allergies ? data.allergies : deleteValue,
                            bloodType: data.bloodType ? data.bloodType : deleteValue,
                            companyName: data.companyName ? data.companyName : deleteValue,
                            companyAddress: data.companyAddress ? data.companyAddress : deleteValue,
                            emailMe: (!data.isLoginDisabled && emailMe && emailMe.length > 0) ? emailMe : deleteValue,
                            emailMeVesselIds: emailMeVesselIds ? emailMeVesselIds : [],
                            weeklyReport: (!data.isLoginDisabled && weeklyReport && weeklyReport.length > 0) ? weeklyReport : deleteValue,
                            weeklyReportVesselIds: weeklyReportVesselIds ? weeklyReportVesselIds : [],
                            touched: serverTimestamp()
                        } as Partial<UserDetails>,
                        { merge: true }
                    );

                    if (!(userToEdit.isLicensee || userToEdit.id === userId) || superAdmin !== undefined) {
                        // Update User Permissions doc
                        if (
                            userPermissions &&
                            haveValuesChanged(userPermissions, originalUserPermissions)
                        ) { // If false then they are offline or failed to load
                            batch.set(
                                doc(firestore, 'userPermissions', userToEdit.id as string),
                                {
                                    whenUpdated: batchTrace.whenAction,
                                    updatedBy: userId,
                                    ...userPermissions
                                },
                                { merge: true}
                            );

                            onCollectionUpdated(batch, 'userPermissions');
                            logAction(
                                batch,
                                'Update',
                                'userPermissions',
                                userToEdit.id as string,
                                renderFullName(userToEdit),
                                undefined,
                                [userToEdit.id as string]
                            );
                        }
                    }

                    logAction(
                        batch,
                        'Update',
                        'users',
                        userToEdit.id as string,
                        renderFullName(userToEdit),
                        undefined,
                        [userToEdit.id as string]
                    );
                } else {
                    const newUserRef = doc(collection(firestore, 'users'));
                    const newUserDetailsRef = doc(firestore, 'userDetails', newUserRef.id);

                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newUserRef.id;

                    batch.set(newUserRef, {
                        state: 'active',
                        licenseeId: licenseeId,
                        vesselIds: vesselIds,
                        crewVesselIds: crewVesselIds,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        position: data.position ? data.position : undefined,
                        roleIds: roleIds,
                        isStaff: data.isStaff ? true : false,
                        isLoginDisabled: data.isLoginDisabled ? true : undefined,
                        dateInducted: data.dateInducted ? data.dateInducted : undefined,
                        whenAdded: batchTrace.whenAction,
                        addedBy: userId,
                    });

                    batch.set(newUserDetailsRef, {
                        state: 'active',
                        licenseeId: licenseeId,
                        email: data.email?.trim().toLowerCase(),
                        contactNumber: data.contactNumber ? data.contactNumber : undefined,
                        dateOfBirth: data.dateOfBirth ? data.dateOfBirth : undefined,
                        passportNumber: data.passportNumber ? data.passportNumber : undefined,
                        address: data.address ? data.address : undefined,
                        nextOfKin: {    
                            name: data.nextOfKinName ? data.nextOfKinName : undefined,
                            relationship: data.nextOfKinRelationship ? data.nextOfKinRelationship : undefined,
                            phone: data.nextOfKinPhone ? data.nextOfKinPhone : undefined
                        },
                        bankAccountNumber: data.bankAccountNumber ? data.bankAccountNumber : undefined,
                        irdNumber: data.irdNumber ? data.irdNumber : undefined,
                        medicalDoctor: {
                            name: data.medicalDoctorName ? data.medicalDoctorName : undefined,
                            phone: data.medicalDoctorPhone ? data.medicalDoctorPhone : undefined
                        },
                        currentMedicalIssues: data.currentMedicalIssues ? data.currentMedicalIssues : undefined,
                        currentMedication: data.currentMedication ? data.currentMedication : undefined,
                        previousInjuryOrSurgery: data.previousInjuryOrSurgery ? data.previousInjuryOrSurgery : undefined,
                        allergies: data.allergies ? data.allergies : undefined,
                        bloodType: data.bloodType ? data.bloodType : undefined,
                        companyName: data.companyName ? data.companyName : undefined,
                        companyAddress: data.companyAddress ? data.companyAddress : undefined,
                        emailMe: (!data.isLoginDisabled && emailMe && emailMe.length > 0) ? emailMe : undefined,
                        emailMeVesselIds: emailMeVesselIds ? emailMeVesselIds : [],
                        weeklyReport: (!data.isLoginDisabled && weeklyReport && weeklyReport.length > 0) ? weeklyReport : undefined,
                        weeklyReportVesselIds: weeklyReportVesselIds ? weeklyReportVesselIds : [],
                        touched: serverTimestamp()
                    } as Omit<UserDetails, 'id'>);

                    if (!data.isLoginDisabled && data.isStaff) {
                        batch.set(
                            doc(collection(firestore, 'welcomeEmails')),
                            {
                                emailedTo: newUserRef.id,
                                sentBy: userId,
                                whenAdded: batchTrace.whenAction,
                                isExpired: false
                                //origin: getServerUrl()
                            }
                        );
                        showToast(`User created and welcome email sent to ${data.email}`);
                    }
                    // Create User Permissions doc
                    batch.set(
                        doc(firestore, 'userPermissions', newUserRef.id),
                        {
                            whenUpdated: batchTrace.whenAction,
                            updatedBy: userId,
                            ...userPermissions
                        }
                    );

                    onCollectionUpdated(batch, 'userPermissions');
                    logAction(
                        batch,
                        'Add',
                        'users',
                        newUserRef.id,
                        `${data.firstName} ${data.lastName}`,
                        undefined,
                        [newUserRef.id]
                    );
                }

                onCollectionUpdated(batch, 'users');
                onCollectionUpdated(batch, 'userDetails');
                batchTrace.data = {
                    data,
                    vesselIds,
                    weeklyReport,
                    weeklyReportVesselIds,
                    emailMe,
                    emailMeVesselIds,
                    userToEdit
                };
                batchTrace.save(`${userToEdit ? `Update account for ${renderFullName(data)}` : `Add account and send welcome email for ${renderFullName(data)}`}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error?.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    if (error?.message === 'changeEmailCancelled') {
                        // Ignore
                    } else if (error?.code === 'offline') {
                        alertMessage('You must be online to create a new user.');
                    } else if (error?.code === 'duplicateEmail') {
                        alertMessage('Email address is already in use by an existing Sea Flux user.<br/><br/>Please try a different email address.')
                        console.log('email already in use')
                    } else {    
                        reportError(`Failed to update user`, error?.message, error, {
                            userToEdit,
                            data
                        });
                    }
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    });

    const crewVesselOptions = useMemo(() => {
        return vessels?.all?.map((vessel) => ({id: vessel.id, name: vessel.name}))
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(x => (vesselIds ?? []).includes(x.id));
    }, [vessels, vesselIds]);

    const onVesselIdsChanged = (vesselIds: string[]) => {
        if (crewVesselIds?.length === 0) {
            // Set crewed vessels only if it hasn't been set yet
            setCrewVesselIds(vesselIds);
        }
        setEmailVesselOptions((previousOptions) => {
            // Work out which vesselIds have been added or removed
            const addedVesselIds = [] as string[];
            const removedVesselIds = [] as string[];
            vesselIds?.forEach((vesselId: string) => {
                let isFound = false;
                previousOptions?.forEach((option) => {
                    if (option.id === vesselId) {
                        isFound = true;
                    }
                });
                if (!isFound) {
                    addedVesselIds.push(vesselId);
                }
            });
            previousOptions?.forEach((option) => {
                let isFound = false;
                vesselIds?.forEach((vesselId: string) => {
                    if (option.id === vesselId) {
                        isFound = true;
                    }
                });
                if (!isFound) {
                    removedVesselIds.push(option.id);
                }
            });

            setEmailMeVesselIds((previousIds) => {
                const newIds = previousIds ? [...previousIds] : [];
                removedVesselIds.forEach((removedVesselId) => {
                    if (newIds.indexOf(removedVesselId) >= 0) {
                        newIds.splice(newIds.indexOf(removedVesselId), 1);
                    }
                });
                addedVesselIds.forEach((addedVesselId) => {
                    if (newIds.indexOf(addedVesselId) === -1) {
                        newIds.push(addedVesselId);
                    }
                });
                return newIds;
            });
            setWeeklyReportVesselIds((previousIds) => {
                const newIds = previousIds ? [...previousIds] : [];
                removedVesselIds.forEach((removedVesselId) => {
                    if (newIds.indexOf(removedVesselId) >= 0) {
                        newIds.splice(newIds.indexOf(removedVesselId), 1);
                    }
                });
                addedVesselIds.forEach((addedVesselId) => {
                    if (newIds.indexOf(addedVesselId) === -1) {
                        newIds.push(addedVesselId);
                    }
                });
                return newIds;
            });

            if (vesselIds && vesselIds.length > 0) {
                return vesselIds?.map((vesselId: string) => {
                    return {
                        id: vesselId,
                        name: vessels?.byId[vesselId]?.name || ''
                    };
                });
            }
            return [];
        });
    };

    const onCrewVesselIdsChanged = (vesselIds: string[]) => {
    };

    const localisedEmailMeOptions = useMemo(() => {
        const options = [] as OptionType[];
        emailMeOptions.forEach((option) => {
            if (option.id === 'companyPlan') {
                options.push({
                    ...option,
                    name: (licenseeSettings && regions[licenseeSettings?.region]?.companyPlanShort) ?? option.name
                });
            } else if ((option.id === 'incidents' && !licenseeSettings?.hasIncidents) 
                // ||
                // (option.id === 'correctiveActions' && !canView(option.id))
            ) {
                return;
            } else {
                options.push(option);
            }
        });
        return options;
    }, [emailMeOptions, licenseeSettings]);

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues)
            || hasArrayChanged(vesselIds, userToEdit?.vesselIds)
            || hasArrayChanged(emailMe, userDetailsToEdit?.emailMe)
            || hasArrayChanged(weeklyReport, userDetailsToEdit?.weeklyReport)
            || hasArrayChanged(roleIds, userToEdit?.roleIds);
    }, [values, initialValues, vesselIds, userToEdit?.vesselIds, userToEdit?.roleIds, emailMe, userDetailsToEdit?.emailMe, userDetailsToEdit?.weeklyReport, weeklyReport, roleIds]);

    const isMe = (userToEdit && userToEdit.id === userId);

	useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const localUserPermissionDefaults = useMemo(() => {
        const hasPermissions = userPermissionDefaults?.byId && Object.keys(userPermissionDefaults.byId).length > 0;
        const _localUserPermissionDefaults = hasPermissions ? userPermissionDefaults.byId : defaultUserPermissionDefaults;
        setSelectedPermissionDefaults(Object.keys(_localUserPermissionDefaults)[0]);
        return _localUserPermissionDefaults;
    }, [userPermissionDefaults?.byId]);

    const handleSelectedPermissionDefaultsChange = useCallback((event: CustomEvent) => {
        const permissionDefaults = event.detail.value as string;
        if (!localUserPermissionDefaults[permissionDefaults as keyof typeof localUserPermissionDefaults]) {
            return
        }
        setSelectedPermissionDefaults(permissionDefaults);
        if (permissionDefaults !== 'custom') {
            let selectedDefaultPermissionSet = localUserPermissionDefaults[permissionDefaults as keyof typeof localUserPermissionDefaults];

            const newUserPermissions = Object.fromEntries(
                Object.entries(selectedDefaultPermissionSet).filter(([key]) => validUserPermissionDefaults.has(key as PermissionRole))
            ) as UserPermissions;

            setUserPermissions(purifyUserPermissions(newUserPermissions));
        }
    }, [localUserPermissionDefaults]);


    useEffect(() => {
        const checkCustomPermissionDefaults = () => {
            if (!userPermissions) return;
            let foundPermissionDefault = 'custom';
    
            for (const permissionDefaultsKey of Object.keys(localUserPermissionDefaults)) {
                const permissionDefaults = localUserPermissionDefaults[permissionDefaultsKey as keyof typeof localUserPermissionDefaults];
                const isMatch = permissionRoles.every((permissionRole) => {
                    if (validUserPermissionDefaults.has(permissionRole as PermissionRole)) {
                        return userPermissions[permissionRole as PermissionRole] === permissionDefaults[permissionRole as PermissionRole];
                    }
                    return true;
                });
                if (isMatch) {
                    foundPermissionDefault = permissionDefaultsKey;
                    break;
                }
            }
    
            if (selectedPermissionDefaults !== foundPermissionDefault) {
                setSelectedPermissionDefaults(foundPermissionDefault);
            }
        };
    
        checkCustomPermissionDefaults();
    }, [localUserPermissionDefaults, selectedPermissionDefaults, userPermissions]);


    // check if data is higher than user permissions - removed in favour of showing an error upon submission
    // useEffect(() => {
    //     if (!data || !userPermissions) return;
    
    //     let hasChanges = false;
    //     const adjustedData = { ...data };

    //     Object.keys(data).forEach((permissionRole: string) => {
    //         if (validRoles.has(permissionRole as PermissionRole)) {
    //             const currentUserMaxPermission = userPermissions[permissionRole as PermissionRole] || permissionLevels.NONE;
    //             if (data[permissionRole as PermissionRole] > currentUserMaxPermission) {
    //                 adjustedData[permissionRole as PermissionRole] = currentUserMaxPermission;
    //                 hasChanges = true;
    //             }
    //         }
    //     });
    
    //     if (hasChanges && setData) {
    //         setData(adjustedData);
    //     }
    // }, [data, userPermissions, setData]);

    return (
        <SeaModal
            title={userToEdit ? `Edit Profile - ${renderFullName(userToEdit)}` : 'Create New User'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                            <SeaInput
                                label="First name"
                                name="firstName"
                                value={values.firstName}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.firstName ? errors.firstName : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                label="Last name"
                                name="lastName"
                                value={values.lastName}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.lastName ? errors.lastName : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                disabled={(userToEdit && superAdmin === undefined) ? true : false}
                                label="Email"
                                name="email"
                                value={values.email}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="email"
                                inputmode="email"
                                error={touched.email ? errors.email : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                disabled={userId === userToEdit?.id || userToEdit?.id === licenseeId}
                                label="User Type"
                                name="isStaff"
                                zone="white"
                                value={values.isStaff}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.isStaff ? errors.isStaff : ''}
                            >
                                <IonSelectOption value={true}>Crew/Staff User</IonSelectOption>
                                <IonSelectOption value={false}>Non-Staff User</IonSelectOption>
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaCheckbox
                                disabled={(userId === userToEdit?.id || userToEdit?.id === licenseeId) && superAdmin === undefined}
                                name="isLoginDisabled"
                                label=" "
                                checked={values.isLoginDisabled}
                                setFieldValue={setFieldValue}
                                error={touched.isLoginDisabled ? errors.isLoginDisabled : ''}
                            >
                                Disable Login Access
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="6">
                            {/* {userToEdit &&
                                <div className="sea-label note">
                                    <div><SeaIcon icon="info"/>
                                    To change a password please visit the <a href="https://seaflux.netlify.app/forgot-password" target="_blank" rel="noopener noreferrer">forgot password page</a></div>
                                </div>
                            } */}
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                label="Job Title / Position"
                                name="position"
                                value={values.position}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.position ? errors.position : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaMultiSelect
                                label="Roles / Departments"
                                name="roleIds"
                                values={roleIds}
                                setValues={(roleIds) => {
                                    setRoleIds(roleIds)
                                }}
                                options={userRoleOptions}
                                emptyText='Not Set'
                                help={{text: 'A user can have multiple Roles/Departments which can be associated with the crew certificates section to help identify the missing crew certs. \n \n Add/edit "Roles/Departments" from the settings cog on the crew particulars page'}}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                label="Contact #"
                                name="contactNumber"
                                value={values.contactNumber}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.contactNumber ? errors.contactNumber : ''}
                            />
                        </IonCol>

                        {!values.isStaff && 
                            <IonCol size="6">
                                <SeaInput
                                    label="Company name"
                                    name="companyName"
                                    value={values.companyName}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    type="text"
                                    inputmode="text"
                                    error={touched.companyName ? errors.companyName : ''}
                                />
                            </IonCol>
                        }

                        {values.isStaff && <>
                            <IonCol size="6">
                                <SeaDate
                                    label="Date of Birth"
                                    name="dateOfBirth"
                                    value={values.dateOfBirth}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    error={touched.dateOfBirth ? errors.dateOfBirth : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaDate
                                    label="Inducted Date"
                                    name="dateInducted"
                                    value={values.dateInducted}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    error={touched.dateInducted ? errors.dateInducted : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaInput
                                    label="Passport #"
                                    name="passportNumber"
                                    value={values.passportNumber}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    type="text"
                                    inputmode="text"
                                    error={touched.passportNumber ? errors.passportNumber : ''}
                                />
                            </IonCol>    
                        </>}
                        <IonCol size="6">
                            <SeaInput
                                label="Address"
                                name="address"
                                value={values.address}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.address ? errors.address : ''}
                            />
                        </IonCol>

                        {values.isStaff && <>
                            <IonCol size="6">
                                <SeaInput
                                    label="Next of kin name"
                                    name="nextOfKinName"
                                    value={values.nextOfKinName}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    type="text"
                                    inputmode="text"
                                    error={touched.nextOfKinName ? errors.nextOfKinName : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaInput
                                    label="Next of kin relationship"
                                    name="nextOfKinRelationship"
                                    value={values.nextOfKinRelationship}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    type="text"
                                    inputmode="text"
                                    error={touched.nextOfKinRelationship ? errors.nextOfKinRelationship : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaInput
                                    label="Next of kin contact #"
                                    name="nextOfKinPhone"
                                    value={values.nextOfKinPhone}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    type="text"
                                    inputmode="text"
                                    error={touched.nextOfKinPhone ? errors.nextOfKinPhone : ''}
                                />
                            </IonCol>
                             {/* Removed as we don't want to store this information on the system */}
                            {/* <IonCol size="6">
                                <SeaInput
                                    label="Bank Account #"
                                    name="bankAccountNumber"
                                    value={values.bankAccountNumber}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    type="text"
                                    inputmode="text"
                                    error={touched.bankAccountNumber ? errors.bankAccountNumber : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaInput
                                    label={licenseeSettings ? regions[licenseeSettings.region]?.taxReg : ''}
                                    name="irdNumber"
                                    value={values.irdNumber}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    type="text"
                                    inputmode="text"
                                    error={touched.irdNumber ? errors.irdNumber : ''}
                                />
                            </IonCol> */}
                            <IonCol size="12"></IonCol>
                            <div className="line-2"></div>
                            <IonCol size="12"></IonCol>
                            <IonCol size="6">
                                <SeaInput
                                    label="Medical Doctor"
                                    name="medicalDoctorName"
                                    value={values.medicalDoctorName}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    type="text"
                                    inputmode="text"
                                    error={touched.medicalDoctorName ? errors.medicalDoctorName : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaInput
                                    label="Doctor Contact #"
                                    name="medicalDoctorPhone"
                                    value={values.medicalDoctorPhone}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    type="text"
                                    inputmode="text"
                                    error={touched.medicalDoctorPhone ? errors.medicalDoctorPhone : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaTextarea
                                    label="Current Medical Issues"
                                    name="currentMedicalIssues"
                                    value={values.currentMedicalIssues}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    height={100}
                                    inputmode="text"
                                    error={touched.currentMedicalIssues ? errors.currentMedicalIssues : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaTextarea
                                    label="Current Medication"
                                    name="currentMedication"
                                    value={values.currentMedication}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    height={100}
                                    inputmode="text"
                                    error={touched.currentMedication ? errors.currentMedication : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaTextarea
                                    label="Previous Injury or Surgery"
                                    name="previousInjuryOrSurgery"
                                    value={values.previousInjuryOrSurgery}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    height={100}
                                    inputmode="text"
                                    error={touched.previousInjuryOrSurgery ? errors.previousInjuryOrSurgery : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaTextarea
                                    label="Allergies"
                                    name="allergies"
                                    value={values.allergies}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    height={100}
                                    inputmode="text"
                                    error={touched.allergies ? errors.allergies : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaInput
                                    label="Blood type"
                                    name="bloodType"
                                    value={values.bloodType}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    type="text"
                                    inputmode="text"
                                    error={touched.bloodType ? errors.bloodType : ''}
                                />
                            </IonCol>
                        </>}

                        {values.isLoginDisabled && values.isStaff === false ? '' : // If Non-Staff and no login access
                            <>
                                {(
                                    ((userToEdit?.id !== licenseeId) && (userToEdit?.id !== userId))
                                    ||
                                    superAdmin !== undefined
                                ) && // Can't edit own user and licensee user (unless superAdmin)
                                    <>
                                        {/* Vessel Access*/}
                                        <IonCol size="12">
                                            <SeaSelectVessels
                                                label="Select Vessels/Assets the user has Access/Visibility To"
                                                vesselIds={vesselIds}
                                                setVesselIds={setVesselIds}
                                                required={true}
                                                requiredError="At least one vessel is required"
                                                isSubmitting={isSubmitting}
                                                onChanged={onVesselIdsChanged}
                                                emptyText="Not Set"
                                                disabled={userToEdit?.isLicensee}
                                            />
                                        </IonCol>

                                        {/* Vessel Crew*/}
                                        <IonCol size="12">
                                            <SeaMultiSelect
                                                label="Select Vessels/Assets User is Operational Crew For"
                                                values={crewVesselIds}
                                                setValues={setCrewVesselIds}
                                                options={ crewVesselOptions}
                                                useAllOption={true}
                                                required={true}
                                                requiredError="At least one vessel is required"
                                                isSubmitting={isSubmitting}
                                                onChanged={onCrewVesselIdsChanged}
                                                emptyText="No Vessels"
                                                help={{text: "Use this to define whether a user is operational crew on a given vessel/asset. \n\nOperational crew will be displayed in sections such as Vessel Drills, Crew Certificates, and Crew Training. \n\nNote - A User must have access to the vessel/asset in order to be defined as operational crew for that vessel/asset."}}
                                            />
                                        </IonCol>

                                        {/* User Permissions*/}
                                        {!values.isLoginDisabled && // no login access
                                            <>
                                                <IonCol size="12"></IonCol>
                                                    <div className="line-2"></div>
                                                <IonCol size="12"></IonCol>
                                            
                                                {(loadingPermissions || !onlineStatus?.isOnline) ? (
                                                        <IonCol size="12">
                                                        <div className="sea-label">User Permissions</div>
                                                        <div className="sea-no-data columns">
                                                            <div><SeaIcon icon="offline" /></div>
                                                            <div>Can't access user permissions while offline</div>
                                                        </div>
                                                    </IonCol>
                                                ) : (
                                                    <IonRow>
                                                        <IonCol size="6">
                                                            <SeaSelect
                                                                label="Apply Default Permissions"
                                                                name="permissionDefaults"
                                                                value={selectedPermissionDefaults}
                                                                onchange={handleSelectedPermissionDefaultsChange}
                                                                help={{ text: 'Use this to quickly apply default permissions. \n \n Add/edit default permissions from the settings cog in the crew particulars page.  \n \n Note: This is not to be confused with user roles.' }}
                                                            >
                                                                {Object.keys(localUserPermissionDefaults).map((key) => {
                                                                    return <IonSelectOption key={key} value={key}>{localUserPermissionDefaults[key as keyof typeof localUserPermissionDefaults].name}</IonSelectOption>;
                                                                })}
                                                                {selectedPermissionDefaults === 'custom' ? <IonSelectOption value="custom">Custom</IonSelectOption> : null}
                                                            </SeaSelect>
                                                        </IonCol>
                                                        <IonCol size="12">
                                                            <SeaUserPermissions
                                                                data={userPermissions}
                                                                setData={setUserPermissions}
                                                                hasSubmitted={hasSubmitted}
                                                            />
                                                        </IonCol>
                                                    </IonRow>
                                                )}
                                            </>
                                        }
                                        <IonCol size="12">
                                            <div className="line-2"></div>
                                        </IonCol>
                                    </>
                                }
                                {vesselIds && vesselIds.length > 0 &&
                                    <>
                                        <IonCol size="6">
                                            <SeaMultiSelect
                                                label="Select Email Notifications"
                                                help={{text: `Select which categories ${isMe ? 'you need' : 'the user needs'} to be notified for whenever something significant happens.\n\nNote: Categories that ${isMe ? 'you' : 'they'} don't have permission for will not be shown in any reports.`}}
                                                name="emailMe"
                                                modalTitle="Select Email Notifications"
                                                values={emailMe}
                                                setValues={setEmailMe}
                                                options={localisedEmailMeOptions}
                                                useAllOption={true}
                                                emptyText="Not Set"
                                                modalMaxWidth="400px"
                                            />
                                        </IonCol>
                                        <IonCol size="6">
                                            <SeaMultiSelect
                                                label="Vessels / Facilities For Email Notifications"
                                                values={emailMeVesselIds}
                                                setValues={setEmailMeVesselIds}
                                                options={emailVesselOptions}
                                                useAllOption={true}
                                                //required={true}
                                                isSubmitting={isSubmitting}
                                                emptyText="Not Set"
                                            />
                                        </IonCol>
                                        <IonCol size="12">
                                            <div className="line-2"></div>
                                        </IonCol>
                                        <IonCol size="6">
                                            <SeaMultiSelect
                                                name="weeklyReport"
                                                label="Select Weekly Report Categories"
                                                help={{
                                                    text: `Select which categories ${isMe ? 'you want to' : 'to'} include in a report emailed every week. Selecting nothing will mean that no reports will be sent.\n\nNote: ${isMe ? 'You' : 'They'} will receive one email per week for each vessel ${isMe ? 'you' : 'they'} have access to. Categories that ${isMe ? 'you' : 'they'} don't have permission for will not be shown in any reports.`
                                                }}
                                                modalTitle="Select Weekly Report Categories"
                                                values={weeklyReport}
                                                setValues={setWeeklyReport}
                                                options={weeklyReportOptions}
                                                useAllOption={true}
                                                emptyText="Not Set"
                                            />
                                        </IonCol>
                                        <IonCol size="6">
                                            <SeaMultiSelect
                                                label="Vessels / Facilities For Weekly Reports"
                                                values={weeklyReportVesselIds}
                                                setValues={setWeeklyReportVesselIds}
                                                options={emailVesselOptions}
                                                useAllOption={true}
                                                isSubmitting={isSubmitting}
                                                emptyText="Not Set"
                                            />
                                        </IonCol>
                                    </>
                                }
                            </>
                        }
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid && !statesErrors}
                />
                <div className="view-modal-buttons">
                    <SeaButton
                        zone="white"
                        type="submit"
                        size="wide"
                        disabled={loading}
                    >
                        {userToEdit ? 'Save Profile Edits' : 'Create New User'}
                    </SeaButton>
                    {(!userToEdit && !values.isLoginDisabled) &&
                        <div className="sea-no-data columns" style={{marginTop:10, marginLeft: 20}}>
                            <div><SeaIcon icon="info"/></div>
                            <div>On submit a welcome email with password activation will be sent to your new user.</div>
                        </div>
                    }
                </div>
            </form>
        </SeaModal>
    );
};

export default EditUserModal;
