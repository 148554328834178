import React, { useState } from 'react';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { formatValue, formatTextAreaText, formatRiskRating } from '../../../../lib/util';
import { canDelete, canAccessAllVessels, permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { WriteBatch } from "firebase/firestore";
import { SplittableBatch } from '../../../../lib/firebase';
import { sharedState } from '../../../../shared-state/shared-state';
import { Hazard } from '../../../../shared-state/HealthSafety/hazardRegistry_deprecated';
import { renderVesselsList } from '../../../../shared-state/Core/vessels';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditHazard from '../EditHazardDeprecated/EditHazardDeprecated';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';

interface ViewHazardProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: Hazard,
}

const ViewHazard: React.FC<ViewHazardProps> = ({showModal, setShowModal, selectedItem}) => {
    const vessels = sharedState.vessels.use(showModal);
    const divisions = sharedState.divisions.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    
    return (
        <SeaModal
            title={`Hazard - ${selectedItem?.name}`}
            showModal={showModal}
            setShowModal={setShowModal}
            size='wide'
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="Vessels / Facilities" w="100">
                    {renderVesselsList(selectedItem?.vesselIds, vessels, divisions)}
                </SeaGridCell>
                <SeaGridCell label="Risk" w="100">
                    {formatValue(formatTextAreaText(selectedItem?.riskNotes))}
                </SeaGridCell>
                <SeaGridCell label="Key Action" w="50">
                    {formatValue(selectedItem?.action)}
                </SeaGridCell>
                <SeaGridCell label="Risk Rating" w="50">
                    {formatValue(formatRiskRating(selectedItem?.riskRating))}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Actions Required" w="100">
                    {formatValue(formatTextAreaText(selectedItem?.actionsRequired))}
                </SeaGridCell>
                <SeaGridCell label="Person Responsible" w="50">
                    {formatValue(selectedItem?.whoResponsible)}
                </SeaGridCell>
            </SeaGrid>

            <div className="view-modal-buttons">
                <RequirePermissions
                    role="hazardRegister"
                    level={permissionLevels.EDIT}
                >
                    <>
                        <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}>
                            <SeaIcon icon="edit" slot="start" />Edit Hazard
                        </SeaButton>
                        <div className="spacer-wide"></div>
                        {canDelete('hazardRegister') &&
                            selectedItem?.vesselIds &&
                            canAccessAllVessels(selectedItem.vesselIds) &&
                            <SeaLinkButton
                                mode="standard-link"
                                onClick={(e) => {
                                    deleteIfConfirmed(
                                        'hazards',
                                        selectedItem.id,
                                        (batch: WriteBatch | SplittableBatch) => {
                                            setShowModal(false);
                                        },
                                        'hazard',
                                        selectedItem?.name,
                                        selectedItem.vesselIds
                                    );
                                }}
                            >
                                Delete Hazard
                            </SeaLinkButton>
                        }
                    </>
                </RequirePermissions>
            </div>

            {showModal &&
                <EditHazard
                    showModal={showEditItemModal}
                    setShowModal={setShowEditItemModal}
                    itemToUpdate={selectedItem}
                    level={2}
                />
            }
        </SeaModal>
    );
};

export default ViewHazard;
