import React, { useState, useEffect, useMemo, Profiler } from "react";
import { logPageView } from "../../../lib/firebase";
import { usePageLimiter } from "../../../hooks/usePageLimiter";
import { formatValue, renderCamelCase } from "../../../lib/util";
import { formatDateShort, formatMonthISO, formatMonthLonger } from '../../../lib/datesAndTime';
import { IonSelectOption } from "@ionic/react";
import { onProfilerRender } from "../../../lib/performance";
import { permissionLevels } from "../../../shared-state/Core/userPermissions";
import { renderCategoryName } from "../../../lib/categories";
import { renderFullNameForUserId } from "../../../shared-state/Core/users";
import { sharedState } from '../../../shared-state/shared-state';
import { MaintenanceTaskCompleted } from "../../../shared-state/VesselMaintenance/maintenanceTasksCompleted";
import { OptionType } from "../../../components/SeaMultiSelect/SeaMultiSelect";
import EditMaintenanceHistorySettings from '../../../modals/VesselMaintenance/MaintenanceHistory/EditMaintenanceHistorySettings/EditMaintenanceHistorySettings';
import SeaIcon from "../../../components/SeaIcon/SeaIcon";
import SeaButton from "../../../components/SeaButton/SeaButton";
import EditMaintenanceHistory from "../../../modals/VesselMaintenance/MaintenanceHistory/EditMaintenanceHistory/EditMaintenanceHistory";
import ViewMaintenanceHistory from "../../../modals/VesselMaintenance/MaintenanceHistory/ViewMaintenanceHistory/ViewMaintenanceHistory";
import SeaNoData from "../../../components/SeaNoData/SeaNoData";
import RequirePermissions from "../../../components/RequirePermissions/RequirePermissions";
import SeaSelect from "../../../components/SeaSelect/SeaSelect";
import MaintenanceHistoryCsv from '../../../exports/VesselMaintenance/MaintenanceHistory/MaintenanceHistoryCsv';
import MaintenanceHistoryPdf from '../../../exports/VesselMaintenance/MaintenanceHistory/MaintenanceHistoryPdf';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import "./MaintenanceHistory.css";

interface MaintenanceHistoryProps {
    visible: boolean;
}

const MaintenanceHistory: React.FC<MaintenanceHistoryProps> = ({ visible }) => {
    const maintenanceTasksCompleted = sharedState.maintenanceTasksCompleted.use(visible ? 1 : 101);
    const equipment = sharedState.equipment.use(visible ? 1 : 101);
    const vesselSystems = sharedState.vesselSystems.use(visible ? 1 : 101);
    sharedState.vesselLocations.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.spareParts.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.scheduledMaintenanceTasks.use(visible ? 52 : 0); // Prepare for modals to access
    sharedState.maintenanceTasksCompleted.use(visible ? 52 : 0); // Prepare for modals to access
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showMaintenanceHistorySettings, setShowMaintenanceHistorySettings] = useState(false);
    const [selectedItem, setSelectedItem] = useState<MaintenanceTaskCompleted>();
    const [maintenanceHistoryItems, setMaintenanceHistoryItems] = useState<Record<string, MaintenanceTaskCompleted[]>>();
    const [monthCategories, setMonthCategories] = useState<string[]>();
    const [systemFilter, setSystemFilter] = useState("");
    const [equipmentFilter, setEquipmentFilter] = useState("");
    const [completedByFilter, setCompletedByFilter] = useState("");
    const [exportType, setExportType] = useState<ExportType>();

    const equipmentFilterOptions = useMemo(() => {
        const _equipment: string[] = [];
        if (maintenanceTasksCompleted?.filterOptions?.equipmentIds?.length) {
            for (const id of maintenanceTasksCompleted.filterOptions.equipmentIds) {
                if (systemFilter && equipment?.byId[id]?.systemId !== systemFilter) {
                    continue;
                }
                _equipment.push(id);
            }
        }
        return _equipment;
    }, [
        maintenanceTasksCompleted?.filterOptions?.equipmentIds,
        equipment?.byId,
        systemFilter,
    ]);

    const completedByFilterOptions = useMemo(() => {
        if (maintenanceTasksCompleted?.filterOptions?.completedBy?.length) {
            const array = [] as OptionType[];
            for (const userId of maintenanceTasksCompleted.filterOptions.completedBy) {
                array.push({
                    id: userId,
                    name: renderFullNameForUserId(userId),
                });
            }
            array.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            return array;
        }
        return [];
    }, [maintenanceTasksCompleted?.filterOptions.completedBy]);

    useEffect(() => {
        if (visible) {
            logPageView("VesselMaintenance/MaintenanceHistory");
        }
    }, [visible]);

    useEffect(() => {
        // Anything that might affect list length needs to trigger this
        resetPageLimit();
    }, [visible, systemFilter, equipmentFilter, completedByFilter, resetPageLimit]);

    useEffect(() => {
        setMaintenanceHistoryItems(undefined);
        setMonthCategories(undefined);
        const data = {} as {
            [key: string]: MaintenanceTaskCompleted[]
        };
        const categories: string[] = [];

        if (maintenanceTasksCompleted?.all) {
            for (let i = 0; i < maintenanceTasksCompleted.all.length; i++) {
                let item = maintenanceTasksCompleted.all[i];

                if (systemFilter && item.equipment?.systemId !== systemFilter) {
                    continue;
                }
                if (equipmentFilter && item.equipment?.id !== equipmentFilter) {
                    continue;
                }
                if (
                    completedByFilter &&
                    item.completedBy !== completedByFilter
                ) {
                    continue;
                }

                // Put item into date category
                let whenCompleted = formatMonthISO(item.whenCompleted);
                if (data[whenCompleted] === undefined) {
                    categories.push(whenCompleted);
                    data[whenCompleted] = [];
                }
                data[whenCompleted].push(item as MaintenanceTaskCompleted);
            }
            categories.sort().reverse();
            setMaintenanceHistoryItems(data);
            setMonthCategories(categories);
        }
    }, [
        maintenanceTasksCompleted,
        systemFilter,
        equipmentFilter,
        completedByFilter
    ]);

    useEffect(() => {
        if (selectedItem) {
            for (const category in maintenanceHistoryItems) {
                maintenanceHistoryItems[category].forEach((item: MaintenanceTaskCompleted) => {
                    if (item.id === selectedItem?.id) {
                        setSelectedItem(item);
                    }
                });
            }
        }
    }, [maintenanceHistoryItems, selectedItem]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };

    const onViewItem = (item: MaintenanceTaskCompleted) => {
        setShowViewItemModal(true);
        setSelectedItem(item);
    };
    const onEditSettings = () => {
        setShowMaintenanceHistorySettings(true);
    }
    const isUsingFilter = useMemo(() => {
        return (systemFilter || equipmentFilter || completedByFilter) ? true : false;
    }, [completedByFilter, equipmentFilter, systemFilter]);

    return (
        <RequirePermissions
            role="maintenanceHistory"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="maintenance-history page-head">
                <div>
                    <h2>Maintenance History</h2>
                </div>
                <div className="actions">
                    <RequirePermissions
                        role="maintenanceHistory"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            Add Completed Task
                        </SeaButton>
                    </RequirePermissions>
                    <SeaExporter 
                        setExportType={setExportType}
                        csv={exportType === "csv" && <MaintenanceHistoryCsv onCompleted={() => setExportType(undefined)} />}
                        pdf={exportType === "pdf" && (
                            <MaintenanceHistoryPdf
                                onCompleted={() => setExportType(undefined)}
                                maintenanceHistoryItems={maintenanceHistoryItems}
                                monthCategories={monthCategories}
                            />
                        )}
                    />
                    <div className="spacer"></div>
                    <RequirePermissions
                        role="vesselSettings"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton
                            zone="grey"
                            shape="circle"
                            onClick={(e) => onEditSettings()}
                        >
                            <SeaIcon slot="icon-only" icon="settings" />
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            {/* Filters */}
            <div className="columns wrap filters">
                {maintenanceTasksCompleted && maintenanceTasksCompleted.filterOptions.systemIds.length > 0 && (
                    <div>
                        <SeaSelect
                            name="systemFilter"
                            value={systemFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => {
                                setSystemFilter(e.detail.value);
                                setEquipmentFilter("");
                            }}
                        >
                            <IonSelectOption value="">
                                Filter by System
                            </IonSelectOption>
                            {maintenanceTasksCompleted.filterOptions.systemIds.map(
                                (id: string) => {
                                    return (
                                        <IonSelectOption key={id} value={id}>
                                            {renderCategoryName(
                                                id,
                                                vesselSystems
                                            )}
                                        </IonSelectOption>
                                    );
                                }
                            )}
                        </SeaSelect>
                    </div>
                )}
                {maintenanceTasksCompleted && maintenanceTasksCompleted.filterOptions.equipmentIds.length > 0 && (
                    <div>
                        <SeaSelect
                            name="equipmentFilter"
                            value={equipmentFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => 
                                setEquipmentFilter(e.detail.value)
                            }
                        >
                            <IonSelectOption value="">
                                Filter by Equipment
                            </IonSelectOption>
                            {equipmentFilterOptions.map((id: string) => (
                                <IonSelectOption key={id} value={id}>
                                    {equipment?.byId[id]?.equipment}
                                </IonSelectOption>
                            ))}
                        </SeaSelect>
                    </div>
                )}
                {completedByFilterOptions.length > 0 && (
                    <div>
                        <SeaSelect
                            name="completedByFilter"
                            value={completedByFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => 
                                setCompletedByFilter(e.detail.value)
                            }
                        >
                            <IonSelectOption value="">
                                Filter Completed By
                            </IonSelectOption>
                            {completedByFilterOptions.map((item: OptionType) => {
                                return (
                                    <IonSelectOption
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </IonSelectOption>
                                );
                            })}
                        </SeaSelect>
                    </div>
                )}
                {isUsingFilter && (
                    <div>
                        <SeaButton
                            onClick={(e) => {
                                setSystemFilter("");
                                setEquipmentFilter("");
                                setCompletedByFilter("");
                            }}
                            zone="grey"
                        >
                            Reset
                        </SeaButton>
                    </div>
                )}
            </div>

            <SeaNoData
                dataName="maintenance history tasks"
                isLoading={!maintenanceHistoryItems || !monthCategories}
                hasNoData={
                    maintenanceHistoryItems &&
                    monthCategories &&
                    monthCategories.length === 0
                }
                isUsingFilter={isUsingFilter}
            />

            <div
                className={`maintenance-history ${
                    maintenanceHistoryItems &&
                    monthCategories &&
                    monthCategories.length > 0
                        ? "reveal"
                        : "conceal"
                }`}
            >
                <div className="sea-row headings">
                    <div>Date</div>
                    <div>Engine Hours</div>
                    <div>System</div>
                    <div>Equipment</div>
                    <div>Task</div>
                    <div>Type</div>
                    <div>Completed by</div>
                </div>
                <Profiler id="maintenance.history" onRender={onProfilerRender}>
                    {monthCategories?.map((category: string) => {
                        if (isLimitReached()) {
                            // eslint-disable-next-line array-callback-return
                            return;
                        }
                        return (
                            <React.Fragment key={category}>
                                <div className="category-heading">
                                    {formatMonthLonger(category)}
                                </div>
                                {mapArrayWithLimit(
                                    maintenanceHistoryItems?.[category],
                                    (item) => {
                                        return (
                                            <div
                                                key={item.id}
                                                className="sea-card sea-row"
                                                onClick={(e) =>
                                                    onViewItem(item)
                                                }
                                            >
                                                <div className="bold truncate">
                                                    {formatDateShort(
                                                        item.whenCompleted
                                                    )}
                                                </div>
                                                <div>{item?.engineHours}</div>
                                                <div className="truncate">
                                                    {formatValue(
                                                        renderCategoryName(
                                                            item.equipment
                                                                ?.systemId,
                                                            vesselSystems
                                                        )
                                                    )}
                                                </div>
                                                <div className="truncate">
                                                    {formatValue(
                                                        item.equipment
                                                            ?.equipment
                                                    )}
                                                    {item.equipment?.state ===
                                                        "deleted" &&
                                                        " (deleted)"}
                                                </div>
                                                <div className="truncate">
                                                    {formatValue(item.task)}
                                                </div>
                                                <div>
                                                    {formatValue(item.type === 'unscheduled' ? 'Job' : renderCamelCase(item.type))}
                                                </div>
                                                <div className="truncate">
                                                    {renderFullNameForUserId(
                                                        item.completedBy
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </React.Fragment>
                        );
                    })}
                </Profiler>
                {visible && limitTriggerElement}
            </div>
            {visible &&
                <>
                    <EditMaintenanceHistory
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                    />
                    {selectedItem && <ViewMaintenanceHistory
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                    <EditMaintenanceHistorySettings
                        showModal={showMaintenanceHistorySettings}
                        setShowModal={setShowMaintenanceHistorySettings}
                    />
                </>
            }
        </RequirePermissions>
    );
};

export default MaintenanceHistory;
