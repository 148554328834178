import React, { useEffect, useState } from 'react';
import { regions, formatValue } from '../../../lib/util';
import { formatDateShort, formatTime, formatDateLonger } from '../../../lib/datesAndTime';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { logModalView } from '../../../lib/firebase';
import { canView, canAccessAnyVessels, canCreate } from '../../../shared-state/Core/userPermissions';
import { sharedState } from '../../../shared-state/shared-state';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { openContextualHelp } from '../../../managers/ContextualHelpManager/ContextualHelpManager';
import { ActionLogEntry } from '../../../shared-state/General/actionLog';
import { LicenseeSettings } from '../../../shared-state/Core/licenseeSettings';
import { renderVesselsList } from '../../../shared-state/Core/vessels';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import './ActionLogModal.css';

export const getActionIcon = (action: ActionLogEntry) => {
    switch (action.action) {
        case 'A':
            return <SeaIcon slot='start' icon="add" forceFontSize={'18px'} />;
        case 'V':
            return <SeaIcon slot='start' icon="archive" forceFontSize={'16px'} marginBottom={0}/>;
        case 'D':
            return <SeaIcon slot='start' icon="trash" forceFontSize={'16px'} marginBottom={2}/>;
        case 'N':
        case 'R':
            return <SeaIcon slot='start' icon="refresh" forceFontSize={'16px'} />;
    }
    return <SeaIcon slot='start' icon="edit" forceFontSize={'17px'} marginBottom={2} />;
}

export const formatAction = (action: ActionLogEntry, licenseeSettings: LicenseeSettings | undefined) => {
    const getDefaultVerb = (action: ActionLogEntry): string => {
        switch (action.action) {
            case 'A': return 'Added';
            case 'U': return 'Updated';
            case 'N': return 'Renewed';
            case 'V': return 'Archived';
            case 'R': return 'Unarchived';
            case 'D': return 'Deleted';
        }
        return 'Updated';
    };
    let verb = getDefaultVerb(action);
    const getNoun = (action: ActionLogEntry): string => {
        switch (action.collection) {
            case 'engines': return 'Engine';
            case 'vessels':
                if (action.type) {
                    if (action.type === 'logbookSettings') return 'Logbook Settings';
                    if (action.type === 'safetyMeetingSettings') return 'Safety Meeting Settings';
                }
                return 'Vessel Settings';
            case 'voyages':
                if (action.type) {
                    if (action.type === 'progress') return 'Voyage Progress';
                    if (action.type === 'complete') {
                        verb = 'Completed';
                        return 'Voyage';
                    }
                }
                return 'Voyage';
            case 'safetyCheckItems': return 'Safety Check';
            case 'safetyCheckCompleted':
                if (action.action === 'A') {
                    verb = 'Completed';
                    return 'Safety Check';
                }
                return 'Completed Safety Check';
            case 'safetyEquipmentItems': return 'Safety Equipment';
            case 'safetyEquipmentTaskCompleted':
                if (action.action === 'A') {
                    verb = 'Completed';
                    return 'Safety Check';
                }
                return 'Completed Safety Check';
            case 'hazards': return 'Hazard';
            case 'risks': return 'Risk Assessment';
            case 'risksReviewed':
                if (action.action === 'A') {
                    verb = 'Reviewed';
                    return 'Risk Assessment';
                }
                return 'Risk Assessment Review';
            //case 'medicalReports': return 'Incident/Accident/Medical Report';
            case 'incidents': return 'Incident / Event Report';
            case 'incidentReviews': return 'Incident / Event Review';
            case 'drills': return 'Drill';
            case 'drillReports': return 'Drill Report';
            case 'scheduledMaintenanceTasks': return 'Scheduled Maintenance Task';
            case 'maintenanceTasksCompleted':
                if (action.type) {
                    if (action.type === 'scheduled') {
                        if (action.action === 'A') {
                            verb = 'Completed';
                            return 'Scheduled Maintenance Task';
                        }
                        return 'Completed Scheduled Maintenance Task';
                    } else if (action.type === 'job') {
                        verb = 'Completed';
                        return 'Job';
                    }
                }
                return 'Completed Maintenance Task';
            case 'jobs': return 'Job';
            case 'spareParts': return 'Spare Part';
            case 'equipment': return 'Equipment';
            case 'vesselCertificates': return 'Vessel Certificate';
            case 'vesselDocuments': return 'Vessel Document';
            case 'surveyReports': return 'Survey Document';
            case 'SOPs': return 'SOP';
            case 'SOPsCompleted':
                if (action.action === 'A') {
                    verb = 'Completed';
                    return 'SOP';
                }
                return 'Completed SOP';
            case 'safetyMeetingReports': return 'Safety Meeting Report';
            case 'dangerousGoods': return 'Dangerous Good';
            case 'equipmentManualDocuments': return 'Equipment Manual Document';
            case 'customForms': return 'Form/Checklist';
            case 'customFormsCompleted':
                if (action.action === 'A') {
                    verb = 'Completed';
                    return 'Form/Checklist';
                }
                return 'Completed Form/Checklist';
            case 'users': return 'User';
            case 'userPermissions': return 'User Permissions';
            case 'userDocuments': return 'User Document(s)';
            case 'voyageDocuments': return 'Voyage Document(s)';
            case 'crewCertificates': return 'Crew Certificate';
            case 'trainingTasks': return 'Crew Training Task';
            case 'trainingTaskReports':
                if (action.action === 'A') {
                    verb = 'Completed';
                    return 'Crew Training';
                }
                // else deleted
                verb = '';
                return 'Crew Removed from Completed Training';
            case 'trainingTaskReportDocuments':
                if (action.action === 'A') {
                    verb = 'Added';
                    return 'Crew Training Document(s)';
                }
                return 'Crew Training Document(s)';
            case 'contacts': return 'Contact/Supplier';
            case 'companyPlans':
                if (!licenseeSettings) {
                    return '';
                }
                return regions[licenseeSettings?.region]?.companyPlanShort;
            case 'companyDocuments':
                return 'Company Document';
            case 'userPermissionDefaults':
                return 'User Permission Default';
            case 'correctiveActions':
                if (action.action === 'A') {
                    verb = 'Completed';
                    return 'Corrective Action';
                }
                return 'Corrective Action';
        }
        return 'Something';
    };
    let noun = getNoun(action);
    return `${noun} ${verb}`;
    //return 'Scheduled Maintenance Task Completed';
};

export const canViewAction = (action: ActionLogEntry) => {
    const userId = sharedState.userId.current;
    if (!userId) {
        return false;
    }
    switch (action.collection) {
        case 'engines':
            return canView('engineHours');
        case 'vessels':
            if (action.type) {
                if (action.type === 'logbookSettings') return canView('logbook');
                if (action.type === 'safetyMeetingSettings') return canView('healthSafetyMeetings');
            }
            return canView('vesselSettings');
        case 'voyages':
        case 'voyageDocuments':
            return canView('logbook');
        case 'safetyCheckItems':
        case 'safetyCheckCompleted':
            return canView('safetyEquipmentChecks');
        case 'safetyEquipmentItems':
        case 'safetyEquipmentTaskCompleted':
            return canView('safetyEquipmentList');
        case 'hazards':
            return canView('hazardRegister');
        // case 'medicalReports':
        //     return canView('incidentAccidentMedicalRegister');
        case 'incidents':
        case 'incidentReviews':
            return canView('incidentAccidentMedicalRegister');
        case 'correctiveActions':
            return canView('correctiveActions');
        case 'drills':
        case 'drillReports':
            return canView('drills');
        case 'scheduledMaintenanceTasks':
            return canView('maintenanceSchedule');
        case 'maintenanceTasksCompleted':
            if (action.type) {
                if (action.type === 'scheduled') {
                    return canView('maintenanceSchedule') || canView('maintenanceHistory');
                }
                if (action.type === 'job') {
                    return canView('jobList') || canView('maintenanceHistory');
                }
            }
            return canView('maintenanceHistory');
        case 'jobs':
            return canView('jobList');
        case 'spareParts':
            return canView('sparePartsList');
        case 'vesselCertificates':
            return canView('vesselCertificates');
        case 'vesselDocuments':
            return canView('vesselDocuments');
        case 'surveyReports':
            return canView('survey');
        case 'SOPs':
        case 'SOPsCompleted':
            return canView('standardOperatingProcedures');
        case 'safetyMeetingReports':
            return canView('healthSafetyMeetings');
        case 'dangerousGoods':
            return canView('dangerousGoodsRegister');
        case 'equipmentManualDocuments':
            return canView('equipmentManualDocuments');
        case 'customForms':
            return canView('customForms');
        case 'customFormsCompleted':
            return (
                canView('customForms') || (
                    canView('crewParticulars') &&
                    action.personnelIds &&
                    action.personnelIds.length > 0
                ) || (
                    action.personnelIds &&
                    action.personnelIds.length > 0 &&
                    action.personnelIds.includes(userId)
                )
            );
        case 'userDocuments':
            return (
                canView('crewParticulars') || (
                    action.personnelIds &&
                    action.personnelIds.length > 0 &&
                    action.personnelIds.includes(userId)
                )
            );
        case 'users':
        case 'userPermissions':
            return canView('crewParticulars');
        case 'userPermissionDefaults':
            return canCreate('crewParticulars');
        case 'crewCertificates':
            return canView('crewCertificates');
        case 'trainingTasks':
        case 'trainingTaskReports':
            return canView('crewTraining');
        case 'trainingTaskReportDocuments':
            return canView('crewTraining');
        case 'contacts':
            return canView('contacts');
        case 'companyPlan':
            return canView('companyPlan');
        case 'companyDocuments':
            return canView('companyDocuments');
    }
    return true;
};


interface ActionLogModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    vesselId?: string
}

const ActionLogModal: React.FC<ActionLogModalProps> = ({showModal, setShowModal, vesselId}) => {
    const user = sharedState.user.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const divisions = sharedState.divisions.use(showModal);
    const actionLog = sharedState.actionLog.use(showModal);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter(100); // itemsPerPage needs to be bigger than default because they don't take up much vertical space
    const [vesselActions, setVesselActions] = useState<{
        days: string[];
        byDay: {
            [key: string]: ActionLogEntry[];
        }
    }>();

    useEffect(() => {
        if (showModal) {
            logModalView('ActionLog', true);
        }
    }, [showModal]);

    useEffect(() => {
        resetPageLimit();
    }, [showModal, vesselActions, resetPageLimit]);

    useEffect(() => {
        setVesselActions(undefined);
        if (showModal && actionLog) {
            const days = [] as string[];
            const byDay: {
                [key: string]: ActionLogEntry[]
            } = {};

            actionLog.forEach((action) => {
                if (
                    (
                        vesselId === undefined ||
                        action.vesselIds === 'any' ||
                        action.vesselIds.includes(vesselId)
                    ) && (
                        vesselId !== undefined ||
                        action.vesselIds === 'any' ||
                        canAccessAnyVessels(action.vesselIds)
                    ) &&
                    canViewAction(action)
                ) {
                    const day = formatDateShort(action.when);
                    if (days.length === 0 || days[days.length - 1] !== day) {
                        days.push(day);
                        byDay[day] = [];
                    }
                    byDay[day].push(action);
                }
            });

            setVesselActions({
                days,
                byDay
            });
        }
    }, [showModal, actionLog, vesselId, user]);

    // const onViewFullNotes = (event: any, notes: any) => {
    //     event.stopPropagation();
    //     alertMessage(formatValue(formatTextAreaText(notes, true))); 
    // };
    
    return (
        <SeaModal
            title={`Action Log${(vesselId && vessels?.byId && vessels.byId[vesselId]) ? ` - ${vessels.byId[vesselId].name}` : ''}`}
            showModal={showModal}
            setShowModal={setShowModal}
            size="extra-wide"
        >
            <SeaNoData
                dataName="action log"
                isLoading={vesselActions ? false : true}
                hasNoData={vesselActions && vesselActions.days.length === 0}
            />
            <div className={`action-log ${vesselId ? 'hide-vessels' : 'show-vessels'} ${(vesselActions && vesselActions.days.length > 0) ? 'reveal' : 'conceal'}`}>
                <div className="sea-row-history headings-history" style={{marginBottom: '-8px'}}>
                    <div>Date</div>
                    <div></div>
                    <div>Action</div>
                    <div>Detail</div>
                    <div>Vessels / Facilities</div>
                    <div>Done By</div>
                </div>
                {vesselActions?.days?.map((day: string) => {
                    if (isLimitReached()) {
                        return undefined;
                    }
                    return (
                        <React.Fragment key={day}>
                            <div className="category-heading log-day">
                                {formatDateLonger(vesselActions.byDay[day][0].when)}
                            </div>
                            <div className="custom-history-table">
                                {mapArrayWithLimit(vesselActions?.byDay[day], (action) => {
                                    return (
                                        <div className="sea-card sea-row-history" key={action.id}>
                                            <div data-fullname={`, ${renderFullNameForUserId(action.userId)}`}>{formatTime(action.when)}</div>
                                            <div>{getActionIcon(action)}</div>
                                            <div onClick={(e) => openContextualHelp(e, {text: formatAction(action, licenseeSettings)})}>
                                                {formatAction(action, licenseeSettings)}
                                            </div>
                                            {/* <div onClick={(e) => openContextualHelp(e, {text: action.detail})}> */}
                                            <div>
                                                {formatValue(action.detail)}
                                            </div>
                                            <div onClick={(e) => openContextualHelp(e, {text: renderVesselsList(action.vesselIds, vessels, divisions)})}>
                                                {renderVesselsList(action.vesselIds, vessels, divisions)}
                                            </div>
                                            <div onClick={(e) => openContextualHelp(e, {text: renderFullNameForUserId(action.userId)})}>
                                                {renderFullNameForUserId(action.userId)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </React.Fragment>
                    );
                })}
                {limitTriggerElement}
            </div>
        </SeaModal>
    );
};

export default ActionLogModal;
