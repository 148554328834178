import { useCallback, useMemo } from "react";
import { CsvConfig, formatCsvBoolean, formatCsvNamesFromIds } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { SparePart } from "../../../shared-state/VesselMaintenance/spareParts";
import { renderCategoryName } from '../../../lib/categories';
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';

type SparePartsCsvProps = {
    onCompleted: () => void
}

const SparePartsCsv = ({onCompleted}: SparePartsCsvProps) => {
    const spareParts = sharedState.spareParts.use();
    const equipment = sharedState.equipment.use();
    const vesselLocations = sharedState.vesselLocations.use();
    const vesselSystems = sharedState.vesselSystems.use();
    const contacts = sharedState.contacts.use();

    const checkContainsCritical = useCallback((equipmentIds: string[] | undefined) => {
        if (!equipmentIds) return false;
        for (const id of equipmentIds) {
            if (equipment?.byId[id]?.isCritical) {
                return true;
            }
        }
        return false;
    }, [equipment]);

    const csvConfig: CsvConfig<SparePart> | undefined = useMemo(() => {
        if (!spareParts || !equipment || !vesselLocations || !vesselSystems || !contacts) {
            return undefined;
        }
        return [
            {
                name: 'Item',
                value: (item) => item.item
            },
            {
                name: 'System',
                value: (item) => renderCategoryName(item.systemId, vesselSystems)
            },
            {
                name: 'Equipment',
                value: (item) => formatCsvNamesFromIds(item.equipmentIds, equipment?.byId, 'equipment')
            },
            {
                name: 'Location',
                value: (item) => renderCategoryName(item.locationId, vesselLocations)
            },
            {
                name: 'Critical',
                value: (item) => formatCsvBoolean(checkContainsCritical(item.equipmentIds))
            },
            {
                name: 'Location Description',
                value: (item) => item.locationDescription || ''
            },
            {
                name: 'Quantity',
                value: (item) => item.quantity?.toString() || ''
            },
            {
                name: 'Minimum Quantity',
                value: (item) => item.minQuantity?.toString() || ''
            },
            {
                name: 'Manufacturer',
                value: (item) => item.manufacturer || ''
            },
            {
                name: 'Model',
                value: (item) => item.model || ''
            },
            {
                name: 'Part #',
                value: (item) => item.partNum || ''
            },
            {
                name: 'Supplier Contract',
                value: (item) => formatCsvNamesFromIds(item.contactIds, contacts?.byId)
            }
        ];
    }, [checkContainsCritical, contacts, equipment, spareParts, vesselLocations, vesselSystems]);


    return (
        <SeaCSVGenerator
            name="Spare-Parts"
            onCompleted={onCompleted}
            config={csvConfig}
            data={spareParts?.all}
        />
    );
};

export default SparePartsCsv