import React, { useCallback } from 'react';
import { Document, View, Page, Text, Image } from '@react-pdf/renderer';
import { PdfPageLimiter, UnloadedPdfRows, pdfColours, pdfStyles } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { warnDays, dateDueToClassName, formatDateShort } from '../../../lib/datesAndTime';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { getImgSrcFromString } from '../../../lib/files';
import { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { SafetyEquipmentItem } from '../../../shared-state/VesselSafety/safetyEquipmentItems';
import { ReportingSafetyItemById } from '../../../shared-state/Reporting/SafetyReporting/useReportingSafetyItemsById';
import { renderVesselsList } from '../../../shared-state/Core/vessels';
import SeaNoDataPDF from '../../../components/SeaNoDataPDF';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';
import CriticalFlagPDF from '../../../assets/svg/CriticalFlagPDF';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaHorizontalStackedBarGraph from '../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import reporting from '../../../lib/reporting';

interface SafetyEquipmentStatusReportPDFProps {
    onCompleted: () => void;
    title: string;
    subTitle?: string;
    graphData?: GraphData[];
    filteredItems?: SafetyEquipmentItem[];
    vesselSafetyItemsById?: ReportingSafetyItemById;
    categories?: (string | undefined)[];
    labelWidthsForPdf?: number[];
}

const SafetyEquipmentStatusReportPDF: React.FC<SafetyEquipmentStatusReportPDFProps> = ({ onCompleted, title, subTitle, graphData, filteredItems, vesselSafetyItemsById, categories, labelWidthsForPdf }) => {
    const vessels = sharedState.vessels.use();
    const divisions = sharedState.divisions.use();
    const { selectedVesselId, selectedVesselIds } = useReportSettings();
    const counterRef = React.useRef(0);
    const { mapPdfArrayWithLimit } = PdfPageLimiter(counterRef);

    const MakePDF = useCallback(() => {
        counterRef.current = 0;
        return (
            <Document title={title} author="Sea Flux" creator="Sea Flux" producer="Sea Flux">
                <Page
                    style={{
                        ...pdfStyles.page,
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader heading={title} subHeading={subTitle} userFullName={renderFullName()} />
                    {vessels && selectedVesselIds?.length > 1 ? (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Vessels</Text>
                                <Text>{renderVesselsList(selectedVesselIds, vessels, divisions)}</Text>
                            </View>
                        </View>
                    ) : null}

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <SeaHorizontalStackedBarGraph mode="pdf" data={graphData} n={0} visible={true} categories={categories} colourPalette={reporting.colours.taskStatuses} labelWidthsForPdf={labelWidthsForPdf} />
                        </View>
                    </View>

                    <SeaNoDataPDF dataName="safety equipment expiries" hasNoData={!filteredItems?.length} isUsingFilter={true} />
                    {filteredItems?.length ? (
                        <View style={pdfStyles.dataContainer}>
                            <View style={pdfStyles.dataHeaderRow}>
                                <View style={pdfStyles.thumbColumn}></View>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2x3]}>Safety Item</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.dateColumn]}>Service / Expiry</Text>
                                {!selectedVesselId ? <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Vessel</Text> : null}
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.statusColumn]}>Status</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.criticalColumn]}>Critical</Text>
                            </View>
                            {mapPdfArrayWithLimit(filteredItems, (item) => {
                                const file = item.files && item.files.length > 0 ? item.files[0] : undefined;
                                const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                const status = dateDueToClassName(item.dateDue, warnDays.safetyEquipmentChecks[0]);
                                const bgColor = status === 'warn' ? pdfColours.bgWarnColor : status === 'fail' ? pdfColours.bgFailColor : undefined;
                                return (
                                    <View wrap={false} key={item.id} style={[pdfStyles.greyDataRow, { backgroundColor: bgColor }]}>
                                        <View style={pdfStyles.thumbColumn}>{imgSrc && <Image src={imgSrc} style={pdfStyles.thumbImgRound} />}</View>
                                        <Text style={[pdfStyles.truncate, pdfStyles.column2x3, pdfStyles.bold]}>{formatValue(vesselSafetyItemsById?.[item.itemId]?.name)}</Text>
                                        <Text style={pdfStyles.dateColumn}>{formatDateShort(item.dateDue)}</Text>
                                        {!selectedVesselId ? <Text style={[pdfStyles.truncate, pdfStyles.column1]}>{vessels?.byId[item.vesselId]?.name}</Text> : null}
                                        <View style={pdfStyles.statusColumn}>
                                            <SeaStatusDueDate dateDue={item.dateDue} warnDays={warnDays.safetyEquipmentExpiries[0]} forPdf={true} />
                                        </View>
                                        <View style={pdfStyles.criticalColumn}>{vesselSafetyItemsById?.[item.itemId]?.isCritical && <CriticalFlagPDF />}</View>
                                    </View>
                                );
                            })}
                            <UnloadedPdfRows length={filteredItems?.length} />
                        </View>
                    ) : null}
                </Page>
            </Document>
        );
    }, [title, subTitle, vessels, selectedVesselIds, divisions, graphData, categories, labelWidthsForPdf, filteredItems, selectedVesselId, mapPdfArrayWithLimit, vesselSafetyItemsById]);

    return <SeaPdfGenerator onCompleted={onCompleted} pdfTitle={title} MakePdf={MakePDF} requestedFrom="safetyEquipmentStatusReport" />;
};

export default SafetyEquipmentStatusReportPDF;
