import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Division, Divisions } from '../../shared-state/Core/divisions';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import { renderVesselName } from '../../shared-state/Core/vessels';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaModalBox from '../SeaModalBox/SeaModalBox';
import SeaIcon from '../SeaIcon/SeaIcon';

interface SeaSelectVesselUsingDivisionsProps {
    label?: string,
    divisions: Divisions,
    vesselId?: string,
    setVesselId?: React.Dispatch<React.SetStateAction<string | undefined>>,
    onChange?: (vesselId: string) => void,
    emptyText?: string,
    width?: string
    zone?: 'blue' | 'white' | 'grey',
    help?: SeaHelp,
    required?: boolean,
    requiredError?: string,
    isSubmitting?: boolean, // Pass in formik's isSubmitting so we can tell if form has been submitted (count as touched)
    disabled?: boolean,
}

type IsFolderOpen = {
    [divisionId: string]: boolean;
}
let defaultIsFolderOpen = undefined as (IsFolderOpen | undefined);

const SeaSelectVesselUsingDivisions: React.FC<SeaSelectVesselUsingDivisionsProps> = ({
    label,
    divisions,
    vesselId,
    setVesselId,
    onChange,
    emptyText = 'Not Set',
    width,
    zone = 'grey',
    help,
    required,
    requiredError = 'Please select a vessel',
    isSubmitting,
    disabled
}) => {
    const [showModal, setShowModal] = useState(false);
    const [isFolderOpen, setIsFolderOpen] = useState<{ // Indicates whether a division is expanded or not (opened or closed)
        [divisionId: string]: boolean
    }>();
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        let isActive = true;
        setTouched(false);
        setTimeout(() => {
            if (!isActive) return;
            setTouched(false);
        }, 100);
        return () => { isActive = false; };
    }, []);

    useEffect(() => {
        if (isSubmitting) {
            setTouched(true);
        }
    }, [isSubmitting]);

    let error = '';
    if (required && touched && !vesselId) {
        error = requiredError;
    }

    const toggleFolder = useCallback((e: any, divisionId: string) => {
        e.preventDefault();
        e.stopPropagation();
        setIsFolderOpen((current) => {
            const result = {
                ...current,
                [divisionId]: !(current?.[divisionId])
            };
            defaultIsFolderOpen = result;
            return result;
        });
    }, []);

    const countOffspring = useCallback((division: Division) => {
        let count = 1 + division.vessels.length;
        division.children?.forEach((child) => {
            if (isFolderOpen && isFolderOpen[child.id]) {
                count += countOffspring(child);
            } else {
                count++;
            }
        });
        return count;
    }, [isFolderOpen]);

    // Default division folders to all being open
    useEffect(() => {
        if (defaultIsFolderOpen) {
            // Remember the last time we used isFolderOpen, where that was within the app
            setIsFolderOpen(defaultIsFolderOpen);
            return;
        }
        const isOpen = {
            'root': true
        } as any;
        divisions?.all.forEach((division) => {
            isOpen[division.id] = true;
        });
        setIsFolderOpen(isOpen);
    }, [divisions]);

    const renderVessels = useCallback((division: Division) => {
        return division.vessels.map((vessel) => {
            if (!vessel.canAccess) {
                return null;
            }
            return (
                <div key={vessel.id} className="sea-division-option columns">
                    <div className="name hover no-select" onClick={(e) => {
                        if (setVesselId) {
                            setVesselId(vessel.id);
                        }
                        if (onChange) {
                            setTimeout(() => {
                                onChange(vessel.id);
                            });
                        }
                        setShowModal(false);
                    }}>
                        {vessel.name}
                    </div>
                </div>
            );
        });
    }, [onChange, setVesselId]);

    const renderDivisions = useCallback((division: Division) => {
        //if (division.vessels.length > 0 || (division.children && division.children.length > 0)) {
        if (division.numVesselsAccess === 0) {
            return null;
        }
        const isOpen = isFolderOpen?.[division.id] ? true : false;
        return (
            <React.Fragment key={division.id}>
                {division.id !== 'root' &&
                    <div className="sea-division-option columns" onClick={(e) => toggleFolder(e, division.id)}>
                        <div
                            className={`folder-icon pushy ${isOpen ? 'opened' : 'closed'}`}
                        >
                            <SeaIcon
                                icon="folderOpened"
                            />
                        </div>
                        <div className="name no-select" style={{ fontWeight: '600' }}>
                            {division.id === 'root' ? 'Top Level' : division.name}
                        </div>
                    </div>
                }
                <div className="sea-division-parent" style={{ paddingLeft: (division.id === 'root' ? '8px' : '33px'), opacity: isOpen ? 1 : 0, maxHeight: isOpen ? (countOffspring(division) * 40)+'px' : '0px', pointerEvents: isOpen ? 'all' : 'none' }}>
                    {
                        division.children?.map((child) => {
                            return renderDivisions(child);
                        })
                    }
                    {division.vessels.length > 0 && 
                        <div style={{ paddingLeft: '26px' }}>
                            {renderVessels(division)}
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }, [countOffspring, isFolderOpen, renderVessels, toggleFolder]);

    const summaryText = useMemo(() => {
        if (vesselId) {
            return renderVesselName(vesselId);
        }
        return emptyText;
    }, [emptyText, vesselId]);

    const stopClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <>
            {label && <div><SeaLabel zone={zone} help={help}>{label}</SeaLabel></div>}
            <div
                className={`sea-input sea-select multi ${zone}-zone ${error ? 'has-error' : ''} ${disabled ? 'disabled' : ''}`}
                style={{ width: width }}
                onClick={() => !disabled && setShowModal(true)}
            >
                <div className="select-text no-select">
                    {summaryText}
                </div>
                <div className="select-icon">
                    <div className="select-icon-inner"></div>
                </div>
            </div>
            <SeaInputError alignLeft>{error}</SeaInputError>
            <SeaModalBox
                showModal={showModal}
                setShowModal={setShowModal}
                maxWidth={600}
                //maxHeight="80vh"
                className="sea-vessels-modal"
            >
                <div className="sea-select-multi-title" style={{ maxWidth: '100%' }}>
                    Select Vessel
                </div>
                <div
                    className="sea-select-multi-box"
                    onClick={stopClick}
                    style={{ padding: '4px 4px 12px 4px', width: '500px', minWidth: '200px' }}
                >
                    {renderDivisions(divisions.root)}
                </div>
            </SeaModalBox>
        </>
    );

};

export default SeaSelectVesselUsingDivisions;
