import React, { useState, useEffect, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { formatDateShort } from '../../../lib/datesAndTime';
import { onProfilerRender } from '../../../lib/performance';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { renderCategoryName } from '../../../lib/categories';
import { sharedState } from '../../../shared-state/shared-state';
import { SOP } from '../../../shared-state/VesselDocuments/vesselSOPS';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import EditSOP from '../../../modals/VesselDocuments/SOPs/EditSOP/EditSOP';
import ViewSOP from '../../../modals/VesselDocuments/SOPs/ViewSOP/ViewSOP';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import EditSopSettings from '../../../modals/VesselDocuments/SOPs/EditSopSettings/EditSopSettings';
import './StandardOperatingProcedures.css';


interface StandardOperatingProceduresProps {
    visible: boolean
}

const StandardOperatingProcedures: React.FC<StandardOperatingProceduresProps> = ({visible}) => {
    const vesselSopCategories = sharedState.vesselSOPCategories.use(visible ? 1 : 101);
    const SOPs = sharedState.vesselSOPs.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showEditSopSettings, setShowEditSopSettings] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SOP>();

    useEffect(() => {
        if (visible) {
            logPageView('VesselDocuments/StandardOperatingProcedures');
        }
    }, [visible]);

    useEffect(() => {
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (
            selectedItem?.id &&
            SOPs?.byId[selectedItem.id]
        ) { 
            setSelectedItem(SOPs.byId[selectedItem.id]);
        };
    }, [SOPs, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: SOP) => {
        console.log(`Viewing SOP ${item.id} ${item.title}`);
        setSelectedItem(item);
        setShowViewItemModal(true);
    };
    const onSettings = () => {
        setShowEditSopSettings(true);
    };

    return (
        <RequirePermissions
            role="standardOperatingProcedures"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="standard-operating-procedures page-head">
                <div><h2>Standard Operating Procedures</h2></div>
                <div className="actions">
                    <RequirePermissions
                        role="standardOperatingProcedures"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Add New
                        </SeaButton>
                        <RequirePermissions
                            role="standardOperatingProcedures"
                            level={permissionLevels.EDIT}
                        >
                        <SeaButton zone="grey" shape="circle" onClick={(e) => onSettings()}>
                            <SeaIcon slot="icon-only" icon="settings"/>
                        </SeaButton>
                        </RequirePermissions>
                    </RequirePermissions>
                </div>
            </div>

            <SeaNoData
                dataName="standard operating procedures"
                isLoading={!SOPs}
                hasNoData={SOPs && SOPs.categoryIds.length === 0}
                isUsingFilter={false}
            />

            <div className={`standard-operating-procedures has-thumbs ${(SOPs && (SOPs.categoryIds.length > 0 || !SOPs.byId.isEmpty)) ? 'reveal' : 'conceal'}`}>
                <div className="sea-row headings">
                    <div></div>
                    <div>Title</div>
                    <div>Issue Date</div>
                </div>
                <Profiler id="documents.SOPs" onRender={onProfilerRender}>
                    {SOPs?.categoryIds?.map((categoryId: string) => {
                        if (isLimitReached()) {
                            return undefined;
                        }
                        return (
                            <React.Fragment key={categoryId}>
                                <div className="category-heading">
                                    {renderCategoryName(categoryId, vesselSopCategories)}
                                </div>
                                {mapArrayWithLimit(SOPs.byCategoryId[categoryId], (item) => {
                                    return (
                                        <div key={item.id} className="sea-card sea-row" onClick={(e) => onViewItem(item)}>
                                            <div>
                                                <SeaFileImage
                                                    files={item.files}
                                                    sfdoc={item.sfdoc}
                                                    size="tiny"
                                                />
                                            </div>
                                            <div className="bold truncate-2">{item.title}</div>
                                            <div>{formatDateShort(item.dateIssued)}</div>
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                    {SOPs && !SOPs?.categoryIds.length ? mapArrayWithLimit(Object.values(SOPs.byId), (item) => {
                        return (
                            <div key={item.id} className="sea-card sea-row" onClick={(e) => onViewItem(item)}>
                                <div>
                                    <SeaFileImage
                                        files={item.files}
                                        sfdoc={item.sfdoc}
                                        size="tiny"
                                    />
                                </div>
                                <div className="bold truncate-2">{item.title}</div>
                                <div>{formatDateShort(item.dateIssued)}</div>
                            </div>
                        );
                    }) : null}
                    {visible && limitTriggerElement}
                </Profiler>
            </div>
            {visible && vesselSopCategories &&
                <>
                    <EditSOP
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        vesselSopCategories={vesselSopCategories}
                    />
                    {selectedItem && <ViewSOP
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                    <EditSopSettings
                        showModal={showEditSopSettings} 
                        setShowModal={setShowEditSopSettings}
                        vesselSopCategories={vesselSopCategories}
                    />
                </>
            }
        </RequirePermissions>
    );
};

export default StandardOperatingProcedures;
