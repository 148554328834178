import React, { useState, useEffect, useMemo } from 'react';
import { WriteBatch } from "firebase/firestore";
import { formatValue } from '../../../../lib/util';
import { formatDate } from '../../../../lib/datesAndTime';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { renderCategoryName } from '../../../../lib/categories';
import { RichTextState, initialRichTextState, loadSfdoc } from '../../../../lib/richText';
import { SplittableBatch } from '../../../../lib/firebase';
import { SeaScrollable } from '../../../../components/SeaScrollableArea/SeaScrollableArea';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { sharedState } from '../../../../shared-state/shared-state';
import { SOP } from '../../../../shared-state/VesselDocuments/vesselSOPS';
import { SeaLinks } from '../../../../components/SeaLinks/SeaLinks';
import { useItemLinks } from '../../../../shared-state/Core/useItemLinks';
import { useFetchSingleItem } from '../../../../shared-state/Core/fetchSingleItem';
import { isPdf } from '../../../../lib/files';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditSOP from '../EditSOP/EditSOP';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaRichText from '../../../../components/lexical/SeaRichText/SeaRichText';
import SeaPDFViewer from '../../../../components/SeaPDFViewer/SeaPDFViewer';
import SeaLabel from '../../../../components/SeaLabel/SeaLabel';
import './ViewSOP.css';

interface ViewSOPProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem?: SOP,
    level?: number
    viewOnly?: boolean,
}

const ViewSOP: React.FC<ViewSOPProps> = ({showModal, setShowModal, selectedItem, level = 1, viewOnly}) => {
    const vesselId = sharedState.vesselId.use(showModal);
    const vesselSOPCategories = sharedState.vesselSOPCategories.use(showModal);
    // This is used if there is no selected vessel (When viewed from Links)
    const fetchedCategory = useFetchSingleItem('vesselSopCategories', selectedItem?.categoryId || '', !showModal || !!vesselSOPCategories?.byId[selectedItem?.categoryId || '']); 
    const links = useItemLinks(selectedItem?.id);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [richTextState, setRichTextState] = useState<RichTextState>(initialRichTextState);
    const [onScroll, setOnScroll] = useState<(event: Event) => void>();
    const [visible, setVisible] = useState(false);
    const [modalContentRef, setModalContentRef] = useState<React.RefObject<SeaScrollable>>();

    // Handle loading
    useEffect(() => {
        if (selectedItem?.sfdoc) {
            return loadSfdoc(
                selectedItem.sfdoc,
                setRichTextState
                // getDefaultContent,
                // licenseeSettings !== undefined,
            );
        }
    }, [selectedItem?.sfdoc]);

    // useEffect(() => {
    //     setCompletedSopItems(undefined);
    //     if (selectedItem) {
    //         return onSnapshot(
    //             query(
    //                 collection(firestore, 'SOPsCompleted'),
    //                 where('sopId', '==', selectedItem.id),
    //                 where('state', '==', 'active'),
    //                 orderBy('whenCompleted', 'desc')
    //             ),
    //             (snap) => {
    //                 const _completedSopItems = snap.docs.map((doc) => {
    //                     registerFiles(doc.data().files);
    //                     return {
    //                         id: doc.id,
    //                         ...doc.data()
    //                     };
    //                 });
    //                 setCompletedSopItems(_completedSopItems);
    //             }, (error) => {
    //                 reportError('Failed to access SOPs completed data', error.message, error, {
    //                     selectedItem
    //                 });
    //             }
    //         );
    //     }
    // }, [selectedItem]);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    const onDeleteItem = (item: SOP, closeModal = false) => {
        deleteIfConfirmed(
            'SOPs',
            item.id,
            (batch: WriteBatch | SplittableBatch) => {
                onCollectionUpdated(batch, 'SOPs');
                if (closeModal) {
                    setShowModal(false);
                }
            },
            'item',
            selectedItem?.title,
            vesselId ? [vesselId] : []
        );
        //setRequestToDeleteItem({item: item, collection: collectionName, closeModal: closeModal})
    };

    const categoryName = useMemo(() => {
        let category = '';
        // Implies that vesselSOPCategories doesn't exist, so there is likely no selected vessel
        if (fetchedCategory) {
            category = fetchedCategory.name;
        // Implies that vesselSOPCategories exists, so there is likely a selected vessel
        } else if (vesselSOPCategories) {
            category = renderCategoryName(selectedItem?.categoryId, vesselSOPCategories);
        } 
        return formatValue(category);
    }, [selectedItem?.categoryId, vesselSOPCategories, fetchedCategory]);

    const editButtons = (
        <RequirePermissions
            role="standardOperatingProcedures"
            level={permissionLevels.EDIT}
        >
            <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit SOP</SeaButton>
            <RequirePermissions
                role="standardOperatingProcedures"
                level={permissionLevels.FULL}
            >
                <div className="spacer-wide"></div>
                <SeaLinkButton mode="standard-link" onClick={(e) => selectedItem && onDeleteItem(selectedItem, true)}>Delete SOP</SeaLinkButton>
            </RequirePermissions>
        </RequirePermissions>
    );

    return (
        <SeaModal
            title={selectedItem?.title}
            showModal={showModal}
            setShowModal={setShowModal}
            size={selectedItem?.sfdoc ? 'rich-text' : 'wide'}
            fixTitleHeight={true}
            scrollEvents={true}
            onScroll={onScroll}
            onOpened={() => {
                setVisible(true);
            }}
            onClosed={() => {
                setVisible(false);
            }}
            setModalContentRef={setModalContentRef}
            level={level}
            viewOnly={viewOnly}
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="Category" w="50">
                    {categoryName}
                </SeaGridCell>
                <SeaGridCell label="Issue Date" w="50">
                    {formatDate(selectedItem?.dateIssued)}
                </SeaGridCell>
                <SeaLinks links={links} ids={selectedItem?.id ? [selectedItem.id] : []} viewOnly={viewOnly} level={level+1} width='50' />
            </SeaGrid>

            {!viewOnly ? <div className="view-modal-buttons">
                {editButtons}
            </div> : null}

            {selectedItem?.sfdoc &&
                <>
                    <div className="end-info-line"></div>
                    <SeaRichText
                        forModal={true}
                        visible={visible}
                        setOnScroll={setOnScroll}
                        richTextState={richTextState}
                        modalContentRef={modalContentRef}
                        editButtons={viewOnly ? undefined : editButtons}
                    />
                </>
            }
            {isPdf(selectedItem?.files?.[0]) ? (
                <div className="sea-fill-container">
                    <div className="end-info-line"></div>
                    <SeaLabel>PDF Preview</SeaLabel>
                    <SeaPDFViewer file={selectedItem?.files?.[0]} allowScroll /> 
                </div>
            ) : null}   

            {showModal && !viewOnly && vesselSOPCategories &&
                <EditSOP
                    showModal={showEditItemModal}
                    setShowModal={setShowEditItemModal}
                    itemToUpdate={selectedItem}
                    level={level+1}
                    vesselSopCategories={vesselSOPCategories}
                    existingLinks={links}
                />
            }
        </SeaModal>
    );
};

export default ViewSOP;
