import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import { Division, Divisions } from '../../shared-state/Core/divisions';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaModalBox from '../SeaModalBox/SeaModalBox';
import './SeaSelectDivision.css';

interface SeaSelectDivisionProps {
    zone?: 'blue' | 'grey' | 'white'
    label?: string,
    divisionId: string,
    setDivisionId: (divisionId: string) => void,
    divisions: Divisions | undefined,
    topLevelText?: string
    help?: SeaHelp
}

const SeaSelectDivision: React.FC<SeaSelectDivisionProps> = ({
    zone = 'white',
    label,
    divisionId,
    setDivisionId,
    divisions,
    topLevelText = 'None (Top Level)',
    help
}) => {
    const [showModal, setShowModal] = useState(false);
    const [isFolderOpen, setIsFolderOpen] = useState<{
        [divisionId: string]: boolean
    }>();

    const stopClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const toggleFolder = useCallback((e: any, divisionId: string) => {
        e.preventDefault();
        e.stopPropagation();
        setIsFolderOpen((current) => {
            return {
                ...current,
                [divisionId]: !(current?.[divisionId])
            }
        });
    }, []);

    const countOffspring = useCallback((division: Division) => {
        let count = 0;
        division.children?.forEach((child) => {
            count++;
            if (isFolderOpen && isFolderOpen[child.id]) {
                count += countOffspring(child);
            }
        });
        return count;
    }, [isFolderOpen]);

    // Default division folders to all being open
    useEffect(() => {
        const isOpen = {
            'root': true
        } as any;
        divisions?.all.forEach((division) => {
            if (division.children && division.children.length > 0) {
                isOpen[division.id] = true;
            }
        });
        setIsFolderOpen(isOpen);
    }, [divisions]);

    const divisionName = useMemo(() => {
        if (divisions && divisionId && divisionId !== 'root') {
            return divisions.byId[divisionId].name;
        }
        return topLevelText;
    }, [divisionId, divisions, topLevelText]);

    const selectDivision = useCallback((divisionId: string) => {
        setDivisionId(divisionId);
        setShowModal(false);
    }, [setDivisionId]);

    const renderDivisions = useCallback((division: Division) => {
        if (division.children && division.children.length > 0) {
            const isOpen = isFolderOpen?.[division.id] ? true : false;
            return (
                <React.Fragment key={division.id}>
                    <div
                        className="sea-division-option columns"
                        onClick={(e) => selectDivision(division.id)}
                    >
                        <div
                            className={`folder-icon pushy ${isOpen ? 'opened' : 'closed'}`}
                            onClick={(e) => toggleFolder(e, division.id)}
                        >
                            <SeaIcon
                                icon="folderOpened"
                                //marginTop={4}
                            />
                        </div>
                        <div className="name">
                            {division.name}
                        </div>
                    </div>
                    <div className="sea-division-parent" style={{ opacity: isOpen ? 1 : 0, maxHeight: isOpen ? (countOffspring(division) * 54)+'px' : '0px', pointerEvents: isOpen ? 'all' : 'none' }}>
                        {
                            division.children.map((child) => {
                                return renderDivisions(child);
                            })
                        }
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <div
                    key={division.id}
                    className="sea-division-option"
                    onClick={(e) => selectDivision(division.id)}
                >
                    <div className="name">
                        {division.name}
                    </div>
                </div>
            );
        }
    }, [countOffspring, isFolderOpen, selectDivision, toggleFolder]);

    if (!divisions) {
        return null;
    }

    return (<>
        {label && <div><SeaLabel zone={zone} help={help}>{label}</SeaLabel></div>}
        <div
            className={`sea-input sea-select multi ${zone}-zone`}
            onClick={() => setShowModal(true)}
        >
            <div className="select-text no-select">
                {divisionName}
            </div>
            <div className="select-icon">
                <div className="select-icon-inner"></div>
            </div>
        </div>
        {/* <SeaInputError alignLeft>{error}</SeaInputError> */}
        <SeaModalBox
            showModal={showModal}
            setShowModal={setShowModal}
            maxWidth={500}
            className="sea-divisions-modal"
        >
            <div className="sea-select-multi-title">
                Select Division
            </div>
            <div className="sea-division-options" onClick={stopClick}>
                {renderDivisions(divisions!.root)}
            </div>
        </SeaModalBox>
    </>);
};

export default SeaSelectDivision;
