import React, { useMemo } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import SeaMultiSelect from '../SeaMultiSelect/SeaMultiSelect';
import { renderVesselName } from '../../shared-state/Core/vessels';

interface SeaSelectVesselsWithoutDivisionsProps {
    zone?: 'blue' | 'grey' | 'white'
    label?: string,
    vesselIds: string[] | undefined,
    setVesselIds?: React.Dispatch<React.SetStateAction<string[] | undefined>>,
    onChanged?: (values: string[]) => void,
    emptyText?: string,
    help?: SeaHelp,
    required?: boolean,
    requiredError?: string,
    isSubmitting?: boolean, // Pass in formik's isSubmitting so we can tell if form has been submitted (count as touched)
    disabled?: boolean,
    modalTitle?: string,
    width?: string,
}

const SeaSelectVesselsWithoutDivisions: React.FC<SeaSelectVesselsWithoutDivisionsProps> = ({
    zone,
    label, // = 'Select Vessels',
    vesselIds,
    setVesselIds,
    onChanged,
    emptyText,
    help,
    required,
    requiredError,
    isSubmitting,
    disabled,
    modalTitle,
    width
}) => {
    const userVesselIds = sharedState.vesselIds.use();
    const vessels = sharedState.vessels.use();
    // const vesselId = sharedState.vesselId.use();

    const vesselOptions = useMemo(() => {
        if (userVesselIds && vessels?.byId) {
            const options: {id: string; name: string}[] = [];
            userVesselIds.forEach((vesselId: string) => {
                if (vessels.byId[vesselId]) {
                    options.push({
                        id: vesselId,
                        name: vessels.byId[vesselId].name
                    });
                }
            });
            options.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            return options;
        }
        return undefined;
    }, [userVesselIds, vessels]);

    const summaryText = useMemo(() => {
        if (vesselIds) {
            const names = vesselIds.map((vesselId) => {
                return renderVesselName(vesselId, vessels);
            });
            if (names.length === 1) {
                return names[0];
            }
            if (names.length === vessels?.totalVessels) {
                return 'All Vessels';
            }
            const s = names.join(', ');
            if (s.length > 36) {
                return `Vessels (${names.length}/${vessels?.totalVessels})`;
            }
            return s;
        }
        return emptyText;
    }, [emptyText, vesselIds, vessels]);

    return (
        <SeaMultiSelect
            mode="popover"
            zone={zone}
            label={label}
            values={vesselIds}
            setValues={setVesselIds}
            onChanged={onChanged}
            options={vesselOptions}
            useAllOption={vesselOptions && vesselOptions.length > 1}
            emptyText={emptyText}
            help={help}
            required={required}
            requiredError={requiredError}
            isSubmitting={isSubmitting}
            disabled={disabled}
            modalTitle={modalTitle}
            width={width}
            summaryText={summaryText}
        />
    );
};

export default SeaSelectVesselsWithoutDivisions;
