import React from 'react';
import { sharedState } from '../../shared-state/shared-state';
import SeaSelectVesselWithoutDivisions from './SeaSelectVesselWithoutDivisions';
import SeaSelectVesselUsingDivisions from './SeaSelectVesselUsingDivisions';
import './SeaSelectVessel.css';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';

interface SeaSelectVesselProps {
    label?: string,
    vesselId?: string,
    setVesselId?: React.Dispatch<React.SetStateAction<string | undefined>>,
    onChange?: (vesselId: string) => void,
    emptyText?: string,
    width?: string
    zone?: 'blue' | 'white' | 'grey',
    disabled?: boolean,
    help?: SeaHelp,
    required?: boolean,
    requiredError?: string,
    isSubmitting?: boolean, // Pass in formik's isSubmitting so we can tell if form has been submitted (count as touched)
}

const SeaSelectVessel: React.FC<SeaSelectVesselProps> = ({
    label,
    vesselId,
    setVesselId,
    onChange,
    emptyText,
    width,
    zone = 'grey',
    disabled,
    help,
    required,
    requiredError,
    isSubmitting
}) => {
    const divisions = sharedState.divisions.use();

    if (divisions?.root) {
        return (
            <SeaSelectVesselUsingDivisions
                label={label}
                divisions={divisions}
                vesselId={vesselId}
                setVesselId={setVesselId}
                onChange={onChange}
                width={width}
                zone={zone}
                emptyText={emptyText}
                disabled={disabled}
                help={help}
                required={required}
                requiredError={requiredError}
                isSubmitting={isSubmitting}
            />
        );
    }

    return (
        <SeaSelectVesselWithoutDivisions
            label={label}
            vesselId={vesselId}
            setVesselId={setVesselId}
            onChange={onChange}
            width={width}
            zone={zone}
            emptyText={emptyText}
            disabled={disabled}
            help={help}
            required={required}
            requiredError={requiredError}
            isSubmitting={isSubmitting}
        />
    )
};

export default SeaSelectVessel;
