import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from 'firebase/firestore';
import { haveValuesChanged, toMillis, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate, formatShortTimeDurationHrsMinsOnly, hours24ToMillis, addInterval } from '../../../../lib/datesAndTime';
import { makeCategoryId, renderCategoryName } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { SafetyCheckItem } from '../../../../shared-state/VesselSafety/safetyCheckItems';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { LinkType } from '../../../../shared-state/Core/links';
import { handleLinkUpdates } from '../../../../lib/links';
import { useItemLinks } from '../../../../shared-state/Core/useItemLinks';
import Yup, { notTooOld } from '../../../../lib/yup';
import SeaMultiSelect, { OptionType } from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaTime from '../../../../components/SeaTime/SeaTime';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaLinkMultiList from '../../../../components/SeaLinkMultiList/SeaLinkMultiList';

interface EditSafetyCheckProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    itemToUpdate?: SafetyCheckItem;
    level?: number;
}

const EditSafetyCheck: React.FC<EditSafetyCheckProps> = ({ showModal, setShowModal, itemToUpdate, level = 1 }) => {
    const userId = sharedState.userId.use(showModal);
    const users = sharedState.users.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const vesselSafetyCheckCategories = sharedState.safetyCheckCategories.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [assignedTo, setAssignedTo] = useState<string[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const existingLinks = useItemLinks(itemToUpdate?.id);
    const [links, setLinks] = useState<LinkType[]>([]);
    const licenseeSettings = sharedState.licenseeSettings.use();

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                itemId: itemToUpdate.itemId ? '' + itemToUpdate.itemId : '',
                locationId: itemToUpdate.locationId ? '' + itemToUpdate.locationId : '',
                description: itemToUpdate.description ? '' + itemToUpdate.description : '',
                interval: itemToUpdate.interval ? '' + itemToUpdate.interval : '',
                whenLastChecked: itemToUpdate.whenLastChecked ? formatSeaDate(itemToUpdate.whenLastChecked) : '',
                isCritical: vesselSafetyItems?.byId[itemToUpdate.itemId]?.isCritical ? true : false,
                categoryId: itemToUpdate.categoryId as string,
                assignedTo: itemToUpdate.assignedTo ?? [],
                estimatedTime: itemToUpdate.estimatedTime ? formatShortTimeDurationHrsMinsOnly(itemToUpdate.estimatedTime) : '',
            };
        } else {
            return {
                itemId: '',
                locationId: '',
                description: '',
                interval: '',
                whenLastChecked: formatSeaDate(),
                isCritical: false,
                categoryId: vesselSafetyCheckCategories?.ids.find((id) => vesselSafetyCheckCategories?.byId[id].name === 'General') ?? '',
                assignedTo: [],
                estimatedTime: formatShortTimeDurationHrsMinsOnly(0),
            };
        }
    }, [itemToUpdate, vesselSafetyCheckCategories, vesselSafetyItems?.byId]);

    const assignedToOptions = useMemo(() => {
        const _options: OptionType[] = [];
        if (users && vesselId) {
            users?.byVesselId[vesselId]?.forEach((user) => {
                if (user.id) {
                    _options.push({
                        id: user.id,
                        name: renderFullNameForUserId(user.id),
                    });
                }
            });
        }
        return _options;
    }, [users, vesselId]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setAssignedTo([...(itemToUpdate?.assignedTo ?? [])]);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            itemId: Yup.string().max(500).required(),
            locationId: Yup.string().max(500),
            description: Yup.string().max(5000),
            interval: Yup.string().max(4).required(),
            whenLastChecked: Yup.date()
                .max(formatSeaDate())
                .required()
                .min(...notTooOld),
            categoryId: Yup.string().required(),
        }),
        onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('safetyCheck')) {
                return;
            }
            if (!vesselId) {
                reportError('No vesselId');
                return;
            }
            // Attempt upload first.... ?
            uploadFiles(files)
                .then(() => {
                    // Process form
                    const batch = splittableBatch(firestore, 20 - 0);
                    const batchTrace = makeBatchTrace(batch, 'safetyCheckItems');
                    let itemId: string;

                    if (itemToUpdate) {
                        batchTrace.exampleOperation = 'update';
                        batchTrace.exampleDocId = itemToUpdate.id;
                        itemId = itemToUpdate.id;
                        batch.set(
                            doc(firestore, 'safetyCheckItems', itemToUpdate.id),
                            {
                                updatedBy: userId,
                                whenUpdated: batchTrace.whenAction,
                                itemId: makeCategoryId(data.itemId, vesselSafetyItems, deleteValue, batch, 'vesselSafetyItems', 'vesselId', vesselId, {
                                    isCritical: data.isCritical ? true : false,
                                }),
                                locationId: makeCategoryId(data.locationId, vesselLocations, deleteValue, batch, 'vesselLocations', 'vesselId', vesselId, {}),
                                categoryId: makeCategoryId(data.categoryId, vesselSafetyCheckCategories, deleteValue, batch, 'safetyCheckCategories', 'vesselId', vesselId, {}),
                                description: data.description ? data.description : deleteValue,
                                interval: data.interval,
                                hasFault: false,
                                whenLastChecked: toMillis(data.whenLastChecked),
                                dateDue: addInterval(data.whenLastChecked, data.interval).toISODate(),
                                assignedTo: assignedTo,
                                files: seaFilesToValue(files),
                                touched: serverTimestamp(),
                                estimatedTime: hours24ToMillis(data.estimatedTime),
                            },
                            { merge: true }
                        );

                        saveFileRefs(batch, files, 'safetyCheckItems', itemToUpdate.id);
                        logAction(batch, 'Update', 'safetyCheckItems', itemToUpdate.id, renderCategoryName(data.itemId, vesselSafetyItems), [vesselId]);
                    } else {
                        const newRef = doc(collection(firestore, 'safetyCheckItems'));
                        batchTrace.exampleOperation = 'create';
                        batchTrace.exampleDocId = newRef.id;
                        itemId = newRef.id;
                        batch.set(newRef, {
                            vesselId: vesselId,
                            addedBy: userId,
                            itemId: makeCategoryId(data.itemId, vesselSafetyItems, undefined, batch, 'vesselSafetyItems', 'vesselId', vesselId, {
                                isCritical: data.isCritical ? true : false,
                            }),
                            locationId: makeCategoryId(data.locationId, vesselLocations, undefined, batch, 'vesselLocations', 'vesselId', vesselId, {}),
                            categoryId: makeCategoryId(data.categoryId, vesselSafetyCheckCategories, undefined, batch, 'safetyCheckCategories', 'vesselId', vesselId, {}),
                            description: data.description ? data.description : undefined,
                            interval: data.interval,
                            hasFault: false,
                            whenLastChecked: toMillis(data.whenLastChecked),
                            dateDue: addInterval(data.whenLastChecked, data.interval).toISODate(),
                            whenAdded: batchTrace.whenAction,
                            assignedTo: assignedTo,
                            state: 'active',
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                            estimatedTime: hours24ToMillis(data.estimatedTime),
                        });

                        saveFileRefs(batch, files, 'safetyCheckItems', newRef.id);
                        logAction(batch, 'Add', 'safetyCheckItems', newRef.id, renderCategoryName(data.itemId, vesselSafetyItems), [vesselId]);
                    }

                    handleLinkUpdates(batch, links, existingLinks, itemId, 'safetyCheckItems');

                    onCollectionUpdated(batch, 'safetyCheckItems');

                    batchTrace.data = {
                        data,
                        files: seaFilesToValue(files),
                        itemToUpdate,
                    };
                    batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} safety check ${renderCategoryName(data.itemId, vesselSafetyItems)}`);
                    batch
                        .commit()
                        .then(() => {
                            batchTrace.reportSuccess();
                        })
                        .catch((error) => {
                            batchTrace.reportError(error.message, error);
                        });

                    setShowModal(false);
                })
                .catch((error: any) => {
                    if (!handleUploadError(error)) {
                        reportError(`Failed to upload safety check files`, error.message, error, {
                            data,
                            files: seaFilesToValue(files),
                            itemToUpdate,
                        });
                    }
                });
        },
    });

    const onClosed = () => {
        setAssignedTo([]);
        setFieldValue('itemId', '');
        setFieldValue('locationId', '');
        setHasSubmitted(false);
    };

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues) || haveFilesChanged(files, itemToUpdate?.files) || haveValuesChanged(assignedTo, itemToUpdate?.assignedTo ?? []);
    }, [values, initialValues, files, itemToUpdate?.files, itemToUpdate?.assignedTo, assignedTo]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal title={itemToUpdate ? 'Edit Safety Check' : 'Add Safety Check'} showModal={showModal} setShowModal={setShowModal} isDirty={isModalDirty} onOpened={onOpened} onClosed={onClosed} level={level} size="wide">
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselSafetyItems}
                                label="Item"
                                name="itemId"
                                initialCategoryId={initialValues.itemId}
                                categoryId={values.itemId}
                                otherPlaceholder="Add New Item"
                                onchange={(e) => {
                                    handleChange(e);
                                }}
                                onblur={handleBlur}
                                error={touched.itemId ? errors.itemId : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselLocations}
                                label="Location"
                                name="locationId"
                                initialCategoryId={initialValues.locationId}
                                categoryId={values.locationId}
                                otherPlaceholder="Add New Location"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.locationId ? errors.locationId : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselSafetyCheckCategories}
                                label="Category"
                                name="categoryId"
                                initialCategoryId={initialValues.categoryId}
                                categoryId={values.categoryId}
                                otherPlaceholder="Add New Location"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.categoryId ? errors.categoryId : ''}
                            />
                        </IonCol>
                        <IonCol size="6" style={{ paddingRight: '4px' }}>
                            <SeaCheckbox
                                mode="beside-input"
                                label="Critical Equipment"
                                name="isCritical"
                                checked={values.isCritical ? true : false}
                                setFieldValue={setFieldValue}
                                help={{ text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew.' }}
                            >
                                This equipment is critical
                            </SeaCheckbox>
                        </IonCol>

                        <IonCol size="12">
                            <SeaTextarea label="Description" name="description" value={values.description} onchange={handleChange} onblur={handleBlur} zone="white" inputmode="text" error={touched.description ? errors.description : ''} />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectInterval label="Interval" name="interval" value={values.interval} onchange={handleChange} onblur={handleBlur} error={touched.interval ? errors.interval : ''} />
                        </IonCol>
                        <IonCol size="6">
                            <SeaDate
                                disabled={itemToUpdate ? true : false}
                                label="Last Check"
                                name="whenLastChecked"
                                value={values.whenLastChecked}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenLastChecked ? errors.whenLastChecked : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaMultiSelect
                                label="Assigned To"
                                // name="assignedTo"
                                values={assignedTo}
                                setValues={(ids) => setAssignedTo(ids)}
                                options={assignedToOptions}
                                mode="popover"
                                useAllOption
                                isSubmitting={isSubmitting}
                            />
                        </IonCol>
                        {licenseeSettings?.hasSafetyCheckTaskTime && (
                            <IonCol size="6">
                                <SeaTime
                                    label="Estimated Time"
                                    help={{ text: 'This is an estimate of how long this task will take.' }}
                                    name="estimatedTime"
                                    value={values.estimatedTime}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    error={touched.estimatedTime ? errors.estimatedTime : ''}
                                />
                            </IonCol>
                        )}
                        <IonCol size="12">
                            <SeaFileUpload label="Images / Documents" files={files} setFiles={setFiles} collection="safetyCheckItems" field="files" />
                        </IonCol>

                        <IonCol size="12">
                            <SeaLinkMultiList
                                selectedCollection="safetyCheckItems"
                                selectedItemId={itemToUpdate?.id || ''}
                                values={links}
                                setValues={setLinks}
                                linkOptions={['customForms', 'external']}
                                deleteable
                                confirmDelete
                                level={level + 1}
                                showVesselNameInCategory
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="grid-row-spacer"></div>
                <SeaFormHasErrors hasSubmitted={hasSubmitted} isValid={isValid} />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">
                        {itemToUpdate ? 'Update Safety Check' : 'Save New Safety Check'}
                    </SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditSafetyCheck;
