import React, { useState, useEffect, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { formatValue } from '../../../lib/util';
import { formatDateShort, formatInterval, warnDays, dateDueToClassName } from '../../../lib/datesAndTime';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { renderCategoryName } from '../../../lib/categories';
import { CompanyDocument } from '../../../shared-state/CompanyDocuments/companyDocuments';
import { sharedState } from '../../../shared-state/shared-state';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import EditCompanyDocument from '../../../modals/CompanyDocuments/CompanyDocuments/EditCompanyDocument/EditCompanyDocument';
import ViewCompanyDocument from '../../../modals/CompanyDocuments/CompanyDocuments/ViewCompanyDocument/ViewCompanyDocument';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import EditCompanyDocumentSettings from '../../../modals/CompanyDocuments/CompanyDocuments/EditCompanyDocumentSettings/EditCompanyDocumentSettings';
import './CompanyDocumentsPage.css';

interface CompanyDocumentsPageProps {
    visible: boolean
}

const CompanyDocumentsPage: React.FC<CompanyDocumentsPageProps> = ({visible}) => {
    const companyDocuments = sharedState.companyDocuments.use(visible ? 1 : 101);
    const companyDocumentCategories = sharedState.companyDocumentCategories.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<CompanyDocument>();
    const [showEditCompanyDocumentSettings, setShowEditCompanyDocumentSettings] = useState(false);

    useEffect(() => {
        if (visible) {
            logPageView('CompanyDocuments/CompanyDocuments');
        }
    }, [visible]);

    useEffect(() => {
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && companyDocuments?.byId[selectedItem.id]) { 
            setSelectedItem(companyDocuments.byId[selectedItem.id])
        };
    }, [companyDocuments, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: CompanyDocument) => {
        console.log(`Viewing companyDocument ${item?.id}`);
        setShowViewItemModal(true);
        setSelectedItem(item)
    };

    const onSettings = () => {
        setShowEditCompanyDocumentSettings(true);
    };

    return (
        <RequirePermissions
            role="companyDocuments"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="vessel-documents page-head">
                <div><h2>Company Documents</h2></div>
                <div className="actions">
                    <RequirePermissions
                        role="companyDocuments"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Add New
                        </SeaButton>
                        <RequirePermissions
                            role="companyDocuments"
                            level={permissionLevels.EDIT}
                        >
                            <div className="spacer"></div>
                            <SeaButton zone="grey" shape="circle" onClick={(e) => onSettings()}>
                                <SeaIcon slot="icon-only" icon="settings"/>
                            </SeaButton>
                        </RequirePermissions>
                    </RequirePermissions>
                </div>
            </div>

            <SeaNoData
                dataName="company documents"
                isLoading={!companyDocuments}
                hasNoData={companyDocuments && companyDocuments.all.length === 0}
                isUsingFilter={false}
            />

            <div className={`vessel-documents has-thumbs has-status ${((companyDocuments?.all?.length || 0) > 0) ? 'reveal' : 'conceal' }`
            }>
                <div className="sea-row headings">
                    <div></div>
                    <div>Name</div>
                    <div>Expiry</div>
                    <div>Interval</div>
                    <div>Status</div>
                    <div>Reminder</div>
                </div>
                <Profiler id="documents.documents" onRender={onProfilerRender}>
                {companyDocuments && companyDocumentCategories?.ids?.map((categoryId: string) => {
                    if (isLimitReached() || companyDocuments.byCategoryId[categoryId] === undefined) {
                        return undefined;
                    }
                    return (
                        <React.Fragment key={categoryId}>
                            <div className="category-heading">{renderCategoryName(categoryId, companyDocumentCategories)}</div>
                            {/* All documents */}
                            {mapArrayWithLimit(companyDocuments?.byCategoryId[categoryId], (item) => {
                                return (
                                    <div
                                        key={item.id}
                                        className={`sea-card sea-row ${item.type === 'renewable' ? dateDueToClassName(item.dateExpires, warnDays.companyDocuments[0]) : 'non-expiring'}`}
                                        onClick={(e) => onViewItem(item)}
                                    >
                                        <div>
                                            <SeaFileImage
                                                files={item.files}
                                                sfdoc={item.sfdoc}
                                                size="tiny"
                                            />
                                        </div>
                                        <div className="bold truncate-2">{item.title}</div>
                                        {
                                            (item.type === 'renewable') &&
                                            <>
                                                <div className="truncate-2">{formatValue(formatDateShort(item.dateExpires))}</div>
                                                <div className="truncate-2">{formatValue(formatInterval(item.interval))}</div>
                                                <div><SeaStatusDueDate dateDue={item.dateExpires} warnDays={warnDays.companyDocuments[0]}/></div>
                                                <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                            </>
                                        }
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
                {visible && limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    <EditCompanyDocument
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                    />
                    <EditCompanyDocumentSettings
                        showModal={showEditCompanyDocumentSettings} 
                        setShowModal={setShowEditCompanyDocumentSettings}
                    />
                    {selectedItem &&  <ViewCompanyDocument
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                </>
            }
        </RequirePermissions>
    );
};

export default CompanyDocumentsPage;
