import React, { useState, useEffect, useMemo } from "react";
import { logPageView } from "../../../lib/firebase";
import { usePageLimiter } from "../../../hooks/usePageLimiter";
import { formatValue, extractSearchTerms } from "../../../lib/util";
import { formatDatetime } from '../../../lib/datesAndTime';
import { permissionLevels } from "../../../shared-state/Core/userPermissions";
import { incidentTypes, renderIncidentState } from "./incidents-util";
import { sharedState } from '../../../shared-state/shared-state';
import { Incident } from '../../../shared-state/HealthSafety/incidents';
import SeaIcon from "../../../components/SeaIcon/SeaIcon";
import SeaButton from "../../../components/SeaButton/SeaButton";
import SeaNoData from "../../../components/SeaNoData/SeaNoData";
import SeaFileImage from "../../../components/SeaFileImage/SeaFileImage";
import RequirePermissions from "../../../components/RequirePermissions/RequirePermissions";
import SeaSearchbar from "../../../components/SeaSearchbar/SeaSearchbar";
import EditIncidentsSettings from "../../../modals/HealthSafety/Incidents/EditIncidentsSettings/EditIncidentsSettings";
import EditIncident from "../../../modals/HealthSafety/Incidents/EditIncident/EditIncident";
import ViewIncident from "../../../modals/HealthSafety/Incidents/ViewIncident/ViewIncident";
import IncidentEventReportsPdf from "../../../exports/HealthSafety/Incidents/IncidentEventReportsPdf";
import RequireLicenseeSettings from '../../../components/RequireLicenseeSettings/RequireLicenseeSettings';
import IncidentEventReportsCsv from '../../../exports/HealthSafety/Incidents/IncidentEventReportsCsv';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import SeaSelectVessels from "../../../components/SeaSelectVessels/SeaSelectVessels";
import "./Incidents.css";

interface IncidentsProps {
    visible: boolean;
    filterVesselIds: string[] | undefined;
    setFilterVesselIds: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

const Incidents: React.FC<IncidentsProps> = ({ visible, filterVesselIds, setFilterVesselIds }) => {
    const incidents = sharedState.incidents.use(visible ? 1 : 101);
    const vessels = sharedState.vessels.use(visible ? 1 : 101);
    const vesselId = sharedState.vesselId.use(visible ? 1 : 101);
    sharedState.incidentCategories.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.incidentCauses.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.injuryTypes.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.injuryLocations.use(visible ? 51 : 0); // Prepare for modals to access
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [searchText, setSearchText] = useState('');
    const [showSettings, setShowSettings] = useState(false);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Incident>();
    const [exportType, setExportType] = useState<ExportType>();
    //const [medicalReports, setMedicalReports] = useState<MedicalReport>();

    
    useEffect(() => {
        if (visible) {
            logPageView("HealthSafety/Incidents");
        }
    }, [visible]);
    
    useEffect(() => {
        resetPageLimit();
    }, [visible, searchText, filterVesselIds, resetPageLimit]); // eslint-disable-line react-hooks/exhaustive-deps
    
    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && incidents?.byId[selectedItem.id]) {
            setSelectedItem(incidents.byId[selectedItem.id]);
        }
    }, [incidents, selectedItem?.id]);

    const onAddNewItem = () => {
        setSelectedItem(undefined);
        setShowEditItemModal(true);
    };
    const onViewItem = (item: Incident) => {
        console.log(`Viewing incident ${item?.id}`);
        setShowViewItemModal(true);
        setSelectedItem(item);
    };
    const onEditSettings = () => {
        setShowSettings(true);
    };

    const filteredIncidents = useMemo(() => {
        let _incidents: Incident[] = [];
        let _filteredIncidents: Incident[] = [];
        const terms = extractSearchTerms(searchText, true);
        if (filterVesselIds) {
            const hasIncident = {} as {[id: string]: true};
            filterVesselIds.forEach((vesselId) => {
                incidents?.byVesselId[vesselId]?.forEach((incident) => {
                    if (!hasIncident[incident.id]) {
                        hasIncident[incident.id] = true;
                        _incidents.push(incident);
                    }
                });
            });
            if (filterVesselIds.length > 1) {
                _incidents.sort((a, b) => {
                    return b.whenEvent - a.whenEvent;
                });
            }
        } else {
            _incidents = incidents?.all || [];
        }
        if (_incidents) {
            for (let i = 0; i < _incidents.length; i++) {
                const incident = _incidents[i];
                if (terms.length > 0) {
                    let isMatch = true;
                    for (let j = 0; j < terms.length; j++) {
                        if (incident.searchText?.indexOf(terms[j]) === -1) {
                            isMatch = false;
                            break;
                        }
                    }
                    if (!isMatch) {
                        continue;
                    }
                }
                _filteredIncidents.push(incident);
            }
        }
        return _filteredIncidents;
    }, [searchText, filterVesselIds, incidents]);

    // TODO: stats to be upgraded (V2)
    // const stats = useMemo(() => {
    //     const o = {
    //         incident: 0,
    //         accident: 0,
    //         medical: 0,
    //         nearMiss: 0
    //     };
    //     if (filteredMedicalReports) {
    //         filteredMedicalReports.forEach((report) => {
    //             if (report.type) {
    //                 o[report.type]++;
    //             }
    //         });
    //     }
    //     return o;
    // }, [filteredMedicalReports]);
    

    return (
        <RequireLicenseeSettings role="hasIncidents" showDenial>
            <div className="incidents page-head">
                <div>
                    <h2>Incident / Event Reports</h2>
                </div>
                <div className="actions">
                    <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                        <SeaIcon slot="start" icon="add" />
                        Report Incident / Event
                    </SeaButton>
                    <SeaExporter
                        setExportType={setExportType}
                        csv={exportType === "csv" && <IncidentEventReportsCsv onCompleted={() => setExportType(undefined)} />} 
                        pdf={exportType === "pdf" && (
                            <IncidentEventReportsPdf 
                                onCompleted={() => setExportType(undefined)}
                                vesselIds={filterVesselIds}
                                incidents={filteredIncidents}
                            />
                        )}
                    />
                    <RequirePermissions
                        role="incidentAccidentMedicalRegister"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton
                            zone="grey"
                            shape="circle"
                            onClick={(e) => onEditSettings()}
                        >
                            <SeaIcon slot="icon-only" icon="settings" />
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            {/* Filters */}
            <div className="columns wrap filters">
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
                {vessels && vessels.all.length > 1 &&
                    <div>
                        <SeaSelectVessels
                            vesselIds={filterVesselIds}
                            setVesselIds={setFilterVesselIds}
                            zone="grey"
                            width="300px"
                            emptyText="None"
                        />
                    </div>
                }
            </div>

            <SeaNoData
                dataName="incident / event reports"
                isLoading={!incidents}
                hasNoData={filteredIncidents && filteredIncidents.length === 0}
                isUsingFilter={true}
            />

            <div
                className={`incidents has-thumbs has-status ${
                    (filteredIncidents && filteredIncidents.length === 1) ? "hide-vessel" : "show-vessel"
                } ${filteredIncidents?.length > 0 ? "reveal" : "conceal"}`}
            >
                <div className="sea-row headings">
                    <div></div>
                    <div>Name</div>
                    <div>Vessel</div>
                    <div>Report #</div>
                    <div>Date</div>
                    <div>Type</div>
                    <div>Status</div>
                </div>
                {mapArrayWithLimit(filteredIncidents, (incident) => {
                    return (
                        <div
                            key={incident.id}
                            className={`sea-card sea-row${
                                incident?.state === "draft" ? " draft" : ""
                            }`}
                            onClick={(e) => onViewItem(incident)}
                        >
                            <div>
                                <SeaFileImage
                                    files={incident.files}
                                    size="tiny"
                                />
                            </div>
                            <div className="bold truncate-2">
                                {formatValue(incident.name)}
                            </div>
                            <div className="truncate-2">
                                {vessels?.byId[incident.vesselId]?.name}
                            </div>
                            <div className="truncate-2">
                                {formatValue(incident.reportNum)}
                            </div>
                            <div className="truncate-2">
                                {formatDatetime(incident.whenEvent)}
                            </div>
                            <div className="truncate-2">
                                {formatValue(incidentTypes[incident.type])}
                            </div>
                            <div>{renderIncidentState(incident.state)}</div>
                        </div>
                    );
                })}
                {visible && filteredIncidents && limitTriggerElement}
            </div>
            {visible &&
                <>
                    <EditIncidentsSettings
                        showModal={showSettings}
                        setShowModal={setShowSettings}
                    />
                    <EditIncident
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        defaultVesselId={(filterVesselIds && filterVesselIds.length === 1) ? filterVesselIds[0] : vesselId}
                    />
                    {selectedItem && <ViewIncident
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                </>
            }
        </RequireLicenseeSettings>
    );
};

export default Incidents;
