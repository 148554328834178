import React, { Profiler, useEffect, useMemo, useState } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { renderFullName } from '../../../shared-state/Core/users';
import { UserType } from '../../../shared-state/Core/user';
import { sharedState } from '../../../shared-state/shared-state';
import { Drill } from '../../../shared-state/VesselSafety/drills';
import { getDayOffsetMillis, makeDateTime, warnDays } from '../../../lib/datesAndTime';
import { DrillReportsData } from '../../../shared-state/VesselSafety/drillReports';
import { VesselDrillsData } from '../../../shared-state/VesselSafety/vesselDrills';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import EditDrill from '../../../modals/VesselSafety/Drills/EditDrill/EditDrill';
import EditDrillReport from '../../../modals/VesselSafety/Drills/EditDrillReport/EditDrillReport';
import ViewDrill from '../../../modals/VesselSafety/Drills/ViewDrill/ViewDrill';
import ViewDrillHistory from '../../../modals/VesselSafety/Drills/ViewDrillHistory/ViewDrillHistory';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import ViewDrillUser from '../../../modals/VesselSafety/Drills/ViewDrillUser/ViewDrillUser';
import SafetyDrillReportsCsv from '../../../exports/VesselSafety/SafetyDrillReportsCsv';
import ViewUserDrills from '../../../modals/VesselSafety/Drills/ViewUserDrills/ViewUserDrills';
import './DrillsV2.css';

interface DrillsProps {
    visible: boolean;
}

/* Migrates the drill to support the `crew` and `notAssignedTo` props */
const hydrateDrills = (vesselDrills?: VesselDrillsData, drillReports?: DrillReportsData, drillUsers?: UserType[]): Drill[] => {
    if (!vesselDrills || !drillReports || !drillUsers) return [];

    return vesselDrills.all.map((drill) => {
        const crew = drillUsers.reduce((result: any, user) => {
            if (!user.id) return result; // Skip if we don't have a user ID

            if (!!drill.crew && drill.crew[user.id]) {
                // If the user already exists under the new data system, use that
                return {
                    ...result,
                    [user.id]: drill.crew[user.id],
                };
            }

            const key = `${drill.id}${user.id}`;
            const lastDrill = drillReports.byDrillAndUser[key];

            if (!lastDrill?.dateDue || !lastDrill?.report?.dateCompleted) return result;

            return {
                ...result,
                [user.id]: {
                    dateDue: lastDrill.dateDue,
                    dateLastCompleted: lastDrill.report.dateCompleted,
                },
            };
        }, {} as Record<string, Record<string, number>>);

        return { ...drill, crew } as Drill;
    });
};

const MissingDrill = ({
    drill,
    onDrillClick,
    onDrillNameClick,
}: {
    drill: Drill;
    onDrillClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onDrillNameClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
    return (
        <div className={'slot'}>
            <div className={'drill-name-mobile'} onClick={onDrillNameClick}>
                {drill.name}
            </div>
            <div className={`pushy due missing`} onClick={onDrillClick}>
                <div className={'missing-text'}>MISSING</div>
            </div>
        </div>
    );
};

const UnassignedDrill = ({
    drill,
    onDrillClick,
    onDrillNameClick,
}: {
    drill: Drill;
    onDrillClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onDrillNameClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
    return (
        <div className={'slot'} onClick={onDrillClick}>
            <div className={'drill-name-mobile'} onClick={onDrillNameClick}>
                {drill.name}
            </div>
            <div className={`pushy due unassigned`}>
                <div className={'unassigned-text'}>UNASSIGNED</div>
            </div>
        </div>
    );
};

const AssignedDrill = ({
    drill,
    dateDueDiff,
    onDrillClick,
    onDrillNameClick,
}: {
    drill: Drill;
    dateDueDiff: number;
    onDrillClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onDrillNameClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
    const isDrillOverdue = dateDueDiff < 0;
    const isDrillDueSoon = dateDueDiff > 0 && dateDueDiff < warnDays.drills[0];

    const periodText = Math.abs(dateDueDiff) === 1 ? 'DAY' : 'DAYS';

    return (
        <div className={'slot'} onClick={onDrillClick}>
            <div className={'drill-name-mobile'} onClick={onDrillNameClick}>
                {drill.name}
            </div>
            <div className={`pushy due ${isDrillOverdue ? 'od' : isDrillDueSoon ? 'soon' : 'ok'}`}>
                {Math.abs(dateDueDiff)}
                <div>
                    {periodText}
                    {dateDueDiff < 0 ? ' OD' : ''}
                </div>
            </div>
        </div>
    );
};

const DrillsV2: React.FC<DrillsProps> = ({ visible }) => {
    const vesselId = sharedState.vesselId.use()!;
    const users = sharedState.users.use(); // TODO - Permissions on this?
    const vesselDrills = sharedState.vesselDrills.use(visible ? 1 : 101);
    const drillReports = sharedState.drillReports.use(visible ? 1 : 101);
    const todayMillis = sharedState.todayMillis.use();

    const [showEditDrillModal, setShowEditDrillModal] = useState(false);
    const [showViewDrillModal, setShowViewDrillModal] = useState(false);
    const [showViewDrillHistoryModal, setShowViewDrillHistoryModal] = useState(false);
    const [selectedDrill, setSelectedDrill] = useState<Drill>();
    const [exportType, setExportType] = useState<ExportType>();
    const [showViewDrillUserModal, setShowViewDrillUserModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserType>();
    const [showEditDrillReportModal, setShowEditDrillReportModal] = useState(false);
    const [showViewUserDrillsModal, setShowViewUserDrillsModal] = useState(false);

    // Used for showing the dropdown view on mobile
    const [expandedUsers, setExpandedUsers] = useState<{
        [key: string]: boolean;
    }>({});

    const drillUsers = useMemo(() => {
        if (!vesselId || !users?.byVesselId) return [];

        return users?.byVesselId[vesselId]
            .filter((user) => user.isStaff) // We don't want to show non-staff users
            .filter((user) => user.crewVesselIds === undefined || user.crewVesselIds.includes(vesselId)); // If list is undefined, just treat the user as crew (safe default)
    }, [vesselId, users]);

    const newDrillsModel = useMemo(
        () => hydrateDrills(vesselDrills, drillReports, drillUsers),
        [vesselDrills, drillReports, drillUsers]
    );

    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();

    useEffect(() => {
        if (visible) {
            logPageView('VesselSafety/Drills');
        }
    }, [visible]);

    useEffect(() => {
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedDrill fresh
    useEffect(() => {
        if (selectedDrill?.id && vesselDrills?.byId[selectedDrill.id]) {
            setSelectedDrill(vesselDrills.byId[selectedDrill.id]);
        }
    }, [vesselDrills, selectedDrill?.id]);

    // Event Handlers
    const onAddNewDrill = () => {
        setShowEditDrillModal(true);
    };
    const onAddNewDrillReport = () => {
        setShowEditDrillReportModal(true);
    };
    const onViewDrillHistory = () => {
        setShowViewDrillHistoryModal(true);
    };
    const onViewDrill = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, drill: Drill) => {
        e.stopPropagation();
        console.log('Viewing drill', drill?.id);
        setShowViewDrillModal(true);
        setSelectedDrill(drill);
    };
    const onViewDrillUser = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, drill: Drill, user: UserType) => {
        e.stopPropagation();
        setSelectedDrill(drill);
        setSelectedUser(user);
        setShowViewDrillUserModal(true);
    };
    const onViewUserDrills = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, user: UserType) => {
        e.stopPropagation();
        setSelectedUser(user);
        setShowViewUserDrillsModal(true);
    };
    const onToggleUser = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, taskId?: string) => {
        if (!taskId) return;

        e.stopPropagation();
        const o = { ...expandedUsers };
        if (expandedUsers[taskId]) {
            delete o[taskId];
            setExpandedUsers(o);
        } else {
            o[taskId] = true;
            setExpandedUsers(o);
        }
    };

    const renderDrillElements = (user: UserType) => {
        const drillElements = [];
        for (let i = 0; i < newDrillsModel.length; i++) {
            const drill = newDrillsModel[i];
            // Iterate through drills for each vessel
            const today = todayMillis ?? getDayOffsetMillis(0);
            const crewData = drill.crew && user.id ? drill?.crew[user.id] : { dateDue: undefined, dateLastCompleted: undefined };

            const isDrillUnassigned = (!!user.id && drill.notAssignedTo?.includes(user.id)) ?? false;

            if (isDrillUnassigned) {
                drillElements.push(
                    <UnassignedDrill
                        drill={drill}
                        key={drill.id}
                        onDrillClick={(e) => onViewDrillUser(e, drill, user)}
                        onDrillNameClick={(e) => onViewDrill(e, drill)}
                    />
                );
                continue;
            }

            if (!crewData?.dateDue || !crewData.dateLastCompleted) {
                drillElements.push(
                    <MissingDrill
                        drill={drill}
                        key={drill.id}
                        onDrillClick={(e) => onViewDrillUser(e, drill, user)}
                        onDrillNameClick={(e) => onViewDrill(e, drill)}
                    />
                );
                continue;
            }

            const dateDueDiff = Math.round(makeDateTime(crewData.dateDue).diff(makeDateTime(today), ['days']).days);

            drillElements.push(
                <AssignedDrill
                    drill={drill}
                    key={drill.id}
                    dateDueDiff={dateDueDiff}
                    onDrillClick={(e) => onViewDrillUser(e, drill, user)}
                    onDrillNameClick={(e) => onViewDrill(e, drill)}
                />
            );
        }
        return drillElements;
    };

    return (
        <RequirePermissions role="drills" level={permissionLevels.VIEW} showDenial={true}>
            {/* Page Header and Actions*/}
            <div className="drills page-head">
                <div>
                    <h2>Drills</h2>
                </div>

                {/* Action Buttons */}
                <div className="actions">
                    <RequirePermissions role="drills" level={permissionLevels.COMPLETE}>
                        <RequirePermissions role="drills" level={permissionLevels.CREATE}>
                            <SeaButton onClick={(e) => onAddNewDrill()} zone="grey">
                                <SeaIcon slot="start" icon="add" />
                                Add New Drill
                            </SeaButton>
                            <div className="spacer"></div>
                        </RequirePermissions>
                        <SeaButton disabled={!vesselDrills?.all.length} zone="grey" onClick={(e) => onAddNewDrillReport()}>
                            <SeaIcon icon="notes" slot="start" />
                            Create Drill Report
                        </SeaButton>
                        <div className="spacer"></div>
                    </RequirePermissions>
                    <SeaButton onClick={(e) => onViewDrillHistory()} zone="grey">
                        <SeaIcon slot="start" icon="archive" />
                        Drill History
                    </SeaButton>
                    <SeaExporter setExportType={setExportType} csv={exportType === 'csv' && <SafetyDrillReportsCsv onCompleted={() => setExportType(undefined)} />} />
                </div>
            </div>

            <div className="drills-content">
                <SeaNoData dataName="drills" isLoading={!vesselDrills} hasNoData={vesselDrills && vesselDrills?.all?.length === 0} isUsingFilter={false} />

                <div className={`drills ${vesselDrills && vesselDrills?.all?.length > 0 ? 'reveal' : 'conceal'}`}>
                    {/* Column Headings */}
                    <div className="sea-row headings">
                        <div>Drill</div>
                        {newDrillsModel.map((drill: Drill) => {
                            return (
                                <div key={drill.id} onClick={(e) => onViewDrill(e, drill)}>
                                    {drill.name}
                                </div>
                            );
                        })}
                    </div>

                    {/* Drill Matrix*/}
                    <Profiler id="safety.drills" onRender={onProfilerRender}>
                        {mapArrayWithLimit(drillUsers, (user) => {
                            // Iterate through crew list
                            return (
                                <div
                                    key={user.id}
                                    className={`sea-card sea-row no-select ${expandedUsers[user.id ?? ''] ? 'expand' : 'shrunk'}`}
                                    onClick={(e) => {
                                        onViewUserDrills(e, user);
                                    }}
                                >
                                    <div className="bold truncate-3">{renderFullName(user)}</div>
                                    {renderDrillElements(user)}
{/* 
                                    {newDrillsModel.map((drill: Drill) => {
                                        // Iterate through drills for each vessel
                                        const today = todayMillis ?? getDayOffsetMillis(0);
                                        const crewData = drill.crew && user.id ? drill?.crew[user.id] : { dateDue: undefined, dateLastCompleted: undefined };

                                        const isDrillUnassigned = (!!user.id && drill.notAssignedTo?.includes(user.id)) ?? false;

                                        if (isDrillUnassigned) {
                                            return <UnassignedDrill drill={drill} key={drill.id} onDrillClick={(e) => onViewDrillUser(e, drill, user)} onDrillNameClick={(e) => onViewDrill(e, drill)} />;
                                        }

                                        if (!crewData?.dateDue || !crewData.dateLastCompleted) {
                                            return <MissingDrill drill={drill} key={drill.id} onDrillClick={(e) => onViewDrillUser(e, drill, user)} onDrillNameClick={(e) => onViewDrill(e, drill)} />;
                                        }

                                        const dateDueDiff = Math.round(makeDateTime(crewData.dateDue).diff(makeDateTime(today), ['days']).days);

                                        return <AssignedDrill drill={drill} key={drill.id} dateDueDiff={dateDueDiff} onDrillClick={(e) => onViewDrillUser(e, drill, user)} onDrillNameClick={(e) => onViewDrill(e, drill)} />;
                                    })} */}
                                    <div className={`more`} onClick={(e) => onToggleUser(e, user.id)}>
                                        <span>Drills</span> <SeaIcon icon={expandedUsers[user.id ?? ''] ? 'moveUp' : 'moveDown'} />
                                    </div>
                                </div>
                            );
                        })}
                        {visible && limitTriggerElement}
                    </Profiler>
                </div>
            </div>

            {/* Modals */}
            {visible && (
                <>
                    <EditDrillReport showModal={showEditDrillReportModal} setShowModal={setShowEditDrillReportModal} vesselId={vesselId} />
                    <EditDrill showModal={showEditDrillModal} setShowModal={setShowEditDrillModal} />
                    <ViewDrill showModal={showViewDrillModal} setShowModal={setShowViewDrillModal} selectedItem={selectedDrill} />
                    {selectedDrill &&
                        <ViewDrillUser
                            showModal={showViewDrillUserModal}
                            setShowModal={setShowViewDrillUserModal}
                            selectedDrill={selectedDrill}
                            selectedUser={selectedUser}
                        />}
                    <ViewUserDrills
                        showModal={showViewUserDrillsModal}
                        setShowModal={setShowViewUserDrillsModal}
                        selectedUser={selectedUser}
                        newDrillsModel={newDrillsModel}
                    />
                    <ViewDrillHistory showModal={showViewDrillHistoryModal} setShowModal={setShowViewDrillHistoryModal} />
                </>
            )}
        </RequirePermissions>
    );
};

export default DrillsV2;
