import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import { Division, Divisions, renderVesselsListUsingDivisions } from '../../shared-state/Core/divisions';
import { Vessel } from '../../shared-state/Core/vessel';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaModalBox from '../SeaModalBox/SeaModalBox';
import SeaButton from '../SeaButton/SeaButton';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaCheckbox from '../SeaCheckbox/SeaCheckbox';

interface SeaSelectVesselsUsingDivisionsProps {
    divisions: Divisions,
    zone?: 'blue' | 'grey' | 'white'
    label?: string,
    width?: string,
    vesselIds: string[] | undefined,
    setVesselIds?: React.Dispatch<React.SetStateAction<string[] | undefined>>,
    onChanged?: (values: string[]) => void,
    emptyText?: string,
    help?: SeaHelp,
    required?: boolean,
    requiredError?: string,
    isSubmitting?: boolean, // Pass in formik's isSubmitting so we can tell if form has been submitted (count as touched)
    disabled?: boolean,
    modalTitle?: string
}

type IsFolderOpen = {
    [divisionId: string]: boolean;
}
let defaultIsFolderOpen = undefined as (IsFolderOpen | undefined);

const SeaSelectVesselsUsingDivisions: React.FC<SeaSelectVesselsUsingDivisionsProps> = ({
    divisions,
    zone,
    label, // = 'Select Divisions / Vessels',
    width,
    vesselIds,
    setVesselIds,
    onChanged,
    emptyText,
    help,
    required,
    requiredError,
    isSubmitting,
    disabled,
    modalTitle
}) => {
    const [showModal, setShowModal] = useState(false);
    const [isFolderOpen, setIsFolderOpen] = useState<{ // Indicates whether a division is expanded or not (opened or closed)
        [divisionId: string]: boolean
    }>();
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        let isActive = true;
        setTouched(false);
        setTimeout(() => {
            if (!isActive) return;
            setTouched(false);
        }, 100);
        return () => { isActive = false; };
    }, []);

    useEffect(() => {
        if (isSubmitting) {
            setTouched(true);
        }
    }, [isSubmitting]);

    let error = '';
    if (required && touched && (vesselIds === undefined || vesselIds.length === 0)) {
        error = requiredError ?? 'Please select at least one vessel';
    }

    const toggleFolder = useCallback((e: any, divisionId: string) => {
        e.preventDefault();
        e.stopPropagation();
        setIsFolderOpen((current) => {
            const result = {
                ...current,
                [divisionId]: !(current?.[divisionId])
            };
            defaultIsFolderOpen = result;
            return result;
        });
    }, []);

    const countOffspring = useCallback((division: Division) => {
        let count = 1 + division.vessels.length;
        division.children?.forEach((child) => {
            if (isFolderOpen && isFolderOpen[child.id]) {
                count += countOffspring(child);
            } else {
                count++;
            }
        });
        return count;
    }, [isFolderOpen]);

    // Default division folders to all being open
    useEffect(() => {
        if (defaultIsFolderOpen) {
            // Remember the last time we used isFolderOpen, where that was within the app
            setIsFolderOpen(defaultIsFolderOpen);
            return;
        }
        const isOpen = {
            'root': true
        } as any;
        divisions?.all.forEach((division) => {
            isOpen[division.id] = division.numVesselsAccess! > 0;
        });
        if (divisions.all.length > 1) {
            defaultIsFolderOpen = isOpen;
        }
        setIsFolderOpen(isOpen);
    }, [divisions]);

    const isFolderSelected = useMemo(() => {
        const isSelected = {} as {
            [divisionId: string]: boolean
        };
        if (divisions?.root && vesselIds) {
            const isDivisionSelected = (division: Division): boolean => {
                if (division.numVesselsAccess === 0) {
                    isSelected[division.id] = false;
                    return true; // Should be counted as selected for parent
                }
                let selected = true;
                if (division.children) {
                    division.children.forEach((child) => {
                        if (!isDivisionSelected(child)) {
                            selected = false;
                        }
                    });
                }
                if (division.vessels && selected) {
                    for (let i = 0; i < division.vessels.length; i++) {
                        if (division.vessels[i].canAccess && !vesselIds.includes(division.vessels[i].id)) {
                            selected = false;
                            break;
                        }
                    }
                }
                isSelected[division.id] = selected;
                return selected;
            };
            isDivisionSelected(divisions.root);
        }
        return isSelected;
    }, [divisions.root, vesselIds]);

    const selectVessel = useCallback((vessel: Vessel, checked: boolean) => {
        if (!setVesselIds || !vessel.canAccess) return;
        setVesselIds((current) => {
            const result = [...(current ?? [])];
            if (checked) {
                if (!result.includes(vessel.id)) {
                    result.push(vessel.id);
                }
            } else {
                const index = result.indexOf(vessel.id);
                if (index !== -1) {
                    result.splice(index, 1);
                }
            }
            if (onChanged) {
                setTimeout(() => {
                    onChanged(result);
                });
            }
            return result;
        });
    }, [setVesselIds, onChanged]);

    /**
     * Select or deselect (depending on checked being true or false) all vessel descendants of division
     */
    const selectDivision = useCallback((division: Division, checked: boolean) => {
        // Apply checked to all vessel descendants
        if (!setVesselIds || division.numVesselsAccess === 0) return;
        setVesselIds((current) => {
            const result = [...(current ?? [])];
            const doVessel = checked ? (
                (vessel: Vessel) => {
                    if (vessel.canAccess && !result.includes(vessel.id)) {
                        result.push(vessel.id);
                    }
                }
            ) : (
                (vessel: Vessel) => {
                    const index = result.indexOf(vessel.id);
                    if (index !== -1) {
                        result.splice(index, 1);
                    }
                }
            )
            const doDivision = (division: Division) => {
                division.vessels.forEach((vessel) => {
                    doVessel(vessel);
                });
                division.children?.forEach((child) => {
                    doDivision(child);
                });
            };
            doDivision(division);
            if (onChanged) {
                setTimeout(() => {
                    onChanged(result);
                });
            }
            return result;
        });
    }, [onChanged, setVesselIds]);

    const renderVessels = useCallback((division: Division) => {
        return division.vessels.map((vessel) => {
            const checked = vesselIds?.includes(vessel.id);
            return (
                <div
                    key={vessel.id}
                    className="sea-division-option columns"
                    style={{ pointerEvents: vessel.canAccess ? 'all' : 'none' }}
                >
                    <div className="check" onClick={(e) => selectVessel(vessel, !checked)}>
                        <SeaCheckbox
                            checked={checked}
                            disabled={!vessel.canAccess}
                        />
                    </div>
                    <div
                        className={`name no-select${vessel.canAccess ? '' : ' no-access'}`}
                        onClick={(e) => selectVessel(vessel, !checked)}
                    >
                        {vessel.name}
                    </div>
                </div>
            );
        });
    }, [selectVessel, vesselIds]);

    const renderDivisions = useCallback((division: Division) => {
        const isOpen = isFolderOpen?.[division.id] ? true : false;
        const checked = isFolderSelected?.[division.id];
        return (
            <React.Fragment key={division.id}>
                <div className="sea-division-option columns">
                    <div
                        className={`folder-icon pushy ${isOpen ? 'opened' : 'closed'}`}
                        onClick={(e) => toggleFolder(e, division.id)}
                    >
                        <SeaIcon
                            icon="folderOpened"
                        />
                    </div>
                    <div
                        className={`check${division.numVesselsNoAccess! > 0 ? ' partial' : ''}`}
                        onClick={(e) => selectDivision(division, !checked)}
                        //style={{ pointerEvents: isOpen ? 'all' : 'none' }}
                    >
                        <SeaCheckbox
                            checked={checked}
                            disabled={division.numVesselsAccess === 0}
                        />
                    </div>
                    <div
                        className={`name no-select${division.numVesselsAccess === 0 ? ' no-access' : ''}`}
                        onClick={(e) => selectDivision(division, !checked)}
                    >
                        {division.id === 'root' ? 'All Vessels' : division.name} {checked && (division.numVesselsNoAccess ? `(${division.numVesselsAccess}/${division.numVesselsAccess! + division.numVesselsNoAccess})` : `(${division.numVesselsAccess})`)}
                    </div>
                </div>
                <div
                    className="sea-division-parent"
                    style={{
                        opacity: isOpen ? 1 : 0,
                        maxHeight: isOpen ? (countOffspring(division) * 40)+'px' : '0px',
                        pointerEvents: isOpen ? 'all' : 'none' }
                    }>
                    {
                        division.children?.map((child) => {
                            return renderDivisions(child);
                        })
                    }
                    {division.vessels.length > 0 && 
                        <div style={{ paddingLeft: '26px' }}>
                            {renderVessels(division)}
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }, [countOffspring, isFolderOpen, isFolderSelected, renderVessels, selectDivision, toggleFolder]);

    const summaryText = useMemo(() => {
        return renderVesselsListUsingDivisions(
            vesselIds,
            undefined,
            divisions,
            emptyText,
            'All Vessels',
            true,
            36
        );
    }, [divisions, emptyText, vesselIds]);

    const stopClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <>
            {label && <div><SeaLabel zone={zone} help={help}>{label}</SeaLabel></div>}
            <div
                className={`sea-input sea-select multi ${zone}-zone ${error ? 'has-error' : ''} ${disabled ? 'disabled' : ''}`}
                style={{ width: width }}
                onClick={() => !disabled && setShowModal(true)}
            >
                <div className="select-text no-select">
                    {summaryText}
                </div>
                <div className="select-icon">
                    <div className="select-icon-inner"></div>
                </div>
            </div>
            <SeaInputError alignLeft>{error}</SeaInputError>
            <SeaModalBox
                showModal={showModal}
                setShowModal={setShowModal}
                maxWidth={600}
                //maxHeight={undefined}
                className="sea-vessels-modal"
            >
                <div className="sea-select-multi-title" style={{ maxWidth: '100%' }}>
                    {modalTitle ?? 'Select Divisions / Vessels'}
                </div>
                <div
                    className="sea-select-multi-box"
                    onClick={stopClick}
                    style={{ padding: '4px 4px', width: '500px' }}
                >
                    {/* {formattedOptions?.map((option) => {
                        return renderOption(option);
                    })} */}
                    {renderDivisions(divisions.root)}
                </div>
                <div
                    className="sea-division-options"
                    onClick={stopClick}
                    style={{ width: '100%', textAlign: 'right', padding: '0px 8px 8px 0px' }}
                >
                    <SeaButton size="small" mini onClick={() => setShowModal(false)}>
                        OK
                    </SeaButton>
                </div>
            </SeaModalBox>
        </>
    );

};

export default SeaSelectVesselsUsingDivisions;
