import { collection, doc, serverTimestamp } from 'firebase/firestore';
import { LinkType } from '../shared-state/Core/links';
import { LinkSide } from '../components/SeaLinks/SeaLinks';
import { SplittableBatch, firestore } from './firebase';
import { onCollectionUpdated } from '../shared-state/DataSyncSystem/dataSync';
import { sharedState } from '../shared-state/shared-state';
import { renderCamelCase } from './util';
import { LinkCollectionOption, LinkOptionsType } from '../components/SeaLinkMultiList/SeaLinkMultiList';
import { fetchSingleItem } from '../shared-state/Core/fetchSingleItem';

const returnLinkTitleFieldFromCollection = (collection: string) => {
    switch (collection) {
        case 'external':
            return 'linkId';
        case 'scheduledMaintenanceTasks':
            return 'tasks';
        case 'SOPs':
        case 'vesselDocuments':
            return 'title';
        default:
            return 'name';
    }
};

function processLinks(batch: SplittableBatch, links: LinkType[], parentId: string, parentCollection: string, operation: 'add' | 'update') {
    links.forEach((link) => {
        if (!sharedState.userId.current || !sharedState.licenseeId.current) {
            return;
        }
        // removes the id from the link
        const { id, ..._link } = link;
        const linkData = {
            ..._link,
            aId: parentId,
            aType: parentCollection,
            touched: serverTimestamp(),
        };

        if (operation === 'add') {
            const newLinkRef = doc(collection(firestore, 'links'));
            batch.set(newLinkRef, {
                ...linkData,
                addedBy: sharedState.userId.current,
                whenAdded: serverTimestamp(),
                licenseeId: sharedState.licenseeId.current,
                state: 'active',
            });
        } else {
            const linkRef = doc(firestore, 'links', link.id);
            batch.set(
                linkRef,
                {
                    ...linkData,
                    updatedBy: sharedState.userId.current,
                    whenUpdated: serverTimestamp(),
                },
                { merge: true }
            );
        }
    });
}

/**
 * Handles the updates to the links. Use in the onSubmit function of the parent.
 *
 * @example
 * <SeaLinkMultiList
 *  onSubmit={(batch, links, existingLinks, itemId, collection) => handleLinkUpdates(batch, links, existingLinks, itemId, collection)}
 * />
 *
 * @param batch - The batch to update the links in.
 * @param newLinks - The new links to update.
 * @param existingLinks - The previous links to update.
 * @param parentId - The id of the parent of the links.
 * @param parentCollection - The collection of the parent of the links.
 */
export function handleLinkUpdates(batch: SplittableBatch, newLinks: LinkType[], existingLinks: LinkType[] | undefined, parentId: string, parentCollection: string) {
    if (!existingLinks || existingLinks.length === 0) {
        // If there are no existing links, we can assume that all new links need to be added
        processLinks(batch, newLinks, parentId, parentCollection, 'add');
    } else {
        // Links to be deleted
        const linksToDelete = existingLinks.filter((existingLink) => !newLinks.some((newLink) => newLink.id === existingLink.id));

        // Links to be added
        const linksToAdd = newLinks.filter((newLink) => !existingLinks.some((existingLink) => existingLink.id === newLink.id));

        // Links to be updated
        const linksToUpdate = newLinks.filter((newLink) =>
            existingLinks.some((existingLink) => existingLink.id === newLink.id && (existingLink.aType !== newLink.aType || existingLink.aId !== newLink.aId || existingLink.bType !== newLink.bType || existingLink.bId !== newLink.bId))
        );

        // Delete links
        linksToDelete.forEach((link) => {
            const linkRef = doc(firestore, 'links', link.id);
            batch.set(linkRef, { state: 'deleted', whenDeleted: serverTimestamp(), deletedBy: sharedState.userId.current }, { merge: true });
        });

        // Process links to add
        processLinks(batch, linksToAdd, parentId, parentCollection, 'add');

        // Process links to update
        processLinks(batch, linksToUpdate, parentId, parentCollection, 'update');
    }

    onCollectionUpdated(batch, 'links');
}

export const convertLinkSideFromLinkType = (link: LinkType, id: string) => {
    let linkSide: LinkSide;
    if (link.aId === id) {
        linkSide = {
            id: id,
            linkId: link.bId,
            collection: link.bType,
        };
    } else {
        linkSide = {
            id: id,
            linkId: link.aId,
            collection: link.aType,
        };
    }
    return linkSide;
};

// Returns links by collection and removes the side matching an id
export const getLinksByCollectionFromIds = (linkTypes?: LinkType[], ids?: string[]) => {
    if (!linkTypes || !ids) {
        return undefined;
    }
    const byCollection: Record<string, LinkSide[]> = {};
    const linkSides = linkTypes.flatMap((link) => {
        if (ids.includes(link.aId)) {
            return {
                id: link.id,
                linkId: link.bId,
                collection: link.bType,
            };
        } else if (ids.includes(link.bId)) {
            return {
                id: link.id,
                linkId: link.aId,
                collection: link.aType,
            };
        }
        return [];
    });

    // Sort linkSides alphabetically, placing external links at the end
    linkSides.sort((a, b) => {
        if (a.collection === 'external') return 1;
        if (b.collection === 'external') return -1;
        return a.collection.localeCompare(b.collection);
    });

    for (const link of linkSides) {
        if (!byCollection[link.collection]) {
            byCollection[link.collection] = [];
        }
        if (byCollection[link.collection].some((val) => val.linkId === link.linkId)) {
            continue;
        }
        byCollection[link.collection].push(link);
    }
    return byCollection;
};

export const renderLinkLabelFromCollection = (collection: string, prefix?: boolean, small?: boolean) => {
    if (collection === 'external') {
        return 'External links';
    }

    let label = prefix ? 'Links to ' : '';
    switch (collection) {
        case 'scheduledMaintenanceTasks':
            label += small ? 'Maintenance Tasks' : 'Scheduled Maintenance Tasks';
            break;
        case 'SOPs':
            label += small ? 'SOPs' : 'Standard Operating Procedures';
            break;
        case 'risks':
            label += small ? 'Risks' : 'Risk Assessments';
            break;
        case 'customForms':
            label += small ? 'Forms' : 'Forms/Checklists';
            break;
        case 'safetyCheckItems':
            label += small ? 'Safety Checks' : 'Safety Checks';
            break;
        default:
            label += renderCamelCase(collection);
            break;
    }

    return label;
};

export const getExternalUrl = (linkId: string): string => {
    // Check if the linkId is already a full URL
    if (linkId.startsWith('http://') || linkId.startsWith('https://')) {
        return linkId;
    } else return `https://${linkId}`;
};

export const renderLinkName = (name: string, state: string) => (['archived', 'deleted'].includes(state) ? `${name} (Deleted)` : name);

export const addMissingLinks = (existingLinks: LinkType[], allOptions: LinkOptionsType[], collection: LinkCollectionOption) => {
    const fetchPromises: Promise<void>[] = [];

    for (const existingLink of existingLinks) {
        const linkType = existingLink.aType === collection ? (existingLink.bType as LinkCollectionOption) : (existingLink.aType as LinkCollectionOption);
        const linkId = existingLink.aType === collection ? existingLink.bId : existingLink.aId;
        const field = returnLinkTitleFieldFromCollection(linkType);

        if (linkType === 'external') {
            continue;
        }

        const optionType = allOptions.find((option) => option.collection === linkType);

        if (optionType) {
            const itemsExist = optionType.items?.some((item) => item.id === linkId);
            const categoriesExist = optionType.categories?.some((category) => category.items?.some((item) => item.id === linkId));

            if (!itemsExist && !categoriesExist) {
                fetchPromises.push(
                    fetchSingleItem(linkType, linkId).then((item) => {
                        if (item) {
                            const newItem = {
                                id: linkId,
                                name: renderLinkName(item[field], item.state),
                            };
                            if (optionType.items) {
                                insertLinkAlphabetically(optionType.items, newItem);
                            } else if (optionType.categories) {
                                // Assuming you want to add to the first category if items don't exist
                                insertLinkAlphabetically(optionType.categories[0].items, newItem);
                            }
                        }
                    })
                );
            }
        } else {
            fetchPromises.push(
                fetchSingleItem(linkType, linkId).then((item) => {
                    if (item) {
                        allOptions.push({
                            collection: linkType,
                            label: renderLinkLabelFromCollection(linkType),
                            items: [
                                {
                                    id: linkId,
                                    name: renderLinkName(item[field], item.state),
                                    vesselId: item.vesselId || 'na',
                                },
                            ],
                        });
                    }
                })
            );
        }
    }

    return Promise.all(fetchPromises);
};

function insertLinkAlphabetically(items: { id: string; name: string }[], newItem: { id: string; name: string }) {
    const index = items.findIndex((item) => item.name.localeCompare(newItem.name) > 0);
    if (index === -1) {
        items.push(newItem);
    } else {
        items.splice(index, 0, newItem);
    }
}
