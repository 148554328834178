import React from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { renderDivisionsBreadcrumb } from '../../shared-state/Core/divisions';
import SeaBreadcrumb from '../../components/SeaBreadcrumb/SeaBreadcrumb';
import SeaLink from '../../components/SeaLink/SeaLink';
import StandardPageLayout from '../../layouts/StandardPageLayout/StandardPageLayout';
import SeaTabsGroup from '../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../components/SeaTab/SeaTab';
import SeaTabContent from '../../components/SeaTabContent/SeaTabContent';
import VesselCertificates from './VesselCertificates/VesselCertificates';
import VesselDocumentsPage from './VesselDocumentsPage/VesselDocumentsPage';
import SurveyReports from './SurveyDocuments/SurveyDocuments';
import StandardOperatingProcedures from './SOPs/StandardOperatingProcedures';
import './VesselDocuments.css';

const VesselDocuments: React.FC = () => {
    const vessel = sharedState.vessel.use();
    const divisions = sharedState.divisions.use();
    const selectedSectionTab = sharedState.selectedSectionTab.use();

    return (
        <StandardPageLayout section="vesselDocuments">
            <SeaBreadcrumb>
                <SeaLink to="/fleet">Fleet DashBoard</SeaLink>
                {renderDivisionsBreadcrumb(vessel, divisions)}
                {vessel && [
                    <SeaLink to={`/vessel/${ vessel.id }`}>{ vessel.name }</SeaLink>,
                    <SeaLink to={`/vessel/${ vessel.id }/documents`}>Vessel Document Register</SeaLink>
                ]}
            </SeaBreadcrumb>

            <SeaTabsGroup id="VesselDocuments" selectedTab={selectedSectionTab} setTab={sharedState.selectedSectionTab.set}>
                <SeaTab tab="Certifications" requireRole="vesselCertificates" setTab={sharedState.selectedSectionTab.set}>{vessel?.isShoreFacility ? 'Vehicle Expiries' : 'Vessel Certificates'}</SeaTab>
                <SeaTab tab="Documents" requireRole="vesselDocuments" setTab={sharedState.selectedSectionTab.set}>{vessel?.isShoreFacility ? 'Documents' : 'Vessel Documents'}</SeaTab>
                <SeaTab hide={vessel?.isShoreFacility} tab="SurveyDocuments" requireRole="survey" setTab={sharedState.selectedSectionTab.set}>Survey Documents</SeaTab>
                <SeaTab tab="StandardOperatingProcedures" requireRole="standardOperatingProcedures" setTab={sharedState.selectedSectionTab.set}>Standard Operating Procedures</SeaTab>
            </SeaTabsGroup>

            <SeaTabContent tab='Certifications' selectedTab={selectedSectionTab}>
                <VesselCertificates visible={selectedSectionTab === 'Certifications'}/>
            </SeaTabContent>
            <SeaTabContent tab="Documents" selectedTab={selectedSectionTab}>
                <VesselDocumentsPage visible={selectedSectionTab === 'Documents'}/>
            </SeaTabContent>
            {!vessel?.isShoreFacility && 
                <SeaTabContent tab="SurveyDocuments" selectedTab={selectedSectionTab}>
                    <SurveyReports visible={selectedSectionTab === 'SurveyDocuments'}/>
                </SeaTabContent>
            }
            <SeaTabContent tab="StandardOperatingProcedures" selectedTab={selectedSectionTab}>
                <StandardOperatingProcedures visible={selectedSectionTab === 'StandardOperatingProcedures'}/>
            </SeaTabContent>

        </StandardPageLayout>
    );
};

export default VesselDocuments;
