import { sharedState } from '../../shared-state/shared-state';
import { renderDivisionsBreadcrumb } from '../../shared-state/Core/divisions';
import SeaBreadcrumb from '../../components/SeaBreadcrumb/SeaBreadcrumb';
import SeaLink from '../../components/SeaLink/SeaLink';
import StandardPageLayout from '../../layouts/StandardPageLayout/StandardPageLayout';
import SeaTabsGroup from '../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../components/SeaTab/SeaTab';
import SafetyEquipmentChecks from './SafetyEquipmentChecks/SafetyEquipmentChecks';
import SafetyEquipmentExpiries from './SafetyEquipmentExpiries/SafetyEquipmentExpiries';
import SeaTabContent from '../../components/SeaTabContent/SeaTabContent';
import DrillsV2 from "./Drills/DrillsV2";
import './VesselSafety.css';

const VesselSafety: React.FC = () => {
    const vessel = sharedState.vessel.use();
    const divisions = sharedState.divisions.use();
    const selectedSectionTab = sharedState.selectedSectionTab.use();

    return (
        <StandardPageLayout section="vesselSafety">
            <SeaBreadcrumb>
                <SeaLink to="/fleet">Fleet DashBoard</SeaLink>
                {renderDivisionsBreadcrumb(vessel, divisions)}
                {vessel && [
                    <SeaLink to={`/vessel/${ vessel.id }`}>{ vessel.name }</SeaLink>,
                    <SeaLink to={`/vessel/${ vessel.id }/safety`}>Safety</SeaLink>
                ]}
            </SeaBreadcrumb>

            <SeaTabsGroup id="VesselSafety" selectedTab={selectedSectionTab} setTab={sharedState.selectedSectionTab.set}>
                <SeaTab tab="SafetyEquipmentChecks" requireRole="safetyEquipmentChecks">Safety Checks</SeaTab>
                <SeaTab hide={vessel?.isShoreFacility} tab="SafetyEquipmentList" requireRole="safetyEquipmentList">Safety Equipment Expiries</SeaTab>
                <SeaTab tab="Drills" requireRole="drills">Drills</SeaTab>
            </SeaTabsGroup>

            <SeaTabContent tab="SafetyEquipmentChecks" selectedTab={selectedSectionTab}>
                <SafetyEquipmentChecks visible={selectedSectionTab === 'SafetyEquipmentChecks'}/>
            </SeaTabContent>
            {!vessel?.isShoreFacility && 
                <SeaTabContent tab="SafetyEquipmentList" selectedTab={selectedSectionTab}>
                    <SafetyEquipmentExpiries visible={selectedSectionTab === 'SafetyEquipmentList'}/>
                </SeaTabContent>
            }
            <SeaTabContent tab="Drills" selectedTab={selectedSectionTab}>
                {/*<Drills visible={selectedSectionTab === 'Drills'}/>*/}
                <DrillsV2 visible={selectedSectionTab === 'Drills'}/>
            </SeaTabContent>

        </StandardPageLayout>
    );
};

export default VesselSafety;
