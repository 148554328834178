import { ReactNode } from "react";
import { SharedStateConfig, sharedState } from "../shared-state";
import { Vessel } from "./vessel";
import { renderVesselName, VesselsData } from "./vessels";
import SeaLink from "../../components/SeaLink/SeaLink";

//
// Creates divisions based on vessels and licenseeSettings.
//

export type Division = {
    id: string,
    name: string,
    parent?: Division,
    children?: Division[],
    vessels: Vessel[],
    // Generated at run time - not in original data
    unique?: number,
    numVesselsAccess?: number, // number of vessels I can access within this division
    numVesselsNoAccess?: number, // number of vessels I don't have access to
}
export type Divisions = {
    all: Division[], // Does not include root
    byId: {
        [divisionId: string]: Division
    },
    root: Division
}

export const createEmptyRootDivision = () => {
    return {
        id: 'root',
        name: 'Top Level',
        children: [],
        vessels: [],
        unique: Math.random()
    } as Division;
};

export const divisionsConfig: SharedStateConfig<Divisions> = {
    isAlwaysActive: true,
    dependencies: ['licenseeSettings', 'vessels'],
    countLiveDocs: () => sharedState.divisions.current ? sharedState.divisions.current.all.length : 0,
    run: (done, set, clear) => {
        clear();
        const vessels = sharedState.vessels.current;
        const licenseeSettings = sharedState.licenseeSettings.current;
        if (vessels && licenseeSettings?.divisions?.length) {

            const all = [] as Division[];
            const root = createEmptyRootDivision();
            const byId = {
                'root': root
            } as {
                [id: string]: Division
            };

            // Create list of divisions without tree structure
            licenseeSettings.divisions.forEach((_division) => {
                const division = {
                    id: _division.id,
                    name: _division.name,
                    vessels: [],
                    unique: Math.random(),
                } as Division;
                byId[division.id] = division;
                all.push(division);
            });

            // Create tree structure
            licenseeSettings.divisions.forEach((_division) => {
                const division = byId[_division.id];
                const parent = _division.parentId ? byId[_division.parentId] : root;
                if (parent.children === undefined) {
                    parent.children = [];
                }
                parent.children.push(division);
                division.parent = parent;
            });

            // Populate with vessels
            //vessels.all.forEach((vessel) => { // (vessels.all only contains vessels I have access to)
            Object.values(vessels.byId).forEach((vessel) => {
                if (vessel?.divisionId && byId[vessel.divisionId]) {
                    byId[vessel.divisionId].vessels.push(vessel);
                } else {
                    root.vessels.push(vessel);
                }
            });

            const determineVesselAccess = (division: Division) => {
                division.numVesselsAccess = 0;
                division.numVesselsNoAccess = 0;
                division.children?.forEach((child) => {
                    determineVesselAccess(child);
                    division.numVesselsAccess! += child.numVesselsAccess!;
                    division.numVesselsNoAccess! += child.numVesselsNoAccess!;
                });
                for (let i = 0; i < division.vessels.length; i++) {
                    if (division.vessels[i].canAccess) {
                        division.numVesselsAccess++
                    } else {
                        division.numVesselsNoAccess++;
                    }
                }
            };
            determineVesselAccess(root);

            set({
                all,
                byId,
                root
            });
        }
        done();
    }
};

/**
 * We need this because simple cloning will mess up the tree structure
 */
export const cloneDivisions = (divisions: Divisions) => {
    const all = [] as Division[];
    const root = createEmptyRootDivision();
    root.vessels = [...divisions.root.vessels];
    const byId = {
        'root': root
    } as {
        [id: string]: Division
    };

    if (divisions?.all) {
        // Clone divisions without tree structure
        divisions.all.forEach((division) => {
            if (division.id === 'root') {
                return;
            }
            byId[division.id] = {
                id: division.id,
                name: division.name,
                vessels: [...division.vessels],
                unique: Math.random()
            }
            all.push(byId[division.id]);
        });
        // Create tree structure in clone
        divisions.all.forEach((division) => {
            if (division.id === 'root') {
                return;
            }
            const parent = byId[division.parent!.id];
            if (parent.children === undefined) {
                parent.children = [];
            }
            parent.children.push(byId[division.id]);
            byId[division.id].parent = parent;
        });
    }

    return {
        all,
        byId,
        root
    }
};

/**
 * Turns what is important within a Divisions to a string.
 */
export const divisionsToString = (divisions?: Divisions) => {
    let s = '';
    divisions?.all?.forEach((division) => {
        s += `${division.id}${division.name}${division.parent!.id}`;
    });
    return s;
};

/**
 * Test whether a division has no vessels (directly or within descendants)
 */
// export const isDivisionEmpty = (division: Division) => {
//     if (division.vessels.length > 0) {
//         return false;
//     }
//     if (division.children) {
//         for (let i = 0; i < division.children.length; i++) {
//             if (!isDivisionEmpty(division.children[i])) {
//                 return false;
//             }
//         }
//     }
//     return true;
// };

/**
 * Renders this list of vessels represented by vesselIds[]
 * If possible, will prefer to use divisions that represent multiple vessels.
 * The goal is to keep the list of names as short as possible.
 */
export const renderVesselsListUsingDivisions = (
    vesselIds?: string[] | string,
    _vessels?: VesselsData,
    _divisions?: Divisions,
    emptyText = '-',
    allVesselsText = 'All Vessels',
    includeVesselCounts = true,
    characterLimit?: number
) => {
    const vessels = _vessels ?? sharedState.vessels.current;
    const divisions = _divisions ?? sharedState.divisions.current;
    if (
        vesselIds === undefined ||
        vesselIds.length === 0 ||
        vessels === undefined ||
        divisions === undefined
    ) {
        return emptyText;
    }
    if (typeof vesselIds === 'string') {
        if (vesselIds === 'any') {
            return allVesselsText ?? '';
        }
        return renderVesselName(vesselIds as string, _vessels);
    }

    // Work out selected vessel counts per divisionId
    const vesselCounts = {} as {
        [divisionId: string]: number
    };
    vesselIds.forEach((vesselId) => {
        const vessel = vessels.byId[vesselId];
        if (!vessel) return;
        const divisionId = vessel.divisionId ?? 'root';
        if (vesselCounts[divisionId] === undefined) {
            vesselCounts[divisionId] = 0;
        }
        vesselCounts[divisionId]++;
    });

    // Determine which divisions are selected
    const isSelected = {} as {
        [divisionId: string]: boolean
    };
    const isDivisionSelected = (division: Division): boolean => {
        let selected = true;
        if (division.children) {
            division.children.forEach((child) => {
                if (!isDivisionSelected(child)) {
                    selected = false;
                }
            });
        }
        if (selected && division.vessels) {
            if ((vesselCounts[division.id] ?? 0) !== division.vessels.length) {
                selected = false; // Not all vessels are selected within this division
            }
        }
        isSelected[division.id] = selected;
        return selected;
    };
    isDivisionSelected(divisions.root);

    const countVessels = (division: Division) => {
        let count = division.vessels.length;
        division.children?.forEach((child) => {
            count += countVessels(child);
        });
        return count;
    };

    if (isSelected['root'] && allVesselsText) {
        if (includeVesselCounts) {
            return `${allVesselsText} (${countVessels(divisions.root)})`;
        }
        return allVesselsText;
    }

    // Create list of divisions/vessels
    let list = [] as string[];
    const traverseForList = (division: Division) => {
        if (isSelected[division.id]) {
            //if (!isDivisionEmpty(division)) {
            if (division.numVesselsAccess! > 0) {
                if (includeVesselCounts) {
                    list.push(`${division.name} (${countVessels(division)})`);
                    //list.push(division.name);
                } else {
                    list.push(division.name);
                }
            }
        } else {
            division.children?.forEach((child) => {
                traverseForList(child);
            });
        }
    };
    traverseForList(divisions.root);

    vesselIds.forEach((vesselId) => {
        const vessel = vessels.byId[vesselId];
        if (vessel && !isSelected[vessel.divisionId ?? 'root']) {
            list.push(vessel.name);
        }
    });

    if (list.length === 1) {
        return list[0];
    } else if (list.length > 1) {
        const s = list.join(', ');
        if (characterLimit && s.length > characterLimit) {
            // Convert to count
            return `Vessels (${list.length}/${divisions.root.numVesselsAccess! + divisions.root.numVesselsNoAccess!})`;
        }
        return s;
    }

    return emptyText;
};


/**
 * Creates an array of SeaLinks to represent a vessel's division within a Breadcrumb trail.
 */
export const renderDivisionsBreadcrumb = (vessel?: Vessel, divisions?: Divisions) => {
    if (
        vessel &&
        divisions &&
        vessel.divisionId &&
        vessel.divisionId !== 'root' &&
        divisions.byId[vessel.divisionId]
    ) {
        const list = [] as ReactNode[];
        const renderVesselsParam = (division: Division) => {
            let s = '';
            division.vessels.forEach((vessel) => {
                s += vessel.id;
            });
            division.children?.forEach((child) => {
                s += renderVesselsParam(child);
            });
            return s;
        };
        const renderLinks = (division?: Division) => {
            if (division && division.id !== 'root') {
                list.unshift(
                    <SeaLink to={`/fleet?vessels=${renderVesselsParam(division)}`}>{division.name}</SeaLink>
                );
                renderLinks(division.parent);
            }
        };
        renderLinks(
            divisions.byId[vessel.divisionId]
        );
        return list;
    }
    return;
};
