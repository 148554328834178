import React, { useState, useEffect, useMemo, useCallback, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { extractSearchTerms, getBasedOnBackgroundColour } from '../../../lib/util';
import { formatDateShort, getDayOffset, getToday, warnDays } from '../../../lib/datesAndTime';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { renderCategoryName } from '../../../lib/categories';
import { sharedState } from '../../../shared-state/shared-state';
import { Risk } from '../../../shared-state/HealthSafety/risks';
import { renderVesselsList } from '../../../shared-state/Core/vessels';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import ViewRiskMatrix from '../../../modals/HealthSafety/RiskRegister/ViewRiskMatrix/ViewRiskMatrix';
import EditRiskAssessment from '../../../modals/HealthSafety/RiskRegister/EditRiskAssessment/EditRiskAssessment';
import ViewRiskAssessment from '../../../modals/HealthSafety/RiskRegister/ViewRiskAssessment/ViewRiskAssessment';
import EditRiskRegisterSettings from '../../../modals/HealthSafety/RiskRegister/EditRiskRegisterSettings/EditRiskRegisterSettings';
import RiskRegisterPdf from '../../../exports/HealthSafety/Risks/RiskRegisterPdf';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import RiskRegisterCsv from '../../../exports/HealthSafety/Risks/RiskRegisterCsv';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import SeaSelectVessels from '../../../components/SeaSelectVessels/SeaSelectVessels';
import './RiskRegister.css';
import SeaSearchbar from '../../../components/SeaSearchbar/SeaSearchbar';

interface RiskRegisterProps {
    visible: boolean,
    filterVesselIds: string[] | undefined;
    setFilterVesselIds: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

const RiskRegister: React.FC<RiskRegisterProps> = ({ visible, filterVesselIds, setFilterVesselIds }) => {
    const risks = sharedState.risks.use(visible ? 1 : 101);
    const riskCategories = sharedState.riskCategories.use(visible ? 1 : 101);
    const vessels = sharedState.vessels.use(visible ? 1 : 101);
    const divisions = sharedState.divisions.use(visible ? 1 : 101);
    const licenseeSettings = sharedState.licenseeSettings.use(visible ? 1 : 101);
    const todayMillis = sharedState.todayMillis.use(visible ? 1 : 101) as number;
    const [selectedRisk, setSelectedRisk] = useState<Risk>();
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [searchText, setSearchText] = useState('');
    const [riskMatrixModal, setRiskMatrixModal] = useState({
        show: false,
        allowEdit: true,
        level: 1,
        likelihoodId: 0,
        consequenceId: 0,
        type: ''
    });
    const [showRiskRegisterSettings, setShowRiskRegisterSettings] = useState(false);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (visible) {
            logPageView('HealthSafety/RiskRegistry');
        }
    }, [visible]);

    useEffect(() => {
        resetPageLimit();
    }, [visible, searchText, filterVesselIds, resetPageLimit]);

    // Keep selectedRisk fresh
    useEffect(() => {
        if (selectedRisk?.id && risks?.byId[selectedRisk.id]) { 
            setSelectedRisk(risks.byId[selectedRisk.id])
        };
    }, [risks, selectedRisk?.id]);

    const isSingleVessel = useMemo(() => {
        return (filterVesselIds && filterVesselIds.length === 1);
    }, [filterVesselIds]);

    const filteredRisks = useMemo(() => { // (by categoryId)
        if (!risks) {
            return undefined;
        }
        let _filteredRisks = {} as {
            [categoryId: string]: Risk[]
        };
        const terms = extractSearchTerms(searchText, true);
        if (filterVesselIds) {
            const doneRisk = {} as {[id: string]: true};
            filterVesselIds.forEach((vesselId) => {
                if (risks?.byVesselId[vesselId]) {
                    Object.keys(risks.byVesselId[vesselId].byCategoryId).forEach((categoryId) => {
                        risks.byVesselId[vesselId].byCategoryId[categoryId].forEach((risk) => {
                            if (!doneRisk[risk.id]) {
                                doneRisk[risk.id] = true;
                                let isMatch = true;
                                if (terms.length > 0) {
                                    for (let i = 0; i < terms.length; i++) {
                                        if (risk.searchText?.indexOf(terms[i]) === -1) {
                                            isMatch = false;
                                            break;
                                        }
                                    }
                                }
                                if (isMatch) {
                                    if (_filteredRisks[categoryId] === undefined) {
                                        _filteredRisks[categoryId] = [];
                                    }
                                    _filteredRisks[categoryId].push(risk);
                                }
                            }
                        });
                    });
                }
            });
            if (filterVesselIds.length > 1) {
                Object.values(_filteredRisks).forEach((category: Risk[]) => {
                    category.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                });
            }
        } else {
            _filteredRisks = risks.byCategoryId;
        }
        return _filteredRisks;
    }, [risks, searchText, filterVesselIds]);

    const renderRiskRating = useCallback((likelihoodId: number | string, consequenceId: number | string) => {
        if (!licenseeSettings?.riskRegister?.matrix) return undefined;
        const raw = licenseeSettings.riskRegister.matrix[`${likelihoodId}-${consequenceId}`];
        const color = raw.substring(0, 6);
        const name = raw.substring(7);
        return <div className="sea-status risk-rating" style={{ backgroundColor: `#${color}`, color: getBasedOnBackgroundColour(color) }}>
            {name}
        </div>;
    }, [licenseeSettings]);


    const today = useMemo(() => {
        return getToday();
    }, [todayMillis]); // eslint-disable-line react-hooks/exhaustive-deps

    const dayUpcoming = useMemo(() => {
        return getDayOffset(warnDays.riskRegister[0]);
    }, [todayMillis]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderWhenDueClass = useCallback((dateDue: string) => {
        if (dateDue < today) {
            return 'fail';
        } else if (dateDue < dayUpcoming) {
            return 'warn';
        }
        return '';
    }, [today, dayUpcoming]);


    const onViewRiskMatrix = () => {
        setRiskMatrixModal({
            show: true,
            allowEdit: true,
            level: 1,
            likelihoodId: 0,
            consequenceId: 0,
            type: ''
        });
    };
    const onAddNewItem = () => {
        setSelectedRisk(undefined);
        setShowEditItemModal(true);
    };

    const onEditSettings = () => {
        setShowRiskRegisterSettings(true);
    };
    const onViewItem = (risk: Risk) => {
        console.log(`Viewing risk ${risk.id}`);
        setSelectedRisk(risk);
        setShowViewItemModal(true);
    };

    return (
        <RequirePermissions
            role="hazardRegister"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="risk-register page-head">
                <div><h2>Risk Assessments</h2></div>
                <div className="actions">
                    <SeaButton onClick={(e) => onViewRiskMatrix()} zone="grey">
                        Risk Matrix
                    </SeaButton>
                    <div className="spacer"></div>
                    <RequirePermissions
                        role="hazardRegister"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Complete Assessment
                        </SeaButton>
                        <div className="spacer"></div>
                    </RequirePermissions>
                    <SeaExporter 
                        setExportType={setExportType}
                        csv={exportType === "csv" && <RiskRegisterCsv onCompleted={() => setExportType(undefined)} />}
                        pdf={exportType === "pdf" && (
                            <RiskRegisterPdf
                                onCompleted={() => setExportType(undefined)}
                                vesselIds={filterVesselIds}
                                risksByCategoryId={filteredRisks}
                            />
                        )}
                    />
                    <RequirePermissions
                        role="hazardRegister"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton zone="grey" shape="circle" onClick={(e) => onEditSettings()}>
                            <SeaIcon slot="icon-only" icon="settings"/>
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            {/* Filters */}
            <div className="columns wrap filters">
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
                {vessels && vessels.all.length > 1 &&
                    <div>
                        <SeaSelectVessels
                            vesselIds={filterVesselIds}
                            setVesselIds={setFilterVesselIds}
                            zone="grey"
                            width="300px"
                            emptyText="None"
                        />
                    </div>
                }
            </div>

            <SeaNoData
                dataName="risks"
                isLoading={!risks}
                hasNoData={(filteredRisks && Object.keys(filteredRisks).length === 0)}
                isUsingFilter={true}
            />

            <div className={`risk-register has-thumbs has-status ${isSingleVessel ? 'hide-vessels' : 'show-vessels'} ${(filteredRisks && Object.keys(filteredRisks).length > 0) ? 'reveal' : 'conceal' }`}>
                <div className="sea-row headings">
                    <div></div>
                    <div>Hazard</div>
                    <div>Risks</div>
                    <div>Pre Risk Rating</div>
                    <div>Controls</div>
                    <div>Post Risk Rating</div>
                    <div>Vessels</div>
                    <div>Review Date</div>
                </div>
                <Profiler id="healthSafety.risks" onRender={onProfilerRender}>
                    {filteredRisks && riskCategories?.ids?.map((categoryId: string) => {
                        if (isLimitReached() || filteredRisks[categoryId] === undefined) {
                            return undefined;
                        }
                        return (
                            <React.Fragment key={categoryId}>
                                <div className="category-heading">{renderCategoryName(categoryId, riskCategories)}</div>
                                {mapArrayWithLimit(filteredRisks[categoryId], (item) => {
                                    return (
                                        <div key={item.id} className={`sea-card sea-row ${renderWhenDueClass(item.dateDue)}`} onClick={(e) => onViewItem(item)}>
                                            <div>
                                                <SeaFileImage files={item.files} size="tiny"/>
                                            </div>
                                            <div className="bold truncate-2">{item.name}</div>
                                            <div className="truncate-2">{item.risks}</div>
                                            {renderRiskRating(item.preControls.likelihood, item.preControls.consequence)}
                                            <div className="truncate-2">{item.controls}</div>
                                            <div>{renderRiskRating(item.postControls.likelihood, item.postControls.consequence)}</div>
                                            <div className="truncate-2">{!isSingleVessel && renderVesselsList(item.vesselIds, vessels, divisions)}</div>
                                            <div className={`truncate-2 review-date ${renderWhenDueClass(item.dateDue)}`}>
                                                {renderWhenDueClass(item.dateDue) ? (
                                                    <SeaStatusDueDate
                                                        dateDue={item.dateDue}
                                                        warnDays={warnDays.riskRegister[0]}
                                                    />
                                                ) : formatDateShort(item.dateDue)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                    {visible && limitTriggerElement}
                </Profiler>
            </div>

            {visible &&
                <>
                    <ViewRiskMatrix
                        showModal={riskMatrixModal.show}
                        setShowModal={(showModal: boolean) => setRiskMatrixModal({...riskMatrixModal, show: showModal})}
                        allowEdit={riskMatrixModal.allowEdit}
                        level={riskMatrixModal.level}
                        likelihoodId={riskMatrixModal.likelihoodId}
                        consequenceId={riskMatrixModal.consequenceId}
                        type={riskMatrixModal.type}
                    />
                    <EditRiskRegisterSettings
                        showModal={showRiskRegisterSettings}
                        setShowModal={setShowRiskRegisterSettings}
                    />
                    <EditRiskAssessment
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        defaultVesselId={isSingleVessel ? filterVesselIds![0] : undefined}
                        setRiskMatrixModal={setRiskMatrixModal}
                        itemToUpdate={selectedRisk}
                        level={2}
                    />
                    {selectedRisk && <ViewRiskAssessment
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        setRiskMatrixModal={setRiskMatrixModal}
                        setShowEditItemModal={setShowEditItemModal}
                        selectedRisk={selectedRisk}
                    />}
                </>
            }
        </RequirePermissions>
    );
};

export default RiskRegister;
