import React from 'react';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import { sharedState } from '../../shared-state/shared-state';
import SeaSelectVesselsWithoutDivisions from './SeaSelectVesselsWithoutDivisions';
import SeaSelectVesselsUsingDivisions from './SeaSelectVesselsUsingDivisions';
import './SeaSelectVessels.css';

interface SeaSelectVesselsProps {
    zone?: 'blue' | 'grey' | 'white'
    label?: string,
    vesselIds: string[] | undefined,
    setVesselIds?: React.Dispatch<React.SetStateAction<string[] | undefined>>,
    onChanged?: (values: string[]) => void,
    emptyText?: string,
    help?: SeaHelp,
    required?: boolean,
    requiredError?: string,
    isSubmitting?: boolean, // Pass in formik's isSubmitting so we can tell if form has been submitted (count as touched)
    disabled?: boolean,
    modalTitle?: string,
    width?: string,
}

const SeaSelectVessels: React.FC<SeaSelectVesselsProps> = ({
    zone,
    label,
    vesselIds,
    setVesselIds,
    onChanged,
    emptyText,
    help,
    required,
    requiredError,
    isSubmitting,
    disabled,
    modalTitle,
    width
}) => {
    const divisions = sharedState.divisions.use();
    if (divisions?.root) {
        return (
            <SeaSelectVesselsUsingDivisions
                divisions={divisions}
                zone={zone}
                label={label}
                vesselIds={vesselIds}
                setVesselIds={setVesselIds}
                onChanged={onChanged}
                emptyText={emptyText}
                help={help}
                required={required}
                requiredError={requiredError}
                isSubmitting={isSubmitting}
                disabled={disabled}
                modalTitle={modalTitle}
                width={width}
            />
        );
    }
    return (
        <SeaSelectVesselsWithoutDivisions
            zone={zone}
            label={label}
            vesselIds={vesselIds}
            setVesselIds={setVesselIds}
            onChanged={onChanged}
            emptyText={emptyText}
            help={help}
            required={required}
            requiredError={requiredError}
            isSubmitting={isSubmitting}
            disabled={disabled}
            modalTitle={modalTitle}
            width={width}
        />
    );
};

export default SeaSelectVessels;
