import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { Vessel } from './vessel';
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { Divisions, renderVesselsListUsingDivisions } from "./divisions";

//
// Loads vessel data based on vesselId.
// vesselId represents the currently selected vessel within the app.
//

export interface VesselOptions{
    id: string;
    name: string;
    isShoreFacility?: boolean;
    images?: string[];
}

export type VesselsData = {
    all: Vessel[], // Only includes vessels the user has access to
    categorised: { // Only includes vessels the user has access to
        vessels: Vessel[],
        shoreFacilities: Vessel[]
    },
    byId: { // Contains licensee-wide vessels
        [vesselId: string]: Vessel
    },
    totalVessels: number // Note: can be larger than all.length because it includes vessels we don't have access to
};

export const vesselsConfig: SharedStateConfig<VesselsData> = {
    isAlwaysActive: true,
    dependencies: ['licenseeId', 'vesselIds'],
    countLiveDocs: () => sharedState.vessels.current ? Object.keys(sharedState.vessels.current.byId).length : 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        const vesselIds = sharedState.vesselIds.current;
        if (licenseeId && vesselIds) {
            const searchText = (vessel: Vessel) => {
                let s = vessel.name;
                if (vessel.name) s += vessel.name;
                if (vessel.areaOfOperation) s += vessel.areaOfOperation;
                if (vessel.callsign) s += vessel.callsign;
                if (vessel.construction) s += vessel.construction;
                if (vessel.driveType) s += vessel.driveType;
                if (vessel.enginesDescription) s += vessel.enginesDescription;
                if (vessel.isShoreFacility) s += 'Shore Facility';
                if (vessel.length) s += vessel.length;
                if (vessel.master) s += vessel.master;
                if (vessel.mmsi) s += vessel.mmsi;
                if (vessel.mnz) s += vessel.mnz;
                return s.toLowerCase();
            };

            return onSnapshot(
                query(
                    collection(firestore, 'vessels'),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    orderBy('name', 'asc')
                ),
                (snap) => {
                    done();
                    let totalVessels = 0;
                    let byId = {} as {
                        [vesselId: string]: Vessel
                    };
                    let all = [] as Vessel[];
                    const categorised = {
                        vessels: [] as Vessel[],
                        shoreFacilities: [] as Vessel[]
                    };

                    snap.docs.forEach((doc) => {
                        const vessel = {
                            ...doc.data(),
                            id: doc.id,
                            searchText: searchText(doc.data() as Vessel)
                        } as Vessel;
                        byId[vessel.id] = vessel;
                        totalVessels++;
                        if (vesselIds.includes(vessel.id)) {
                            vessel.canAccess = true;
                            all.push(vessel);
                            registerFiles(vessel.images, 'vessels', vessel);
                            if (vessel.isShoreFacility) {
                                categorised.shoreFacilities.push(vessel);
                            } else {
                                categorised.vessels.push(vessel);
                            }
                        }
                    });

                    set({
                        all,
                        categorised,
                        byId,
                        totalVessels
                    });
                }, (error) => {
                    done();
                    console.log(`Error getting vessels for licensee ${licenseeId}`, error);
                }
            );
        }
    }
};




















export const renderVesselName = (vesselId: string, _vessels?: VesselsData, emptyText = '') => {
    const vessels = _vessels ?? sharedState.vessels.current;
    if (vesselId && vessels?.byId[vesselId]) {
        return vessels.byId[vesselId].name;
    }
    return emptyText;
};












/**
 * Renders a full list of vessel names represented by vesselIds
 * i.e. do not group vessels together under divisions.
 * If allVesselsText is defined, we'll render it if we have all active vessels.
 */
export const renderVesselsListWithoutDivisions = (
    vesselIds?: string[] | string,
    _vessels?: VesselsData,
    emptyText = '-', // What to show if there are no vesselIds
    allVesselsText = 'All Vessels' // What to show if we have all the active vessels
) => {
    if (vesselIds === undefined || vesselIds.length === 0) {
        return emptyText;
    }
    if (typeof vesselIds === 'string') {
        if (vesselIds === 'any') {
            return allVesselsText ?? '';
        }
        return renderVesselName(vesselIds as string, _vessels);
    }
    const vessels = _vessels ?? sharedState.vessels.current;
    if (!vessels) {
        return emptyText;
    }
    if (
        allVesselsText &&
        vesselIds.length === vessels.all.length &&
        vesselIds.length > 1
    ) {
        // Make sure all vesselIds represent active vessels
        let allActive = true;
        for (let i = 0; i < vesselIds.length; i++) {
            if (vessels.byId[vesselIds[i]].state !== 'active') {
                allActive = false;
                break;
            }
        }
        if (allActive) {
            return allVesselsText;
        }
    }
    // Create list of vessel names as string
    let s = '';
    for (let i = 0; i < vesselIds.length; i++) {
        if (vessels.byId[vesselIds[i]]) {
            if (s.length > 0) {
                s += ', ';
            }
            s += vessels.byId[vesselIds[i]].name;
        }
    }
    return s;
};

/**
 * Render a list of vessel name, grouping by division if possible.
 */
export const renderVesselsList = (
    vesselIds?: string[] | string,
    vessels?: VesselsData,
    _divisions?: Divisions,
    emptyText = '-',
    allVesselsText = 'All Vessels'
) => {
    const divisions = _divisions ?? sharedState.divisions.current;
    if (divisions === undefined) {
        return renderVesselsListWithoutDivisions(vesselIds, vessels, emptyText, allVesselsText);
    }
    return renderVesselsListUsingDivisions(vesselIds, vessels, divisions, emptyText, allVesselsText);
};

