import React, { useState, useEffect, Profiler, useCallback, useMemo } from 'react';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { formatValue } from '../../../lib/util';
import { formatDateShort, getDayOffset, warnDays, dateDueToClassName, MAX_DATE, formatDatetime } from '../../../lib/datesAndTime';
import { logPageView } from '../../../lib/firebase';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { IonSelectOption } from '@ionic/react';
import { renderCategoryName } from '../../../lib/categories';
import { sharedState } from '../../../shared-state/shared-state';
import { SafetyEquipmentItem } from '../../../shared-state/VesselSafety/safetyEquipmentItems';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import EditSafetyEquipment from '../../../modals/VesselSafety/SafetyEquipmentExpiries/EditSafetyEquipmentExpiry/EditSafetyEquipmentExpiry';
import ViewSafetyEquipment from '../../../modals/VesselSafety/SafetyEquipmentExpiries/ViewSafetyEquipmentExpiry/ViewSafetyEquipmentExpiry';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaStatusIcon from '../../../components/SeaStatusIcon/SeaStatusIcon';
import EditSafetyEquipmentExpirySettings from '../../../modals/VesselSafety/SafetyEquipmentExpiries/EditSafetyEquipmentExpirySettings/EditSafetyEquipmentExpirySettings';
import SafetyEquipmentExpiriesCsv from '../../../exports/VesselSafety/SafetyEquipmentExpiriesCsv';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import SafetyEquipmentExpiriesPdf from '../../../exports/VesselSafety/SafetyEquipmentExpiriesPdf';
import './SafetyEquipmentExpiries.css';

interface SafetyEquipmentExpiriesProps {
    visible: boolean
}

const SafetyEquipmentExpiries: React.FC<SafetyEquipmentExpiriesProps> = ({ visible }) => {
    const safetyEquipmentItems = sharedState.safetyEquipmentItems.use(visible ? 1 : 101);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(visible ? 1 : 101);
    const vesselLocations = sharedState.vesselLocations.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showEquipmentCheckSettings, setShowEquipmentCheckSettings] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SafetyEquipmentItem>();
    const [listType, setListType] = useState(warnDays.safetyEquipmentExpiries[0].toString());
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (visible) {
            logPageView('VesselSafety/SafetyEquipmentList');
        }
    }, [visible]);

    useEffect(() => {
        resetPageLimit();
    }, [visible, listType, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && safetyEquipmentItems?.byId[selectedItem.id]) {
            setSelectedItem(safetyEquipmentItems.byId[selectedItem.id]);
        };
    }, [safetyEquipmentItems, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: SafetyEquipmentItem) => {
        setShowViewItemModal(true);
        setSelectedItem(item)
    };

    const onEditSettings = () => {
        setShowEquipmentCheckSettings(true);
    };

    const isCritical = useCallback((id: string) => {
        if (vesselSafetyItems?.byId[id]?.isCritical) {
            return true;
        }
        return false;
    }, [vesselSafetyItems]);

    const filteredSafetyExpiryItems = useMemo(() => {
        let filteredItems = {
            servicable: [] as SafetyEquipmentItem[],
            expiring: [] as SafetyEquipmentItem[],
            nonExpiring: [] as SafetyEquipmentItem[]
        };
        if (!safetyEquipmentItems) return filteredItems;
        if (listType === 'all') {
            console.log('all', safetyEquipmentItems.all);
            return safetyEquipmentItems.all;
        }

        const numDays = Number(listType);
        const maxDateDue = isNaN(numDays) ? MAX_DATE : getDayOffset(numDays);

        if (['all', 'critical'].includes(listType)) {
            ['servicable', 'expiring', 'nonExpiring'].forEach((type) => {
                const list = safetyEquipmentItems?.all?.[type as 'expiring' | 'nonExpiring' | 'servicable'];
                list?.forEach((item: SafetyEquipmentItem) => {
                    if (listType === 'all' || (item.itemId && isCritical(item.itemId))) {
                        filteredItems[type as 'expiring' | 'servicable'].push(item);
                    }
                });
            });
        } else if (listType === 'all') {
            ['servicable', 'expiring', 'nonExpiring'].forEach((type) => {
                const list = safetyEquipmentItems?.all[type as 'expiring' | 'nonExpiring' | 'servicable'];
                list?.forEach((item: any) => {
                    (filteredItems as any)[type].push(item);
                });
            });
        } else {
            ['servicable', 'expiring'].forEach((type) => {
                const list = Object.values(safetyEquipmentItems?.prioritised[type as 'expiring' | 'servicable']);
                for (let i = 0; i < Object.keys(list).length; i++) {
                    const item = list[i] as SafetyEquipmentItem;
                    if (item?.dateDue && item.dateDue >= maxDateDue && !item.hasFault) {
                        break;
                    }
                    filteredItems[type as 'expiring' | 'servicable'].push(item);
                }
            });
        }

        return filteredItems;
    }, [safetyEquipmentItems, listType, isCritical]);

    return (
        <RequirePermissions
            role="safetyEquipmentList"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="safety-equipment-list page-head">
                <div><h2>Safety Equipment Expiries</h2></div>
                <div className="actions">
                    <SeaSelect
                        name="listType"
                        value={listType}
                        width="240px"
                        zone="grey"
                        onchange={(e) => {
                            setListType(e.detail.value);
                        }}
                    >
                        {warnDays.safetyEquipmentExpiries.map((check) => {
                            return <IonSelectOption key={check} value={check.toString()}>Overdue &amp; Due Within {check} Days</IonSelectOption>
                        })}
                        <IonSelectOption value="critical">Critical</IonSelectOption>
                        <IonSelectOption value="all">All</IonSelectOption>
                    </SeaSelect>
                    <RequirePermissions
                        role="safetyEquipmentList"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            Add New Item
                        </SeaButton>
                        <SeaExporter
                            setExportType={setExportType}
                            pdf={exportType === 'pdf' && 
                                <SafetyEquipmentExpiriesPdf
                                    onCompleted={() => setExportType(undefined)}
                                    listType={listType}
                                    servicable={filteredSafetyExpiryItems?.servicable}
                                    expiring={filteredSafetyExpiryItems?.expiring}
                                    nonExpiring={filteredSafetyExpiryItems?.nonExpiring}
                                />
                            }
                            csv={exportType === 'csv' && <SafetyEquipmentExpiriesCsv onCompleted={() => setExportType(undefined)} />}
                        />
                        <RequirePermissions
                            role="safetyEquipmentList"
                            level={permissionLevels.EDIT}
                        >
                            <SeaButton
                                zone="grey"
                                shape="circle"
                                onClick={(e) => onEditSettings()}
                                >
                                <SeaIcon slot="icon-only" icon="settings" />
                            </SeaButton>
                        </RequirePermissions>
                    </RequirePermissions>
                </div>
            </div>

            <SeaNoData
                dataName="safety equipment items"
                isLoading={!filteredSafetyExpiryItems}
                hasNoData={filteredSafetyExpiryItems
                    && filteredSafetyExpiryItems.servicable.length === 0
                    && filteredSafetyExpiryItems.expiring.length === 0
                    && (listType !== 'all' || filteredSafetyExpiryItems.nonExpiring.length === 0)
                }
                isUsingFilter={listType !== 'all'}
            />

            <div className={`safety-equipment-list has-thumbs has-status ${(
                filteredSafetyExpiryItems
                && (
                    filteredSafetyExpiryItems?.servicable?.length > 0
                    || filteredSafetyExpiryItems?.expiring?.length > 0
                    || filteredSafetyExpiryItems?.nonExpiring?.length > 0
                )
            ) ? 'reveal' : 'conceal'}`}>
                <div className="sea-row headings">
                    <div></div>
                    <div>Safety Item</div>
                    <div>Location</div>
                    <div>Last Service</div>
                    <div>Service/<br />Expiry</div>
                    <div>Critical</div>
                    <div>Status</div>
                    <div>Reminder</div>
                </div>
                <Profiler id="safety.equipmentList" onRender={onProfilerRender}>
                    {/* Severviable Equipment Category */}
                    {filteredSafetyExpiryItems?.servicable?.length > 0 && !isLimitReached() && <>
                        <div className="category-heading">Servicable Equipment</div>
                        {mapArrayWithLimit(filteredSafetyExpiryItems.servicable, (item) => {
                            return (
                                <div key={item.id} className={`sea-card sea-row ${dateDueToClassName(item.dateDue, warnDays.safetyEquipmentExpiries[0])}`} onClick={(e) => onViewItem(item)}>
                                    <div><SeaFileImage files={item.files} size="tiny" /></div>
                                    <div className="bold truncate-2">{renderCategoryName(item.itemId, vesselSafetyItems)}</div>
                                    <div className="truncate-2">{formatValue(renderCategoryName(item.locationId, vesselLocations))}</div>
                                    <div>{formatDatetime(item.whenLastChecked)}</div>
                                    <div>{formatDateShort(item.dateDue)}</div>
                                    <div>
                                        {isCritical(item.itemId) && (
                                            <SeaStatusIcon
                                                icon="flag"
                                                className="critical bottom"
                                            />
                                        )}
                                    </div>
                                    <div><SeaStatusDueDate dateDue={item.dateDue} warnDays={warnDays.safetyEquipmentExpiries[0]} /></div>
                                    <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                </div>
                            );
                        })}
                    </>}
                    {/* Expiring Equipment Category */}
                    {filteredSafetyExpiryItems?.expiring?.length > 0 && !isLimitReached() && <>
                        <div className="category-heading">Expiring Equipment</div>
                        {mapArrayWithLimit(filteredSafetyExpiryItems.expiring, (item) => {
                            return (
                                <div key={item.id} className={`sea-card sea-row ${dateDueToClassName(item.dateDue, warnDays.safetyEquipmentExpiries[0])}`} onClick={(e) => onViewItem(item)}>
                                    <div>
                                        <SeaFileImage files={item.files} size="tiny" />
                                    </div>
                                    <div className="bold truncate-2">{renderCategoryName(item.itemId, vesselSafetyItems)}</div>
                                    <div className="truncate-2">{formatValue(renderCategoryName(item.locationId, vesselLocations))}</div>
                                    <div></div>
                                    <div>{formatDateShort(item.dateDue)}</div>
                                    <div>
                                        {isCritical(item.itemId) && (
                                            <SeaStatusIcon
                                                icon="flag"
                                                className="critical bottom"
                                            />
                                        )}
                                    </div>
                                    <div><SeaStatusDueDate dateDue={item.dateDue} warnDays={warnDays.safetyEquipmentExpiries[0]} /></div>
                                    <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                </div>
                            );
                        })}
                    </>}
                    {/* Non Expiring Equipment Category */}
                    {filteredSafetyExpiryItems?.nonExpiring?.length > 0 && !isLimitReached() && <>
                        <div className="category-heading">Non Expiring Equipment</div>
                        {mapArrayWithLimit(filteredSafetyExpiryItems.nonExpiring, (item) => {
                            return (
                                <div key={item.id} className="sea-card sea-row" onClick={(e) => onViewItem(item)}>
                                    <div><SeaFileImage files={item.files} size="tiny" /></div>
                                    <div className="bold truncate-2">{renderCategoryName(item.itemId, vesselSafetyItems)}</div>
                                    <div className='truncate-2'>{formatValue(renderCategoryName(item.locationId, vesselLocations))}</div>
                                    <div className="not-card"></div>
                                    <div className="not-card"></div>
                                    <div>
                                        {isCritical(item.itemId) && (
                                            <SeaStatusIcon
                                                icon="flag"
                                                className="critical bottom"
                                            />
                                        )}</div>
                                    <div className="not-card"></div>
                                    <div className="not-card"></div>
                                </div>
                            );
                        })}
                    </>}
                    {visible && limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    <EditSafetyEquipment
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                    />
                    <ViewSafetyEquipment
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />
                    <EditSafetyEquipmentExpirySettings
                        showModal={showEquipmentCheckSettings}
                        setShowModal={setShowEquipmentCheckSettings}
                    />
                </>
            }
        </RequirePermissions>
    );
};

export default SafetyEquipmentExpiries;
