import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { IonSelectOption } from '@ionic/react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { extractSearchTerms, formatValue } from '../../../lib/util';
import { formatDateShort, getDayOffset, warnDays, dateDueToClassName, MAX_DATE } from '../../../lib/datesAndTime';
import { canEdit, canView, permissionLevels } from '../../../shared-state/Core/userPermissions';
import { CertificateHolderInfo, CrewCertificate } from '../../../shared-state/Crew/crewCertificates';
import { sharedState } from '../../../shared-state/shared-state';
import { UserType } from '../../../shared-state/Core/user';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import EditCrewCertificateSettings from '../../../modals/Crew/CrewCertificates/EditCrewCertificateSettings/EditCrewCertificateSettings';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import EditCrewCertificate from '../../../modals/Crew/CrewCertificates/EditCrewCertificate/EditCrewCertificate';
import ViewCrewCertificate from '../../../modals/Crew/CrewCertificates/ViewCrewCertificate/ViewCrewCertificate';
import ArchiveCrewCertificate from '../../../modals/Crew/CrewCertificates/ArchiveCrewCertificate/ArchiveCrewCertificate';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaSearchbar from '../../../components/SeaSearchbar/SeaSearchbar';
import CrewCertificatesListPdf from '../../../exports/Crew/CrewCertificates/CrewCertificatesListPdf';
import CrewCertificatesCsv from '../../../exports/Crew/CrewCertificates/CrewCertificatesCsv';
import SeaCheckbox from '../../../components/SeaCheckbox/SeaCheckbox';
import SeaSelectVessels from '../../../components/SeaSelectVessels/SeaSelectVessels';
import './CrewCertificates.css';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';


interface CrewCertificatesProps {
    visible: boolean;
    filterVesselIds: string[] | undefined;
    setFilterVesselIds: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

const CrewCertificates: React.FC<CrewCertificatesProps> = ({ visible, filterVesselIds, setFilterVesselIds }) => {
    const users = sharedState.users.use(visible ? 1 : 101);
    const vesselIds = sharedState.vesselIds.use(visible ? 1 : 101);
    const crewCertificates = sharedState.crewCertificates.use(visible ? 1 : 101);
    sharedState.crewCertificates.use(visible ? 102 : 0); // Prepare for modal to use
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [searchText, setSearchText] = useState('');
    const [operationalCrewOnly, setOperationalCrewOnly] = useState(false);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showArchivedModal, setShowArchivedModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<CrewCertificate>();
    const [showEditCrewCertificateSettings, setShowEditCrewCertificateSettings] = useState(false);
    const [listType, setListType] = useState(warnDays.crewCertificates[0].toString());
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (visible) {
            logPageView('Crew/CrewCertificates');
        }
    }, [visible]);

    useEffect(() => {
        resetPageLimit();
    }, [visible, resetPageLimit, listType, searchText, filterVesselIds, operationalCrewOnly]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && crewCertificates?.byId[selectedItem.id]) {
            setSelectedItem(crewCertificates.byId[selectedItem.id])
        };
    }, [crewCertificates, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: CrewCertificate) => {
        if (item.state === 'missing') {
            setShowEditItemModal(true);
            setSelectedItem(item)
        } else {
            setShowViewItemModal(true);
            setSelectedItem(item)
        }
    };
    const onViewArchived = () => {
        setShowArchivedModal(true);
    };

    const filterByVesselId = useMemo(() => {
        const o = {} as { [vesselId: string]: true }
        filterVesselIds?.forEach((vesselId) => {
            o[vesselId] = true;
        });
        return o;
    }, [filterVesselIds]);

    const shouldIncludeUser = useCallback((user?: UserType) => {
        if (!user) return false;
        if (operationalCrewOnly) {
            if (user.crewVesselIds) {
                for (let j = 0; j < user.crewVesselIds.length; j++) {
                    if (filterByVesselId[user.crewVesselIds[j]]) {
                        return true;
                    }
                }
            }
        } else {
            if (user.vesselIds) {
                for (let j = 0; j < user.vesselIds.length; j++) {
                    if (filterByVesselId[user.vesselIds[j]]) {
                        return true;
                    }
                }
            }
        }
    }, [filterByVesselId, operationalCrewOnly]);

    const shouldIncludeCertificate = useCallback((certificate: CrewCertificate, terms: string[]) => {
        if (terms.length > 0) {
            for (let term of terms) {
                if (!certificate.searchText?.toLowerCase().includes(term)) {
                    return false;
                }
            }
        }
        return true;
    }, []);

    const prioritisedCrewCertificates = useMemo(() => {
        if (['all', 'gap'].includes(listType)) return undefined;

        const terms = extractSearchTerms(searchText, true);
        const numDays = Number(listType);
        const dateExpires = isNaN(numDays) ? MAX_DATE : getDayOffset(numDays);
        return crewCertificates?.prioritised?.filter((item) => {
            const _user = users?.byId[item.heldBy];
            if (_user && item.dateExpires && item.dateExpires < dateExpires) {
                if (!shouldIncludeUser(_user)) {
                    return false;
                }
                return shouldIncludeCertificate(item, terms);
            }
            return false;
        });
    }, [listType, searchText, crewCertificates?.prioritised, users?.byId, shouldIncludeUser, shouldIncludeCertificate]);

    const filteredCertificateHolders = useMemo(() => {
        if (!crewCertificates || !['all', 'gap'].includes(listType)) {
            return undefined;
        }

        const terms = extractSearchTerms(searchText, true);
        const _holders = [] as CertificateHolderInfo[];
        crewCertificates.holdersSorted.forEach((holder) => {
            if (!shouldIncludeUser(users?.byId[holder.id])) {
                return;
            }
            const certificates = [] as CrewCertificate[];
            holder.certificates.forEach((certificate) => {
                if (shouldIncludeCertificate(certificate, terms)) {
                    certificates.push(certificate);
                }
            });
            if (certificates.length > 0) {
                _holders.push({
                    ...holder,
                    certificates: certificates
                });
            }
        });

        return _holders;
    }, [crewCertificates, listType, searchText, shouldIncludeCertificate, shouldIncludeUser, users?.byId]);

    return (
        <div>
            <div className="crew-certificates page-head">
                <div><h2>{!canView('crewCertificates') ? 'My' : 'Crew'} Certificates</h2></div>
                <div className="actions">
                    <SeaSelect
                        name="listType"
                        value={listType}
                        width="250px"
                        zone="grey"
                        onchange={(e) => {
                            setListType(e.detail.value);
                        }}
                    >
                        {warnDays.crewCertificates.map((days) => {
                            return <IonSelectOption key={days} value={days.toString()}>Overdue &amp; Due Within {days} Days</IonSelectOption>
                        })}
                        <IonSelectOption value="gap">Gap Analysis</IonSelectOption>
                        <IonSelectOption value="all">All</IonSelectOption>
                    </SeaSelect>
                    <RequirePermissions
                        role="crewCertificates"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            Add New
                        </SeaButton>
                        <RequirePermissions
                            role="crewCertificates"
                            level={permissionLevels.FULL}
                        >
                            <div className="spacer"></div>
                            <SeaButton onClick={(e) => onViewArchived()} zone="grey">
                                <SeaIcon slot="start" icon="archive" />
                                Archived
                            </SeaButton>
                        </RequirePermissions>

                        <SeaExporter
                            setExportType={setExportType}
                            pdf={exportType === 'pdf' && filteredCertificateHolders && (
                                <CrewCertificatesListPdf
                                    onCompleted={() => setExportType(undefined)}
                                    listType = {listType}
                                    crewCertificateHolder={filteredCertificateHolders}
                                    prioritisedCrewCertificates={prioritisedCrewCertificates || []}
                                />
                            )}
                            csv={exportType === "csv" && <CrewCertificatesCsv onCompleted={() => setExportType(undefined)} />}
                        />
                        <RequirePermissions
                            role="crewCertificates"
                            level={permissionLevels.EDIT}
                        >
                            <div className="spacer"></div>
                            <SeaButton
                                zone="grey"
                                shape="circle"
                                onClick={(e) => {
                                    setShowEditCrewCertificateSettings(true);
                                }}
                            >
                                <SeaIcon slot="icon-only" icon="settings"/>
                            </SeaButton>
                        </RequirePermissions>
                    </RequirePermissions>

                </div>
            </div>

            <div className={`columns wrap filters`}>
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
                {vesselIds && vesselIds.length > 1 &&
                    <div>
                        <SeaSelectVessels
                            vesselIds={filterVesselIds}
                            setVesselIds={setFilterVesselIds}
                            zone="grey"
                            width="300px"
                            emptyText="None"
                        />
                    </div>
                }
                <div style={{ paddingTop: '2px' }}>
                    <SeaCheckbox
                        checked={operationalCrewOnly}
                        setChecked={setOperationalCrewOnly}
                    >
                        Operational Crew Only
                    </SeaCheckbox>
                </div>
            </div>

            <SeaNoData
                dataName="certificates"
                isLoading={!prioritisedCrewCertificates && !filteredCertificateHolders}
                hasNoData={(prioritisedCrewCertificates && prioritisedCrewCertificates.length === 0) || (filteredCertificateHolders && filteredCertificateHolders.length === 0)}
                isUsingFilter={true}
            />

            <div 
                key={listType} // Force re-render when listType changes
                className={`crew-certificates has-thumbs has-status ${['all', 'gap'].includes(listType) ? 'all' : ''} ${(filteredCertificateHolders || prioritisedCrewCertificates) ? 'reveal' : 'conceal'}`}
            >
                {/* Prioritised Certificates */}
                {!['all', 'gap'].includes(listType) && prioritisedCrewCertificates && prioritisedCrewCertificates.length > 0 && (
                    <>
                        <div className="sea-row headings">
                            <div></div>
                            <div>Certificate</div>
                            <div>Held By</div>
                            <div>Issued By</div>
                            <div>Expiry</div>
                            <div>Status</div>
                            <div>Reminder</div>
                        </div>
                        {mapArrayWithLimit(prioritisedCrewCertificates, (item) => {
                            return (
                                <div key={item.id} className={`sea-card sea-row ${dateDueToClassName(item.dateExpires, warnDays.crewCertificates[0])}`} onClick={(e) => onViewItem(item)}>
                                    <div>
                                        <SeaFileImage files={item.files} size="tiny" />
                                    </div>
                                    <div className="bold truncate-2">{item.title}</div>
                                    <div className="truncate-2">{formatValue(crewCertificates?.holders[item.heldBy].name)}</div>
                                    <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                    <div>{item.dateExpires ? formatDateShort(item.dateExpires) : null}</div>
                                    <div>{item.dateExpires ? <SeaStatusDueDate dateDue={item.dateExpires} warnDays={warnDays.crewCertificates[0]} /> : ''} </div>
                                    <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                </div>
                            );
                        })}
                    </>
                )}
                {/* Certificates Grouped by Individual Crew */}
                {['all', 'gap'].includes(listType) && filteredCertificateHolders && filteredCertificateHolders.length > 0 && (
                    <>
                        <div className="sea-row headings">
                            <div></div>
                            <div>Certificate</div>
                            <div>Issued By</div>
                            <div>Issue Date</div>
                            <div>Expiry</div>
                            <div>Status</div>
                            <div>Reminder</div>
                        </div>
                        {filteredCertificateHolders.map((holder) => {
                            if (isLimitReached()) {
                                return undefined;
                            }
                            return (
                                <React.Fragment key={holder.name}>
                                    <div className="category-heading">{holder.name}</div>
                                    {mapArrayWithLimit(holder.certificates, (item) => {
                                        return (
                                            <div
                                                key={item.id}
                                                className={`sea-card sea-row ${item.dateExpires && dateDueToClassName(item.dateExpires, warnDays.crewCertificates[0])}`}
                                                onClick={(e) => onViewItem(item)}
                                            >
                                                <div>
                                                    <SeaFileImage files={item.files} size="tiny" />
                                                </div>
                                                <div className="bold truncate-2">{item.title}</div>
                                                <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                                <div className={item.state === 'missing' ? 'not-card' : ''}>{formatDateShort(item.dateIssued)}</div>
                                                <div className={item.dateExpires && item.state !== 'missing' ? '' : 'not-card'}>{item.dateExpires ? formatDateShort(item.dateExpires) : '-'}</div>
                                                <div className={(item.dateExpires || item.state === 'missing') ? '' : 'not-card'}>
                                                    {item.state === 'missing' ? (
                                                        <SeaStatusDueDate isMissing />
                                                    ) : item.dateExpires ? (
                                                        <SeaStatusDueDate dateDue={item.dateExpires} warnDays={warnDays.crewCertificates[0]} />
                                                    ) : ''}
                                                </div>
                                                <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                            </div>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </>
                )}
                {visible && limitTriggerElement}
            </div>
            {visible &&
                <>
                    <EditCrewCertificate
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem?.state === 'missing' ? selectedItem : undefined}
                        setItemToUpdate={setSelectedItem}
                    />
                    {selectedItem && <ViewCrewCertificate
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                    {canView('crewCertificates') &&
                        <ArchiveCrewCertificate
                            showModal={showArchivedModal}
                            setShowModal={setShowArchivedModal}
                        />
                    }
                    {canEdit('crewCertificates') && <EditCrewCertificateSettings
                        showModal={showEditCrewCertificateSettings}
                        setShowModal={setShowEditCrewCertificateSettings}
                    />}
                    
                </>
            }
        </div>
    );
};

export default CrewCertificates;
