import React, { useEffect, useState } from 'react';
import { IonSelectOption } from '@ionic/react';
import { sharedState } from '../../shared-state/shared-state';
import { Vessel } from '../../shared-state/Core/vessel';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import SeaSelect from '../SeaSelect/SeaSelect';

interface SeaSelectVesselWithoutDivisionsProps {
    label?: string,
    vesselId?: string,
    setVesselId?: React.Dispatch<React.SetStateAction<string | undefined>>,
    onChange?: (vesselId: string) => void,
    width?: string
    zone?: 'blue' | 'white' | 'grey',
    emptyText?: string,
    disabled?: boolean,
    help?: SeaHelp,
    required?: boolean,
    requiredError?: string,
    isSubmitting?: boolean, // Pass in formik's isSubmitting so we can tell if form has been submitted (count as touched)
}

const SeaSelectVesselWithoutDivisions: React.FC<SeaSelectVesselWithoutDivisionsProps> = ({
    label,
    vesselId,
    setVesselId,
    onChange,
    width,
    zone = 'grey',
    emptyText = 'Not Set',
    disabled,
    help,
    required,
    requiredError = 'Please select a vessel',
    isSubmitting
}) => {
    const vessels = sharedState.vessels.use();
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        let isActive = true;
        setTouched(false);
        setTimeout(() => {
            if (!isActive) return;
            setTouched(false);
        }, 100);
        return () => { isActive = false; };
    }, []);

    useEffect(() => {
        if (isSubmitting) {
            setTouched(true);
        }
    }, [isSubmitting]);

    let error = '';
    if (required && touched && !vesselId) {
        error = requiredError;
    }

    return (
        <SeaSelect
            label={label}
            value={vesselId}
            //setValue={setVesselId}
            zone={zone}
            width={width}
            onchange={(e) => {
                if (setVesselId) {
                    setVesselId(e.detail.value);
                }
                if (onChange) {
                    setTimeout(() => {
                        onChange(e.detail.value);
                    });
                }
            }}
            disabled={disabled}
            help={help}
            error={error}
        >
            <IonSelectOption value="">{emptyText}</IonSelectOption>
            {vessels?.all?.map((vessel: Vessel) => {
                return (
                    <IonSelectOption key={vessel.id} value={vessel.id}>{vessel.name}</IonSelectOption>
                );
            })}
        </SeaSelect>
    );
};

export default SeaSelectVesselWithoutDivisions;
