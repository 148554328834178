import React, {useCallback, useMemo} from 'react';
import {IonRadioGroup, IonRadio, IonSelectOption} from '@ionic/react';
import { PermissionRole, PureUserPermissions, permissionLevels, permissionRoles, permissionsConfig } from '../../shared-state/Core/userPermissions';
import { regions } from '../../lib/util';
import { sharedState } from '../../shared-state/shared-state';
import { openContextualHelp } from '../../managers/ContextualHelpManager/ContextualHelpManager';
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaSelect from '../SeaSelect/SeaSelect';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaUserPermissions.css';

interface SeaUserPermissionsProps {
    error?: string
    data?: PureUserPermissions,
    setData: React.Dispatch<React.SetStateAction<PureUserPermissions | undefined>>,
    keyPrefix?: string,
    disable?: boolean,
    hasSubmitted?: boolean,
}

// const debugPermissions = (permissions: PureUserPermissions | undefined) => {
//     if (!permissions) return '[undefined]';
//     let s = '[';
//     permissionRoles.forEach((id) => {
//         s += permissions[id];
//     });
//     s += ']';
//     return s;
// };

const SeaUserPermissions: React.FC<SeaUserPermissionsProps> = ({error, data, setData, disable, keyPrefix = '', hasSubmitted }) => {
    const licenseeSettings = sharedState.licenseeSettings.use();
    const userPermissions = sharedState.userPermissions.use();

    const userHasPermission = useCallback((permissionRole: PermissionRole, level: number) => {
        if (!userPermissions) return false;
        if (data && data[permissionRole] !== level) {
            return true;
        }
        const currentUserMaxPermission = userPermissions[permissionRole] || permissionLevels.NONE;
        if (currentUserMaxPermission < level) {
            return false;
        }
        return true
    }, [data, userPermissions]);

    const handleChange = useCallback((e: any, permissionRole: PermissionRole) => {
        if (!data) {
            return;
        }
        const newValue = e.detail.value;
        setData((current) => {
            return {
                ...current as PureUserPermissions,
                [permissionRole]: newValue
            };
        });
    }, [data, setData]);

    const warnAboutCrewParticulars = useMemo(() => {
        if (data?.crewParticulars && data.crewParticulars >= permissionLevels.EDIT) {
            return true;
        }
        return false;
    }, [data]);

    const isDisabled = useCallback((permissionRole: PermissionRole, level: number) => {
        if (!disable) return false;
        if (!userPermissions || !userPermissions[permissionRole] || userPermissions[permissionRole] < level) {
            return true;
        }
        return false;
    }, [userPermissions, disable]);

    const radioGroups = useMemo(() => {
        if (!data) return null
        return permissionRoles?.map((permissionRole: PermissionRole, index: number) => {
            const permissionConfig = permissionsConfig[permissionRole];
            const hasError = hasSubmitted && !userHasPermission(permissionRole, data[permissionRole]);
            return (
                <IonRadioGroup 
                    className={`row radio-group ${hasError ? 'has-error' : ''}`}
                    value={data[permissionRole]} 
                    onIonChange={e => handleChange(e, permissionRole)}
                    allowEmptySelection={false}
                    key={`${keyPrefix}${permissionRole}`}
                >
                    {permissionConfig.help ? (
                        <div
                            className="columns help-icon"
                            onClick={(e) => openContextualHelp(e, permissionConfig.help!)}
                        >
                            <div>
                                {permissionConfig.name}
                            </div>
                            {/* <div style={{ padding: '2px 0px 0px 4px', color: '#ccc' }}> */}
                            <div className="sea-help">
                                <SeaIcon icon="help"/>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {(permissionRole === 'companyPlan') ? (
                                regions[licenseeSettings?.region as string]?.companyPlan
                            ) : (
                                permissionConfig.name
                            )}
                        </div>
                    )}
                    <div>
                        <IonRadio 
                            className={`sea-user-permissions-radio ${permissionConfig.hidden?.includes(permissionLevels.NONE) ? 'sea-radio-hidden' : ''}`}
                            value={permissionLevels.NONE} 
                            color="primary" 
                            mode="md"
                        />
                    </div>
                    <div>
                        <IonRadio 
                            className={`
                                sea-user-permissions-radio
                                ${hasSubmitted && !userHasPermission(permissionRole, permissionLevels.VIEW) ? 'has-error' : ''}
                                ${permissionConfig.hidden?.includes(permissionLevels.VIEW) ? 'sea-radio-hidden' : ''}
                            `}
                            value={permissionLevels.VIEW} 
                            color="primary"
                            mode="md"
                            disabled={isDisabled(permissionRole, permissionLevels.VIEW)}
                        />
                    </div>
                    <div>
                        <IonRadio 
                            className={`
                                sea-user-permissions-radio
                                ${hasSubmitted && data[permissionRole] === permissionLevels.COMPLETE && !userHasPermission(permissionRole, permissionLevels.COMPLETE) ? 'has-error' : ''}
                                ${permissionConfig.hidden?.includes(permissionLevels.COMPLETE) ? 'sea-radio-hidden' : ''}
                            `}
                            value={permissionLevels.COMPLETE} 
                            color="primary" 
                            mode="md"
                            disabled={isDisabled(permissionRole, permissionLevels.COMPLETE)}
                        />
                    </div>
                    <div>
                        <IonRadio 
                            className={`
                                sea-user-permissions-radio
                                ${hasSubmitted && data[permissionRole] === permissionLevels.CREATE && !userHasPermission(permissionRole, permissionLevels.CREATE) ? 'has-error' : ''}
                                ${permissionConfig.hidden?.includes(permissionLevels.CREATE) ? 'sea-radio-hidden' : ''}
                            `}
                            value={permissionLevels.CREATE} 
                            color="primary" 
                            mode="md"
                            disabled={isDisabled(permissionRole, permissionLevels.CREATE)}
                        />
                    </div>
                    <div>
                        <IonRadio 
                            className={`
                                sea-user-permissions-radio
                                ${hasSubmitted && data[permissionRole] === permissionLevels.EDIT && !userHasPermission(permissionRole, permissionLevels.EDIT) ? 'has-error' : ''}
                                ${permissionConfig.hidden?.includes(permissionLevels.EDIT) ? 'sea-radio-hidden' : ''}
                            `}
                            value={permissionLevels.EDIT} 
                            color="primary" 
                            mode="md"
                            disabled={isDisabled(permissionRole, permissionLevels.EDIT)}
                        />
                        {(permissionRole === 'crewParticulars' && warnAboutCrewParticulars) && <div className="warning-space"></div> }
                    </div>
                    <div>
                        <IonRadio 
                            className={`
                                sea-user-permissions-radio
                                ${hasSubmitted && data[permissionRole] === permissionLevels.FULL && !userHasPermission(permissionRole, permissionLevels.FULL) ? 'has-error' : ''}
                                ${permissionConfig.hidden?.includes(permissionLevels.FULL) ? 'sea-radio-hidden' : ''}
                            `}
                            value={permissionLevels.FULL} 
                            color="primary" 
                            mode="md"
                            disabled={isDisabled(permissionRole, permissionLevels.FULL)}
                        />
                    </div>
                    <div className="drop-down">
                        <SeaSelect
                            name={`dd${permissionRole}`}
                            value={data[permissionRole]}
                            onchange={(e) => {
                                handleChange(e, permissionRole);
                            }}
                        >
                            <IonSelectOption
                                className={permissionConfig.hidden?.includes(permissionLevels.NONE) ? 'sea-option-hidden' : ''}
                                value={permissionLevels.NONE}
                            >
                                Hidden
                            </IonSelectOption>
                            <IonSelectOption
                                className={`
                                    ${permissionConfig.hidden?.includes(permissionLevels.VIEW) ? 'sea-option-hidden' : ''}
                                    ${hasSubmitted && data[permissionRole] === permissionLevels.VIEW && !userHasPermission(permissionRole, permissionLevels.VIEW) ? 'has-error' : ''}
                                `}
                                value={permissionLevels.VIEW}
                            >
                                View
                            </IonSelectOption>
                            <IonSelectOption
                                className={`
                                    ${permissionConfig.hidden?.includes(permissionLevels.COMPLETE) ? 'sea-option-hidden' : ''}
                                    ${hasSubmitted && data[permissionRole] === permissionLevels.COMPLETE && !userHasPermission(permissionRole, permissionLevels.COMPLETE) ? 'has-error' : ''}
                                `}
                                value={permissionLevels.COMPLETE}
                            >
                                Complete
                            </IonSelectOption>
                            <IonSelectOption
                                className={`
                                    ${permissionConfig.hidden?.includes(permissionLevels.CREATE) ? 'sea-option-hidden' : ''}
                                    ${hasSubmitted && data[permissionRole] === permissionLevels.CREATE && !userHasPermission(permissionRole, permissionLevels.CREATE) ? 'has-error' : ''}
                                `}
                                value={permissionLevels.CREATE}
                            >
                                Create/Add
                            </IonSelectOption>
                            <IonSelectOption
                                className={`
                                    ${permissionConfig.hidden?.includes(permissionLevels.EDIT) ? 'sea-option-hidden' : ''}
                                    ${hasSubmitted && data[permissionRole] === permissionLevels.EDIT && !userHasPermission(permissionRole, permissionLevels.EDIT) ? 'has-error' : ''}
                                `}
                                value={permissionLevels.EDIT}
                            >
                                Edit
                            </IonSelectOption>
                            <IonSelectOption 
                                className={`
                                    ${permissionConfig.hidden?.includes(permissionLevels.FULL) ? 'sea-option-hidden' : ''}
                                    ${hasSubmitted && data[permissionRole] === permissionLevels.FULL && !userHasPermission(permissionRole, permissionLevels.FULL) ? 'has-error' : ''}
                                `}
                                value={permissionLevels.FULL}
                            >
                                Delete
                            </IonSelectOption>
                        </SeaSelect>
                    </div>
                    {(permissionRole === 'crewParticulars' && warnAboutCrewParticulars) && <div className="warning">Warning - This will allow this person to edit other user's accounts (disable logins, change permissions etc.)</div>}
                </IonRadioGroup>
            )
        })
    }, [data, keyPrefix, licenseeSettings?.region, hasSubmitted, userHasPermission, isDisabled, warnAboutCrewParticulars, handleChange]); 

    return (
        <div className="sea-user-permissions">
           {!data ? (
                <div className="sea-label">Loading permissions...</div>
            ) : (
                <>
                    <div className="row">
                        <div className="sea-label">User permissions</div>
                        <div className="sea-label">Hidden</div>
                        <div className="sea-label">View</div>
                        <div className="sea-label">Complete</div>
                        <div className="sea-label">Create/Add</div>
                        <div className="sea-label">Edit</div>
                        <div className="sea-label">Delete</div>
                        <div className="drop-down"></div>
                    </div>
                    {radioGroups}
                    <SeaInputError>{error}</SeaInputError>
                </>
            )}
        </div>
    );
};

export default SeaUserPermissions;
